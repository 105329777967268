import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import ClientLayout from "../../components/layouts/ClientLayout";
import { useContext, useState } from "react";
import { AuthContext } from "../../store/AuthContext";
import FieldSwitch from "../../components/forms/FieldSwitch";
import { DataContext } from "../../store/DataContext";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import questionnaireApi from "../../services/apis/questionnaireApi";
import { isSuccess, showSuccess } from "../../shared/util";
import { useNavigate } from "react-router-dom";
import { isValidQItem } from "../../shared/validateQuestionnaireField";
import axiosClient from "../../shared/axiosClient";

const ClientQuestionnaire = () => {
    const context = useContext(AuthContext);
    const dataContext = useContext(DataContext);
    const [section, setSection] = useState(0);
    const [savingInProgress, setSavinginProgress] = useState(false);
    const [savingAsDraftInProgress, setSavingAsDraftinProgress] = useState(false);
    const questionnaireService = questionnaireApi(context.application.programId, 'programs/');
    const navigate = useNavigate();

    const [questionnaire, setQuestionnaire] = useState(context.questionnaire);

    const getSection = () => {
        return questionnaire.sections[section];
    }

    const next = () => {
        if(section === questionnaire.sections.length - 1) {
            return;
        }

        setSection(section + 1);
    }

    const previous = () => {
        if(section === 0) {
            return;
        }

        setSection(section - 1);
    }

    const uploadFile = async (item) => {
        let formData = new FormData();
        formData.append('file', item.value);

        const fileUploadResponse = await axiosClient.post("questionnaires/" + questionnaire.id + "/items/" + item.id + '/file', formData, {
            headers: { "Content-type": "multipart/form-data" }
        });

        return fileUploadResponse;
    }

    const saveQuestionnaire = async (forSave) => {
        for(const sectionItem of forSave.sections) {
            const fileItems = sectionItem.items.filter(x => dataContext.getFieldType(x.fieldTypeId).slug === 'file');
            for(const fileItem of fileItems) {
                if(fileItem.value) {
                    const resp = await uploadFile(fileItem);
                    fileItem.value = null;
                }
            }
        }

        const saveQuestionnaireResponse = await questionnaireService.patch(null, forSave);
        return saveQuestionnaireResponse;
    }

    const submit = async () => {
        setSavinginProgress(true);
        
        const tmpQestionnaire = {...questionnaire};
        tmpQestionnaire.status = "Submitted";
        
        const saveQuestionnaireResponse = await saveQuestionnaire(tmpQestionnaire);
        if(isSuccess(saveQuestionnaireResponse.status)) {
            context.setQuestionnaire({...context.questionnaire, status: 'Submitted'});
            showSuccess("Questionnare has beed saved");
            navigate("/");
        }

        setSavinginProgress(false);
    }

    const saveAsDraft = async () => {
        setSavingAsDraftinProgress(true);
        
        const tmpQestionnaire = {...questionnaire};
        tmpQestionnaire.status = "Draft";
        
        const saveQuestionnaireResponse = await saveQuestionnaire(tmpQestionnaire);
        if(isSuccess(saveQuestionnaireResponse.status)) {
            showSuccess("Questionnare has beed saved");
        }

        setSavingAsDraftinProgress(false);
    }

    const clearSection = () => {
        const tmpQestionnaire = {...questionnaire};
        tmpQestionnaire.sections[section].items.forEach((item, index, itemArray) => {
            itemArray[index].value = "";
        });

        setQuestionnaire(tmpQestionnaire);
    }

    const isValid = () => {
        let valid = true;
        questionnaire.sections.forEach(section => {
            const sectionValid = section.items.some(x => !isValidQItem(x, dataContext.getFieldType(x.fieldTypeId).slug));
            if (sectionValid) {
                valid = false;
            }
        });

        return valid;
    }

    const footer = (
        <div className="fixed bottom-0 left-0 flex flex-col justify-center w-full py-4 space-y-6 bg-white shadow md:py-8 md:space-y-0 md:flex-row">
            {questionnaire.sections.length > 1 && 
            <div className="flex items-center justify-center md:justify-start space-x-4 max-w-[840px] w-full font-medium">
                <div onClick={previous} className="flex items-center space-x-2 cursor-pointer hover:text-gray-600">
                    <p>Previous</p>
                    <ChevronLeftIcon width={18} />
                </div>
                <p>{section + 1} / {questionnaire.sections.length}</p>
                <div onClick={next} className="flex items-center space-x-2 cursor-pointer hover:text-gray-600">
                    <ChevronRightIcon width={18} />
                    <p>Next</p>
                </div>
            </div>
            }
            <div className="flex flex-col px-4 space-y-4 md:space-y-0 md:space-x-2 md:flex-row md:px-0">
                <SecondaryButton className="md:w-[180px]" spinning={savingAsDraftInProgress} onClick={saveAsDraft} text="Save as draft" />
                <PrimaryButton disabled={!isValid()} className="md:w-[180px]" spinning={savingInProgress} onClick={submit} text="Submit" />
            </div>
        </div>
    );

    const setValue = (value, index) => {
        const tmpSections = [...questionnaire.sections];
        tmpSections[section].items[index].value = value;

        if(!value && dataContext.getFieldType(tmpSections[section].items[index].fieldTypeId).slug === 'file') {
            tmpSections[section].items[index].fileId = null;
        }

        setQuestionnaire({...questionnaire, sections: tmpSections});
    }

    const activeSection = getSection();

    return (
        <ClientLayout
            footer={footer}
            paddingB="pb-[100px]"
        >
            <div className="flex flex-col px-6 py-6 space-y-12 bg-white rounded">
                <div>
                    <p className="text-2xl font-medium">SECTION {section + 1} - {activeSection.name}</p>
                    <p>{activeSection.description}</p>
                </div>
                <div className="flex flex-col space-y-5">
                    {activeSection.items.map((item, itemIndex) => {
                        return (
                            <div className="flex flex-col items-center w-full space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
                                <div className="flex flex-col justify-center w-full md:w-1/3">
                                    <span className="font-medium text-gray-900">{section + 1}.{itemIndex + 1} {item.question}
                                        {item.isRequired && <span className="ml-1 text-red-500">*</span>}
                                    </span>
                                    <span className="text-sm text-gray-700 ">{item.description}</span>
                                </div>
                                <div className="flex justify-start w-full md:w-2/3">
                                    <FieldSwitch 
                                        type={dataContext.getFieldType(item.fieldTypeId).slug}
                                        value={item.value ? item.value : ""}
                                        isRequired={item.isRequired}
                                        onChange={(value) => setValue(value, itemIndex)}
                                        size="sm" 
                                        className="w-full" 
                                        item={item}    
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="flex items-center justify-center font-medium cursor-pointer hover:text-gray-700">
                    <p onClick={clearSection}>Clear section</p>
                </div>
            </div>
        </ClientLayout>
    );
}

export default ClientQuestionnaire;