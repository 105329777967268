import { useEffect, useState } from "react";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import { useParams } from "react-router-dom";
import programApi from "../../services/apis/programApi";
import { isSuccess, showSuccess } from "../../shared/util";
import { CircleFlag } from "react-circle-flags";
import questionnaireApi from "../../services/apis/questionnaireApi";
import FormForm from "../Forms/forms/FormForm";

const ManageQuestionnaire = () => {
    const [program, setProgram] = useState(null);
    const [questionnaire, setQuestionnaire] = useState(null);
    const { programId } = useParams();
    const programService = programApi();
    const questionnaireService = questionnaireApi(programId, 'programs/');
    const [saveQuestionnaireInProgress, setSaveQuestionnaireInProgress] = useState(false);

    useEffect(() => {
        getProgram();
        getQuestionnaire();
    }, [])

    const getProgram = async () => {
        const getProgramResponse = await programService.getSingle(programId);
        if (isSuccess(getProgramResponse.status)) {
            setProgram(getProgramResponse.data);
        }
    }

    const getQuestionnaire = async () => {
        const getQuestionnaireResponse = await questionnaireService.getAll();
        if (isSuccess(getQuestionnaireResponse.status)) {
            setQuestionnaire(getQuestionnaireResponse.data);

        }
    }

    const handleSave = async (newQuestionnaire) => {
        setSaveQuestionnaireInProgress(true);

        const tmpQestionnaire = { ...newQuestionnaire };
        tmpQestionnaire.sections.forEach((section, index, theArray) => {
            theArray[index].orderNumber = index + 1;

            section.items.forEach((item, yIndex, itemArray) => {
                itemArray[yIndex].orderNumber = yIndex + 1;
                if (item.id === -1) {
                    delete itemArray[yIndex].id;
                }
            });
        });

        const patchProgramQuestionnaireResponse = await questionnaireService.patch(0, tmpQestionnaire);
        if (isSuccess(patchProgramQuestionnaireResponse.status)) {
            getQuestionnaire();
            showSuccess("Questionnaire has been saved");
        }

        setSaveQuestionnaireInProgress(false);
    }
    const getTitle = () => {
        if (!program) {
            return <span></span>;
        }

        return (
            <div className="flex items-center space-x-2">
                <CircleFlag className="hidden md:block" countryCode={program.country.slug} width={22} />
                <p className="hidden md:block">{program.country.name}</p>
                <span className="hidden text-base font-normal text-gray-600 md:block">/</span>
                <p className="hidden text-base font-normal text-gray-600 md:block">{program.name}</p>
                <span className="hidden text-base font-normal text-gray-600 md:block">/</span>
                <p className="text-base font-normal text-gray-600">Questionnaire</p>
            </div>
        );
    }

    return (
        <SidebarLayout title={getTitle()}>
            {questionnaire && 
                <FormForm
                    questionnaire={questionnaire} 
                    setQuestionnaire={setQuestionnaire}
                    save={handleSave}
                    showDetailsForm={false}
                />
            }
        </SidebarLayout>
    );
}

export default ManageQuestionnaire;