import { DocumentTextIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";
import { useDropzone } from 'react-dropzone';

const InlineFileDropzone = ({ file, fileName, onUpload }) => {
    const onDrop = useCallback(acceptedFiles => {
        onUpload(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
                <div className="flex flex-col items-start px-4 py-3 space-y-1 border border-dashed rounded-md cursor-pointer">
                    {(file || fileName) && <div className={`relative flex flex justify-center items-start cursor-default space-x-2`}>
                        {file && <span className="text-blue-600 text-md">{file.name}</span>}
                        {!file && <span className="text-blue-600 text-md">{fileName}</span>}
                        {(file || fileName) && 
                            <div onClick={(event) => { event.stopPropagation(); onUpload(null); }} className="flex space-x-2 text-orange-700 underline cursor-pointer">
                                <XMarkIcon width={22} />
                                {/* <XMarkIcon onClick={(event) => { event.stopPropagation(); onUpload(null); }} width={22} className="rounded cursor-pointer bg-slate-100" /> */}
                            </div>
                        }
                    </div>}
                    {!file && !fileName &&
                    <>
                    {isDragActive 
                        ? <p>Drop the files here ...</p>
                        : <p>Drag 'n' drop or click to select file</p>
                    }
                    </>}
                </div>
            }
        </div>
    );
}

export default InlineFileDropzone;