import { useEffect, useState } from "react";
import { changeField, getFirstError, isValid } from "../../../shared/validate";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { isSuccess } from "../../../shared/util";
import TextField from "../../../components/forms/TextField";
import applicationMemberApi from "../../../services/apis/applicationMemberApi";
import DropdownField from "../../../components/forms/DropdownField";

const EditApplicationMemberForm = ({ onMemberAdded, edit, member, applicationId }) => {
    const applicationMemberService = applicationMemberApi(applicationId);
    const firstNameDefault = {
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        editable: true,
        validators: [
            {
                type: 'required',
                message: 'First name is required.',
                isValid: true,
            },
        ],
    };
    const [firstName, setFirstName] = useState(firstNameDefault);

    const lastNameDefault = {
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        editable: true,
        validators: [
            {
                type: 'required',
                message: 'Last name is required.',
                isValid: true,
            },
        ],
    };

    const memberTypes = [
        {
            value: 'spouse',
            display: 'Spouse',
        },
        {
            value: 'dependent16AndOver',
            display: 'Adult dependent',
        },
        {
            value: 'dependentUnder16',
            display: 'Minor dependent',
        },
    ];
    const [lastName, setLastName] = useState(lastNameDefault);

    const [memberType, setMemberType] = useState(null);

    const [saveMemberInProgress, setSaveMemberInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    useEffect(() => {
        if (edit && member) {
            setFirstName({ ...firstName, value: member.firstName, isValid: true });
            setLastName({ ...lastName, value: member.lastName, isValid: true });
            setMemberType({ ...memberType, value: member.memberType.value, isValid: true });
        }
    }, []);

    const resetForm = () => {
        setFirstName(firstNameDefault);
        setLastName(lastNameDefault);
        setMemberType(null);
    }

    const handleSaveMemberButtonClicked = async () => {
        const memberResponse = edit && member
            ?
            await applicationMemberService.put(member.id, {
                firstName: firstName.value,
                lastName: lastName.value,
            })
            :
            await applicationMemberService.post({
                firstName: firstName.value,
                lastName: lastName.value,
                memberType: memberType,
                applicationId: applicationId,
            });

        if (isSuccess(memberResponse.status)) {
            onMemberAdded(memberResponse.data, edit);
            resetForm();
        } else {
            setErrorMessages([{ message: 'Something went wrong. Please contact system administrator.' }]);
        }
    }

    const isFormValid = () => {
        return isValid([firstName, lastName]) && memberType;
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <form className="flex flex-col space-y-10" onSubmit={onFormSubmit}>
            <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">First name</label>
                    <TextField
                        name='first_name'
                        placeholder='First name'
                        value={firstName.value}
                        disabled={edit && !lastName.editable}
                        isValid={firstName.isValid || !firstName.showValidation}
                        onChange={(e) => setFirstName(changeField(e, { ...firstName }))}
                        onBlur={(e) => setFirstName({ ...firstName, showValidation: true })}
                        errorMessage={firstName.showValidation ? getFirstError(firstName) : ''}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Last name</label>
                    <TextField
                        name='last_name'
                        placeholder='Last name'
                        disabled={edit && !firstName.editable}
                        value={lastName.value}
                        isValid={lastName.isValid || !lastName.showValidation}
                        onChange={(e) => setLastName(changeField(e, { ...lastName }))}
                        onBlur={(e) => setLastName({ ...lastName, showValidation: true })}
                        errorMessage={lastName.showValidation ? getFirstError(lastName) : ''}
                    />
                </div>
                {!edit && <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Member type</label>                    
                    <DropdownField
                        name='client_form'
                        placeholder='Member type'
                        onChange={(option) => setMemberType(option.value)}
                        value={memberType}
                        options={memberTypes}
                    />
                </div>
                }
            </div>
            <div className="">
                <PrimaryButton
                    text="Save Member"
                    spinning={saveMemberInProgress}
                    onClick={handleSaveMemberButtonClicked}
                    disabled={!isFormValid()}
                    type="submit"
                />
            </div>
        </form>
    );
}

export default EditApplicationMemberForm;