const TextArea = ({name, placeholder, value, onChange, onBlur, onKeyDown, className, isValid, infoMessage, errorMessage, successMessage, rows, ...props}) => {
    const getClassName = () => {
        return className ? className + ' relative flex flex-col' : 'relative flex flex-col';
    }

    return (
        <div className={getClassName()}>
            <div className='relative'>
                <textarea 
                    className={`w-full p-3 rounded-md text-gray-900 drop-shadow-sm focus:drop-shadow-none
                    placeholder:silver600 ${isValid ? "border-silver200 focus:border-indigo-200"
                    : "border-red-400 focus:border-red-400"} border-[1.4px] outline-0`}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    value={value}
                    rows={rows}
                    {...props}
                />
            </div>
            <div>
                {errorMessage && <span className='text-sm text-red-600'>{errorMessage}</span>}
                {infoMessage && <span className='text-sm text-orange-400'>{infoMessage}</span>}
                {successMessage && <span className='text-sm text-green-700'>{successMessage}</span>}
            </div>
        </div>
    );
}

TextArea.defaultProps = {
    isValid: true,
}

export default TextArea;