import { DocumentArrowDownIcon, MagnifyingGlassIcon, PencilIcon, PlayIcon, TrashIcon, LockOpenIcon, CursorArrowRippleIcon } from "@heroicons/react/24/outline";
import TextField from "../../components/forms/TextField";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import colors from "../../shared/colors";
import FilterButton from "../../components/buttons/FilterButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { downloadA, getInitials, isSuccess, showSuccess } from "../../shared/util";
import { useEffect, useState } from "react";
import AddClientForm from "./forms/AddClientForm";
import clientApi from "../../services/apis/clientApi";
import UserStatusTag from "../../components/tags/UserStatusTag";
import RichTable from "../../components/tables/RichTable";
import AddCaseForm from "../Cases/forms/AddCaseForm";
import axiosClient from "../../shared/axiosClient";
import BaseModal from "../../components/modals/BaseModal";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import resetPasswordEmailApi from "../../services/apis/resetPasswordEmail";
import resendActivationEmail from "../../services/apis/resendActivationEmail";
import ConfirmModal from "../../components/modals/ConfirmModal";

const Clients = () => {
    const [clients, setClients] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [addClientModalOpen, setAddClientModalOpen] = useState(false);
    const [startApplicationModalOpen, setStartApplicationModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [sortByField, setSortByField] = useState('id');
    const [sortBy, setSortBy] = useState('DESC');
    const [selectedClient, setSelectedClient] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmResetPasswordModal, setShowConfirmResetPasswordModal] = useState(false);
    const [showConfirmResendActivationModal, setShowConfirmResendActivationModal] = useState(false);

    const clientService = clientApi();

    const getClients = async () => {
        setIsLoading(true);

        const params = {
            "limit": 8,
            "page": page,
            "sortBy": sortByField + ":" + sortBy,
        };

        if (searchTerm) {
            params["filter.firstName"] = "$ilike:$or:" + searchTerm;
            params["filter.lastName"] = "$ilike:$or:" + searchTerm;
        }

        const getUsersResponse = await clientService.getAll({ params: params });

        if (isSuccess(getUsersResponse.status)) {
            setTotalPages(getUsersResponse.data.meta.totalPages);
            setClients(getUsersResponse.data.data);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getClients();
    }, [page, sortBy, sortByField, searchTerm]);

    const clientAdded = () => {
        getClients();
        setAddClientModalOpen(false);
        showSuccess("Client has been saved!");
    }

    const caseAdded = () => {
        getClients();
        setStartApplicationModalOpen(false);
        showSuccess("Application has been started!");
    }

    const changeOrder = (field) => {
        if (field !== sortByField) {
            setSortByField(field);
            setSortBy("ASC");
        }

        setSortBy(sortBy === "ASC" ? "DESC" : "ASC");
    }

    const clientTableColumns = [
        {
            display: 'User',
            sortable: true,
            sortName: 'firstName',
            width: 30,
            column: 'name',
            generate: (item) => {
                return (
                    <div className="flex items-center space-x-3">
                        {item.firstName && item.lastName ?
                            <>
                                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-slate-100">
                                    <span className="text-slate-900">{getInitials(item.firstName + " " + item.lastName)}</span>
                                </div>
                                <span className="text-gray-900">{item.firstName} {item.lastName}</span>
                            </> :
                            <span className="pl-12 text-gray-900">-</span>}
                    </div>
                );
            }
        },
        {
            display: 'Email',
            sortable: true,
            sortName: 'email',
            width: 30,
            column: 'email',
        },
        {
            display: 'Status',
            sortable: true,
            sortName: 'status',
            width: 23,
            column: 'status',
            textTransform: (item) => <UserStatusTag status={item.user.status} />
        },
    ];

    const tableActions = [
        {
            title: 'Reset Password',
            icon: <LockOpenIcon width={20} />,
            show: (item) => item.user.status === 'active',
            onClick: (item) => handleResetPasswordButtonClicked(item),
        },
        {
            title: 'Resend Activation',
            icon: <CursorArrowRippleIcon width={20} />,
            show: (item) => item.user.status !== 'active',
            onClick: (item) => handleResendActivationButtonClicked(item),
        },
        {
            title: 'Start Application',
            icon: <PlayIcon width={20} />,
            confirm: false,
            onClick: (item) => {
                setSelectedClient(item);
                setStartApplicationModalOpen(true);
            },
            show: (item) => !item.agentId
        },
        {
            title: 'Edit',
            icon: <PencilIcon width={20} />,
            confirm: false,
            onClick: (item) => {
                setSelectedClient(item);
                setAddClientModalOpen(true);
            }
        },
        {
            title: 'Delete',
            icon: <TrashIcon width={20} />,
            onClick: (item) => handleDeleteClientClicked(item),
        },
        {
            title: 'Download Contract',
            icon: <DocumentArrowDownIcon width={20} />,
            confirm: false,
            show: (item) => item.contractFileId,
            onClick: async (item) => {
                const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/clients/" + item.id + "/file");
                if (isSuccess(downloadResponse.status)) {
                    downloadA(downloadResponse.data.url);
                }
            }
        }
    ];

    const getRightSidebarContent = () => {
        if (!addClientModalOpen && !startApplicationModalOpen) {
            return null;
        }

        return addClientModalOpen ?
            <AddClientForm onClientAdded={clientAdded} client={selectedClient} edit={!!selectedClient} /> :
            <AddCaseForm client={selectedClient} />
    }

    const handleDeleteClientClicked = (client) => {
        setSelectedClient(client);
        setShowConfirmDeleteModal(true);
    }

    const handleResetPasswordButtonClicked = async (client) => {
        setSelectedClient(client);
        setShowConfirmResetPasswordModal(true);
    }

    const resetPassword = async () => {
        const reserPasswordEmailService = resetPasswordEmailApi();
        setShowConfirmResetPasswordModal(false);
        const sendResetPasswordResponse = await reserPasswordEmailService.post({ email: selectedClient.user.username });

        if (isSuccess(sendResetPasswordResponse.status)) {
            showSuccess("The password reset link has been sent!");
        }

        setSelectedClient(null);
    }

    const handleResendActivationButtonClicked = async (client) => {
        setSelectedClient(client);
        setShowConfirmResendActivationModal(true);
    }

    const resendActivation = async () => {
        const resendActivationEmailService = resendActivationEmail();
        setShowConfirmResendActivationModal(false);
        console.log(selectedClient)
        const sendResendActivationResponse = await resendActivationEmailService.post({ email: selectedClient.user.username });

        if (isSuccess(sendResendActivationResponse.status)) {
            showSuccess("The activation link has been sent!");
        }

        setSelectedClient(null);
    }

    const deleteClient = async () => {
        const deleteClientResponse = await clientService.remove(selectedClient.id);
        if (isSuccess(deleteClientResponse.status)) {
            showSuccess("Client has been deleted");
            setSelectedClient(null);
            setShowConfirmDeleteModal(false);
            getClients();
        }
    }

    const getRightSidebarTitle = () => {
        return addClientModalOpen ? selectedClient ? 'Edit client' : 'Add new client' : selectedClient ?
            (
                <span>Start application
                    <span className="text-gray-500">{" (" + selectedClient.firstName + " " + selectedClient.lastName + ")"}</span>
                </span>
            ) :
            "Start application";
    }

    const onRightSidebarClose = () => {
        setAddClientModalOpen(false);
        setStartApplicationModalOpen(false);
    }

    return (
        <div>
            <SidebarLayout title="Clients"
                showRightSidebar={addClientModalOpen || startApplicationModalOpen}
                rightSidebarTitle={getRightSidebarTitle()}
                rightSidebarContent={getRightSidebarContent()}
                onRightSidebarClose={onRightSidebarClose}>
                <div className="flex flex-col mb-4 space-y-3 md:space-y-0 md:space-x-4 md:flex-row">
                    <div className="w-full md:w-[380px]">
                        <TextField placeholder="Search" icon={<MagnifyingGlassIcon width={20} color={colors.silver600} />}
                            value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    <div className="flex space-x-3 grow">
                        {/* <div className="w-1/2">
                            <FilterButton text="Filters" className="md:w-[220px]" filtersCount={2} />
                        </div> */}
                        <div className="flex justify-end w-full">
                            <PrimaryButton className="w-full md:w-[220px]" text="Add new client" onClick={() => {
                                setSelectedClient(null);
                                setAddClientModalOpen(true);
                            }} />
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                    </div>
                </div>
                <RichTable
                    columns={clientTableColumns}
                    items={clients}
                    tableActions={tableActions}
                    loading={isLoading}
                    page={page}
                    setPage={(page) => setPage(page)}
                    totalPages={totalPages}
                    changeOrder={changeOrder}
                    sortByField={sortByField}
                    sortBy={sortBy}
                    actionsStyle="w-[128px]"
                />
            </SidebarLayout>

            <BaseModal show={showConfirmDeleteModal} onClose={() => setShowConfirmDeleteModal(false)}>
                <div className="flex flex-col space-y-12 w-[540px]">
                    <div className="flex flex-col space-y-6">
                        <p className="text-xl font-medium">Confirm client deletion</p>
                        <div className="flex flex-col space-y-4">
                            <p className="">Are you sure you want to delete this client?</p>
                        </div>
                    </div>
                    <div className="flex space-x-4">
                        <PrimaryButton className="w-1/2" onClick={() => deleteClient()} text="Confirm" />
                        <SecondaryButton className="w-1/2" onClick={() => setShowConfirmDeleteModal(false)} text="Cancel" />
                    </div>
                </div>
            </BaseModal>
            <ConfirmModal
                show={showConfirmResetPasswordModal}
                title="Confirm reset password"
                message={`Are you sure you want to reset password for ${selectedClient?.firstName} ${selectedClient?.lastName}`}
                onConfirm={resetPassword}
                onClose={() => {
                    setShowConfirmResetPasswordModal(false);
                    setSelectedClient(null);
                }}
            />
            <ConfirmModal
                show={showConfirmResendActivationModal}
                title="Confirm resend of activation email"
                message={`Are you sure you want to resend activation email for ${selectedClient?.firstName} ${selectedClient?.lastName}`}
                onConfirm={resendActivation}
                onClose={() => {
                    setShowConfirmResendActivationModal(false)
                    setSelectedClient(null);
                }}
            />
        </div>
    );
}

export default Clients;