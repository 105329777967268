export const questionnaireStatuses = [
    {
        value: "Draft",
        display: "Draft",
    },
    {
        value: "Changes required",
        display: "Changes required",
    },
    {
        value: "Submitted",
        display: "Submitted",
    },
];