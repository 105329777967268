import { useEffect, useState } from "react";
import { changeField, getFirstError, isValid } from "../../../shared/validate";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { isSuccess } from "../../../shared/util";
import questionnaireApi from "../../../services/apis/questionnaireApi";
import TextField from "../../../components/forms/TextField";
import TextArea from "../../../components/forms/TextArea";
import SSelect from "../../../components/forms/SSelect";
import Toggle from "../../../components/forms/Toggle";

const NewFormForm = ({ onFormSaved, templates }) => {
    const nameDefault = {
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'Name is required.',
                isValid: true,
            },
        ],
    };
    const [name, setName] = useState(nameDefault);
    const [description, setDescription] = useState("");
    const [template, setTemplate] = useState(null);
    const [isDefault, setIsDefault] = useState(false);


    const [saveFormInProgress, setSaveFormInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const questionnaireService = questionnaireApi();

    const resetForm = () => {
        setName(nameDefault);
        setDescription('');
        setTemplate(null);
        setIsDefault(false)
    }

    const handleSaveButtonClicked = async () => {
        setSaveFormInProgress(true);

        let sections = [
            {
                name: 'General',
                items: [],
            }
        ];

        if(template) {
            const getQuestionnaireResponse = await questionnaireService.getSingle(template);
            if(isSuccess(getQuestionnaireResponse.status)) {
                sections = getQuestionnaireResponse.data.sections;
            }
        }

        const saveQuestionnaireResponse = await questionnaireService.post({
            name: name.value,
            description: description,
            isTemplate: true,
            sections: sections,
            isDefault: isDefault
        });

        if(isSuccess(saveQuestionnaireResponse.status)) {
            onFormSaved(saveQuestionnaireResponse.data);            
            resetForm();
        } else {
            setErrorMessages([{ message: 'Something went wrong. Please contact system administrator.' }]);
        }

        setSaveFormInProgress(false);
    }

    const isFormValid = () => {
        return isValid([name]);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <form className="flex flex-col space-y-10" onSubmit={onFormSubmit}>
            <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">From Template</label>
                    <SSelect
                        name='template'
                        placeholder='Template'
                        onChange={(option) => setTemplate(option.value)}
                        value={template}
                        options={templates}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Name</label>
                    <TextField
                        name='name'
                        placeholder='Name'
                        value={name.value}
                        isValid={name.isValid || !name.showValidation}
                        onChange={(e) => setName(changeField(e, { ...name }))}
                        onBlur={(e) => setName({ ...name, showValidation: true })}
                        errorMessage={name.showValidation ? getFirstError(name) : ''}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Description</label>
                    <TextArea 
                        name='description'
                        placeholder='Description'
                        value={description}
                        rows={4}
                        isValid={true}
                        onChange={(e) => setDescription(e.target.value)}
                        onBlur={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Is Default</label>
                    <Toggle
                        name='isDefault'
                        value={isDefault}
                        onChange={() => setIsDefault(!isDefault)}
                    />
                </div>
            </div>
            <div className="">
                <PrimaryButton
                    text="Save form"
                    spinning={saveFormInProgress}
                    onClick={handleSaveButtonClicked}
                    disabled={!isFormValid()}
                    type="submit"
                />
            </div>
            <div className="flex flex-col pl-2 mt-4 space-y-1">
                {errorMessages && errorMessages.map((errorMessage, index) => (
                    <span key={'add-user-err-message-' + index} className="text-red-600">{errorMessage.message}</span>
                ))}
            </div>
        </form>
    );
}

export default NewFormForm;