const PublicLayoutBackground = () => {
    return (
        <div className="absolute top-0 left-0 flex w-full h-full -z-[1] md:min-h-[800px]">
            <div className="w-4/5 h-full md:w-1/2 md:w-1/4 bg-cultured" />
            <div className="w-0 h-full md:w-1/2 " />
            <div className="w-1/5 h-full md:w-1/2 md:w-1/4 bg-dirt opacity-60" />
        </div>
    );
}

export default PublicLayoutBackground;