import { ApiCore } from "../utilities/core";

const questionnaireApi = (id, prefix) => {
    return new ApiCore({
        getAll: true,
        getSingle: true,
        post: true,
        put: true,
        patch: true,
        remove: true,
        url: id ? prefix + id + '/questionnaires' : 'questionnaires'
    });
}

export default questionnaireApi;