import { useContext, useEffect, useState } from "react";
import SidebarLayout from "../../components/layouts/SidebarLayout"
import { AuthContext } from "../../store/AuthContext";
import conversationApi from "../../services/apis/conversationApi";
import { generatePaginationArray, isSuccess } from "../../shared/util";
import MessageItem from "../ClientBoard/MessageItem";
import TextArea from "../../components/forms/TextArea";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { PaperAirplaneIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import colors from "../../shared/colors";
import moment from "moment";
import TextField from "../../components/forms/TextField";
import { useInterval } from "../../hooks/CustomHooks";

const Support = () => {
    const authContext = useContext(AuthContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedConversation, setSelectedConversation] = useState(null);
    const conversationService = conversationApi();
    const [visibleConversations, setVisibleConversations] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalPagesMessages, setTotalPagesMessages] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [sendingInProgress, setSendingInProgress] = useState(false);
    const [message, setMessage] = useState();
    const [currentDate, setCurrentDate] = useState(null);
    const [pageMessage, setPageMessages] = useState(1);

    useEffect(() => {
        setPageMessages(1);
    }, [selectedConversation])

    useEffect(() => {
        if (!selectedConversation && visibleConversations && visibleConversations.length > 0) {
            setSelectedConversation(visibleConversations[0]);
        }

        if (selectedConversation) {
            fetchMessages();
        }
    }, [
        selectedConversation,
        visibleConversations,
        pageMessage,
    ]);

    useEffect(() => {
        fetchConversations();
        setCurrentDate(new Date());
    }, [page])

    useEffect(() => {
        if (searchTerm) {
            fetchConversations(true);
            setPage(1)
        }
        setCurrentDate(new Date());
    }, [searchTerm]);


    useInterval(() => {
        fetchChangedConversations();
    }, 30000);

    const fetchConversations = async (reset) => {
        setLoading(true);

        try {
            const params = {
                'sortBy': 'updatedAt:DESC',
                "limit": 20,
                "page": reset ? 1 : page,
            };

            if (searchTerm) {
                params["filter.name"] = "$ilike:$or:" + searchTerm;
            }

            const getConversationsResponse = await conversationService.getAll({ params: params });

            setTotalPages(getConversationsResponse.data.meta.totalPages)
            const newConversations = getConversationsResponse.data.data;
            if (reset) {
                setVisibleConversations(newConversations);
            } else {
                setVisibleConversations(prevConversations => [...prevConversations, ...newConversations]);
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    const fetchChangedConversations = async () => {
        const params = {
            'sortBy': 'updatedAt:DESC',
            "limit": 0,
            "filter.updatedAt": "$gte:" + moment(currentDate).utc().format("YYYY-MM-DD h:mm:ss a")
        };

        if (searchTerm) {
            params["filter.name"] = "$ilike:$or:" + searchTerm;
        }

        const getConversationsResponse = await conversationService.getAll({ params: params });
        console.log(getConversationsResponse)
        setCurrentDate(new Date());
        const updatedConversations = getConversationsResponse.data.data;

        const filteredVisibleConversations = visibleConversations.filter(conversation =>
            !updatedConversations.some(updatedConversation => updatedConversation.id === conversation.id)
        );
        const updatedVisibleConversations = updatedConversations.concat(filteredVisibleConversations);
        setVisibleConversations(updatedVisibleConversations);
    };


    const fetchMessages = async () => {
        const getMessagesResponse = await conversationService.getSingle(selectedConversation.id, {
            params: {
                "sortBy": "createdAt:ASC",
                "limit": 20,
                "page": pageMessage,
            }
        });
        if (isSuccess(getMessagesResponse.status)) {
            const tmpMessages = getMessagesResponse.data.data;
            const firstUnreadIndex = tmpMessages.findIndex(x => !didUserRead(x));
            tmpMessages[firstUnreadIndex] = { ...tmpMessages[firstUnreadIndex], showUnreadMessage: true };
            setTotalPagesMessages(getMessagesResponse.data.meta.totalPages)

            if (pageMessage === 1) {
                setMessages(tmpMessages);
            } else {
                setMessages(prevMessages => [...prevMessages, ...tmpMessages]);
            }

        }
    };

    const didUserRead = (message) => {
        return message.authorId === authContext.session.userId || message.reads.map(x => x.user.id).includes(authContext.session.userId);
    }

    const sendMessage = async () => {
        setSendingInProgress(true);

        const createMessageResponse = await conversationService.post({ message: message }, null, selectedConversation.id);
        if (isSuccess(createMessageResponse.status)) {
            setPageMessages(1);
            fetchMessages();
            setMessage("");
        }

        setSendingInProgress(false);
    }

    const readMessages = async () => {
        const messageIds = messages.filter(x => !didUserRead(x)).map(x => x.id);
        if (messageIds.length === 0) {
            return;
        }

        const readMessagesResponse = await conversationService.patch(null, { ids: messageIds });

        if (isSuccess(readMessagesResponse.status)) {
            fetchMessages();
        }
    }

    const getUnreadCount = (conversation) => {
        const part = conversation.participants.find(x => x.userId === authContext.session.userId);
        return part ? part.unreadMessageCount : 0;
    }

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (page > totalPages || page === -1) {
            return
        }

        if (scrollTop + clientHeight >= scrollHeight) {
            setPage(page + 1);
        }
    };

    const handleScrollMessages = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (pageMessage > totalPagesMessages || page === -1) {
            return
        }

        if (scrollTop + clientHeight >= scrollHeight) {
            setPageMessages(pageMessage + 1);
        }
    };

    return (
        <SidebarLayout containerClass="md:py-0 md:px-0 overflow-y-hidden w-full">
            <div className="flex w-full h-full">
                <div className="flex flex-col w-full h-full grow">
                    <div className="flex flex-col overflow-y-auto grow">
                        <div onScroll={handleScrollMessages} className="h-full overflow-y-auto scroll-smooth">
                            <div className="flex flex-col justify-end w-full overflow-y-auto grow">
                                {messages && messages.map((message, index) => (
                                    <div key={"message-item-container-" + index} className={`py-3`}>
                                        {message.showUnreadMessage &&
                                            <div className="flex justify-center mb-2">
                                                <p className="px-4 py-2 font-medium text-center text-gray-900 border-2 rounded-full w-fit">New messages</p>
                                                {/* <div className={`w-full h-1 bg-gray-500`}></div> */}
                                            </div>
                                        }
                                        <div className={`px-6`}>
                                            <MessageItem message={message} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-end px-4 py-4 space-y-2 h-fit">
                        <TextArea
                            className="w-full"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onFocus={readMessages}
                            rows={4}
                            disabled={!selectedConversation}
                        />
                        <PrimaryButton
                            spinning={sendingInProgress}
                            onClick={sendMessage}
                            disabled={!message || !selectedConversation}
                            className="w-[222px]"
                            icon={<PaperAirplaneIcon width={22} color={colors.white} />}
                            text="Send"
                            size="sm"
                        />
                    </div>
                </div>
                <div onScroll={handleScroll} className="w-[380px] h-full flex flex-col border-l-2 py-6 overflow-y-auto">
                    <p className="px-6 pb-4 text-gray-700 border-b">Conversations</p>
                    <TextField placeholder="Search" icon={<MagnifyingGlassIcon width={20} color={colors.silver600} />} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    {visibleConversations
                        .map((x, index) => (
                            <div onClick={() => setSelectedConversation(x)} key={"conversation-item-container-" + index}
                                className={`px-6 py-3 cursor-pointer hover:bg-gray-50 relative 
                            ${selectedConversation && selectedConversation.id === x.id && "bg-orange-100"}`}>
                                <p>{x.name}</p>
                                {getUnreadCount(x) !== 0 &&
                                    <p className="text-dirt">{getUnreadCount(x)} new messages</p>
                                }
                                <p className="text-sm italic text-coyoteb">{moment(x.updatedAt).fromNow()}</p>
                                {/* <span className="absolute flex items-center justify-center w-8 h-8 text-sm text-white rounded-full right-2 top-2 bg-coyoteb">12</span> */}
                            </div>
                        ))}
                </div>
            </div>
        </SidebarLayout>
    );
}

export default Support;
