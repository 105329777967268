import { ApiCore } from "../utilities/core";

const countryApi = () => {
    return new ApiCore({
        getAll: true,
        getSingle: false,
        post: false,
        put: false,
        patch: false,
        remove: false,
        url: 'countries'
    });
}

export default countryApi;