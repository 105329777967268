import { createContext, useEffect, useState } from "react";

export const UIContext = createContext({
    sidebarVisible: false,
    showBackdrop: false,
    showLightBackdrop: false,
    showAccountDropdown: false,
    accountDropdownContent: null,
    showNotificationDropdown: false,
    notificationDropdownContent: null,
    showRightSidebar: false,
    onRightSidebarClose: false,
    rightSidebarTitle: false,
    rightSidebarContent: false,
    showSecondPane: false,
});

const UIContextProvider = ({ children }) => {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showLightBackdrop, setShowLightBackdrop] = useState(false);
    const [showAccountDropdown, setShowAccountDropdown] = useState(false);
    const [accountDropdownContent, setAccountDropdownContent] = useState(null);
    const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
    const [notificationDropdownContent, setNotificationDropdownContent] = useState(null);
    const [showRightSidebar, setShowRightSidebar] = useState(false);
    const [onRightSidebarClose, setOnRightSidebarClose] = useState(false);
    const [onRightSidebarTitle, setOnRightSidebarTitle] = useState(false);
    const [onRightSidebarContent, setOnRightSidebarContent] = useState(false);
    const [showSecondPane, setShowSecondPane] = useState(false);

    useEffect(() => {
        if (showRightSidebar) {
            setShowBackdrop(showRightSidebar);
        } else {
            setTimeout(() => setShowBackdrop(showRightSidebar), 300);
        }
    }, [showRightSidebar]);

    const value = {
        sidebarVisible: sidebarVisible,
        showBackdrop: showBackdrop,
        showLightBackdrop: showLightBackdrop,
        showAccountDropdown: showAccountDropdown,
        accountDropdownContent: accountDropdownContent,
        showNotificationDropdown: showNotificationDropdown,
        notificationDropdownContent: notificationDropdownContent,
        showRightSidebar: showRightSidebar,
        onRightSidebarClose: onRightSidebarClose,
        onRightSidebarTitle: onRightSidebarTitle,
        onRightSidebarContent: onRightSidebarContent,
        showSecondPane: showSecondPane,
        setSidebarVisible: setSidebarVisible,
        setShowBackdrop: setShowBackdrop,
        setShowLightBackdrop: setShowLightBackdrop,
        setShowAccountDropdown: setShowAccountDropdown,
        setAccountDropdownContent: setAccountDropdownContent,
        setShowNotificationDropdown: setShowNotificationDropdown,
        setNotificationDropdownContent: setNotificationDropdownContent,
        setShowRightSidebar: setShowRightSidebar,
        setOnRightSidebarClose: setOnRightSidebarClose,
        setOnRightSidebarTitle: setOnRightSidebarTitle,
        setOnRightSidebarContent: setOnRightSidebarContent,
        setShowSecondPane: setShowSecondPane,
    };

    return (
        <UIContext.Provider value={value}>{children}</UIContext.Provider>
    );
}

export default UIContextProvider;