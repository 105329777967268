import { createContext, useContext, useEffect, useState } from "react";
import { isSuccess, removeToken, setToken, showNotification } from "../shared/util";
import clientApi from "../services/apis/clientApi";
import caseApi from "../services/apis/caseApi";
import questionnaireApi from "../services/apis/questionnaireApi";
import notificationApi from "../services/apis/notificationApi";
import conversationApi from "../services/apis/conversationApi";
import { useInterval } from "../hooks/CustomHooks";

export const AuthContext = createContext({
    session: null,
    client: null,
    application: null,
    questionnaire: null,
    notifications: null,
    conversations: null,
    unreadMessages: null,
});

const AuthContextProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const [client, setClient] = useState(null);
    const [application, setApplication] = useState(null);
    const [questionnaire, setQuestionnaire] = useState(null);
    const [notifications, setNotifications] = useState(null);
    const [conversations, setConversations] = useState(null);
    const [selectedConversation, setSelectedConversation] = useState(null);

    const clientService = clientApi();
    const caseService = caseApi();
    const notificationService = notificationApi();
    const conversationService = conversationApi();

    useEffect(() => {
        if (session) {
            fetchClientData();
            fetchNotifications();
            fetchConversations();
        } else {
            setClient(null);
            setApplication(null);
            setQuestionnaire(null);
            setNotifications(null);
            setConversations(null);
            setSelectedConversation(null);
        }
    }, [session]);

    useInterval(() => {
        if (session) {
            fetchNotifications();
            fetchConversations();
        }
    }, 30000);

    const login = (session) => {
        logout();
        setSession(session);
        setToken(session.token);
    }

    const logout = () => {
        setSession(null);
        removeToken();
    }

    const setClientData = (clientData, applicationData, questionnaireData) => {
        setClient(clientData);
        setApplication(applicationData);
        setQuestionnaire(questionnaireData);
    }

    const getNotificationLink = (notification) => {
        if (!notification.metas || notification.metas.length === 0) {
            return null;
        }

        switch (notification.type) {
            case 'application_created': {
                const applicationId = notification.metas.find(x => x.key === 'applicationId');
                return applicationId ? '/applications/' + applicationId.value : null;
            }
            case 'compliance_report_file_created': {
                const applicationId = notification.metas.find(x => x.key === 'applicationId');
                return applicationId ? '/applications/' + applicationId.value : null;
            }
            case 'document_uploaded': {
                const applicationId = notification.metas.find(x => x.key === 'applicationId');
                return applicationId ? '/applications/' + applicationId.value : null;
            }
            case 'document_changes_required': {
                return '/documents';
            }
            case 'questionnaire_changes_required': {
                return '/questionnaire';
            }
            default: {
                return null;
            }
        }
    }

    const fetchNotifications = async () => {
        const getNotificationResponse = await notificationService.getAll({ params: { 'sortBy': 'createdAt:DESC' } });
        if (isSuccess(getNotificationResponse.status)) {
            const tmpNotifications = getNotificationResponse.data.data.map(x => { return { ...x, link: getNotificationLink(x) } })

            if (notifications) {
                const newNotifications = tmpNotifications.filter(x => !notifications.map(y => y.id).includes(x.id));

                if (newNotifications.length < 4) {
                    newNotifications.forEach((element) => {
                        showNotification(element.message);
                    });
                }
            }

            setNotifications(tmpNotifications);
        }
    }

    const fetchConversations = async () => {
        const getConversationsRespomse = await conversationService.getAll({ params: { 'sortBy': 'updatedAt:DESC' } });
        if (isSuccess(getConversationsRespomse.status)) {
            setConversations(getConversationsRespomse.data.data);
            if (getConversationsRespomse.data.data.length > 0) {
                setSelectedConversation(getConversationsRespomse.data.data[0]);
            }
        }
    }

    const fetchClientData = async (sessionData) => {
        if (!sessionData || sessionData.role !== 'client' || sessionData.clients.length === 0) {
            return;
        }

        const clientId = sessionData.clients[0].id;
        const getClientResponse = await clientService.getSingle(clientId);
        if (!isSuccess(getClientResponse.status)) {
            return;
        }

        const getClientApplicationsResponse = await caseService.getAll({ params: { "filter.clientId": clientId } });
        if (!isSuccess(getClientApplicationsResponse.status) && getClientApplicationsResponse.data.data.length === 0) {
            return;
        }

        const application = getClientApplicationsResponse.data.data[0];
        const questionnaireService = questionnaireApi(application.programId, 'programs/');

        const getQuestionnaireResponse = await questionnaireService.getAll();
        if (!isSuccess(getQuestionnaireResponse.status) && getQuestionnaireResponse.data.data.length === 0) {
            return;
        }

        setClientData(getClientResponse.data, application, getQuestionnaireResponse.data);
    }

    const value = {
        session: session,
        client: client,
        application: application,
        questionnaire: questionnaire,
        notifications: notifications,
        conversations: conversations,
        selectedConversation: selectedConversation,
        setSession: setSession,
        setClient: setClient,
        setApplication: setApplication,
        setQuestionnaire: setQuestionnaire,
        login: login,
        logout: logout,
        setClientData: setClientData,
        fetchClientData: fetchClientData,
        setNotifications: setNotifications,
        setConversations: setConversations,
        setSelectedConversation: setSelectedConversation,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}

export default AuthContextProvider;