import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import BaseModal from "./BaseModal";

const ConfirmModal = ({show, onClose, onConfirm, title, message, data}) => {
    return (
        <BaseModal show={show} onClose={onClose}>
            <div className="flex flex-col space-y-12 w-[540px]">
                <div className="flex flex-col space-y-6">
                    <p className="text-xl font-medium">{title}</p>
                    <div className="flex flex-col space-y-4">
                        <p className="">{message}</p>
                    </div>
                </div>
                <div className="flex space-x-4">
                    <PrimaryButton className="w-1/2" onClick={() => onConfirm(data)} text="Confirm" />
                    <SecondaryButton className="w-1/2" onClick={onClose} text="Cancel" />
                </div>
            </div>
        </BaseModal>
    );
}

export default ConfirmModal;