import { useContext, useEffect, useState } from "react";
import StatusTag from "../tags/StatusTag";
import { useOutsideClick } from "../../hooks/CustomHooks";
import { UIContext } from "../../store/UIContext";

const StatusDropdown = ({value, statuses, onChange, align, canEdit}) => {
    const [open, setOpen] = useState(false);
    const ref = useOutsideClick(() => setOpen(false));
    const uiContext = useContext(UIContext);

    useEffect(() => {
        uiContext.setShowBackdrop(open);
    }, [open])

    const handleOpen = () => {
        if(!canEdit) {
            return;
        }
        setOpen(!open);
    };

    const onOptionClick = (option) => {
        if(!canEdit) {
            return;
        }
        onChange(option);
        setOpen(false);
    }

    let display = null;
    if(value && statuses) {
        const selectedOption = statuses.find(x => x.value === value);
        if(selectedOption) {
            display = selectedOption.display;
        }
    }

    const items = statuses.filter(x => x.value !== value);
    
    return (
        <div ref={ref} className={`relative flex flex-col space-y-1 w-fit ${align === 'right' ? "items-end" : "items-start"} ${open && "z-[18]"}`}>
            <StatusTag onClick={handleOpen} className="cursor-pointer hover:opacity-75" status={display} />
            {open && 
                <div className={`flex flex-col py-2 space-y-1.5 w-[310px] rounded absolute
                    top-8 ${align === 'right' ? "items-end" : "items-start"}`}>
                    {items.map((status, index) => (
                        <StatusTag onClick={() => onOptionClick(status)} className="cursor-pointer hover:opacity-75" status={status.display} />
                    ))}
                </div>
            }
        </div>
    );
}

export default StatusDropdown;