import ErrorLayout from "../../components/layouts/ErrorLayout";

const NotFound = () => {
    return (
        <ErrorLayout>
            <p class="text-2xl">This page does not exist.</p>
        </ErrorLayout>
    );
}

export default NotFound;