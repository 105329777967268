import { ArrowUpOnSquareIcon, DocumentIcon } from "@heroicons/react/24/outline";
import StatusTag from "../../components/tags/StatusTag";
import { isSuccess } from "../../shared/util";
import colors from "../../shared/colors";
import StatusDropdown from "../../components/dropdowns/StatusDropdown";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { useDropzone } from "react-dropzone";
import { useCallback, useContext, useState } from "react";
import { AuthContext } from "../../store/AuthContext";
import axiosClient from "../../shared/axiosClient";
import DocumentRelatedFileList from "../../components/documents/DocumentRelatedFileList";

const DocumentListItem = ({ document, index, onFileClick, onStatusChangeClick, canEdit, getDocuments }) => {

    const [documents, setDocuments] = useState(null);
    const authContext = useContext(AuthContext);
    const AdminRole = authContext.session.role === 'admin';

    const statuses = [
        {
            value: "Changes required",
            display: "Changes required",
        },
        {
            value: "Pending agent's approval",
            display: "Pending agent's approval",
        },
        {
            value: "Waiting for physical copy",
            display: "Waiting for physical copy",
        },
        {
            value: "Accepted",
            display: "Accepted",
        },
    ];

    const uploadFile = async (file, document) => {
        if (file) {
            let formData = new FormData();
            formData.append('file', file);

            const fileUploadResponse = await axiosClient.post("documents/" + document.id + "/file", formData, {
                headers: { "Content-type": "multipart/form-data" }
            });

            if (isSuccess(fileUploadResponse.status)) {
                setDocuments(getDocuments);
            }
        }
    }

    const upload = async (file) => {
        setUploading(true);
        await uploadFile(file, document);
        setUploading(false);
    }

    const onDrop = useCallback(acceptedFiles => {
        if (document.status !== "Changes required") {
            return;
        }

        upload(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const { onClick, ...rootProps } = getRootProps();
    const [uploading, setUploading] = useState(false);

    return (
        <div {...rootProps}>
            <input {...getInputProps()} />
            {
                <div className={`px-6 py-6 space-y-6 rounded ${isDragActive ? "bg-gray-100 border border-dashed" : "bg-gray-50 border border-gray-100"}`}>
                    <div className="flex items-center mb-4">
                        <p className="w-3/5 font-semibold text-md">{document.name}</p>
                        <div className="flex justify-end w-2/5">
                            <StatusDropdown
                                value={document.status}
                                statuses={statuses}
                                align="right"
                                onChange={(option) => onStatusChangeClick(document, option.value)}
                                canEdit={canEdit}
                            />
                        </div>
                    </div>
                    <p className="text-gray-600 whitespace-pre">{document.description}</p>
                    <div className="flex items-end">
                        <div className="w-3/5">
                            <div className="flex flex-col space-y-6">
                                {document.file && <div className="flex items-center space-x-2 text-orange-500 cursor-pointer hover:text-orange-700" onClick={() => onFileClick(document)}>
                                    <DocumentIcon width={22} />
                                    <p className="font-medium text-md">{document.file.name}</p>
                                </div>}
                                {document.status === "Changes required" && <SecondaryButton
                                    lIcon={<ArrowUpOnSquareIcon width={22} />}
                                    text="Upload document"
                                    className="max-w-[280px]"
                                    size="sm"
                                    onClick={onClick}
                                    spinning={uploading}
                                    disabled={!AdminRole}
                                />}
                            </div>
                        </div>
                        <div className="flex justify-end w-2/5">
                            {/* <div className="flex justify-end space-x-2 cursor-pointer text-dirt hover:text-coyoteb">
                            <ExclamationCircleIcon width={22} />
                            <span>Instructions</span>
                        </div> */}
                        </div>
                    </div>
                    <DocumentRelatedFileList document={document} canEdit={false} />
                </div>
            }
        </div>
    );
}

export default DocumentListItem;