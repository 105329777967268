import { useContext } from "react";
import { AuthContext } from "../../store/AuthContext";
import { getInitials } from "../../shared/util";

const UserAvatar = ({ auth, firstName, lastName }) => {
    const authContext = useContext(AuthContext);

    const getAuthName = () => {
        return authContext.session.role === 'client' && authContext.session.clients && authContext.session.clients.length > 0
            ? authContext.session.clients[0].firstName + " " + authContext.session.clients[0].lastName
            : authContext.session.firstName + " " + authContext.session.lastName;
    }

    const getName = () => {
        if(auth) {
            return getAuthName();
        }

        return firstName + " " + lastName;
    }

    return (
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-slate-100">
            <span className="font-medium text-slate-900">{getInitials(getName())}</span>
        </div>
    );
}

export default UserAvatar;