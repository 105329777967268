import { useContext, useState } from "react";
import SSelect from "../forms/SSelect";
import TextField from "../forms/TextField";
import { DataContext } from "../../store/DataContext";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Toggle from "../forms/Toggle";
import TextArea from "../forms/TextArea";
import SecondaryButton from "../buttons/SecondaryButton";

const QuestionItemForm = ({ question, onChangeQuestion, onAddQuestion, onAddQuestionClose, 
        loadingFieldValues, fieldValues, addFieldValue }) => {
    const dataContext = useContext(DataContext);
    const [fieldTypeValue, setFieldTypeValue] = useState("");
    
    const getQuestionChange = (name, value) => {
        const tmpQuestion = { ...question };
        tmpQuestion[name] = value;

        return tmpQuestion;
    }

    return (
        <div className="flex flex-col space-y-8 w-full min-w-min px-8 py-5 border-b-[1.5px] bg-slate-50 border-b-slate-200">
            <div className="flex w-full space-x-4">
                <div className="min-w-[210px] md:w-[50%]">
                    <div className="flex flex-col items-start space-y-1">
                        <span>Question</span>
                        <TextField
                            className="w-full mr-2"
                            value={question.question}
                            onChange={(e) => onChangeQuestion(getQuestionChange('question', e.target.value))}
                        />
                    </div>
                </div>
                <div className="min-w-[210px] md:w-[25%]">
                    <div className="flex flex-col items-start space-y-1">
                        <span>Type</span>
                        <SSelect
                            className="w-full mr-2"
                            options={dataContext.getDropdownFieldTypes()}
                            value={question.fieldTypeId}
                            onChange={(option) => onChangeQuestion(getQuestionChange('fieldTypeId', option.value))}
                        />
                    </div>
                </div>
                <div className="min-w-[210px] md:w-[25%]">
                    <div className="flex flex-col items-start space-y-1">
                        <span>Required</span>
                        <Toggle
                            value={question.isRequired}
                            onChange={() => onChangeQuestion(getQuestionChange('isRequired', !question.isRequired))}
                        />
                    </div>
                </div>
            </div>
            {/* {dataContext.getFieldType(question.fieldTypeId).slug === 'dropdown-custom' &&
                <div className="flex w-full space-x-4">
                    <div className="min-w-[210px] md:w-[75%]">
                        <div className="flex flex-col items-start space-y-1">
                            <span>Answers</span>
                            <div className="flex flex-col space-y-4">
                                <div className="flex space-x-2">
                                    <TextField
                                        className="w-full mr-2"
                                        value={fieldTypeValue}
                                        onChange={(e) => setFieldTypeValue(e.target.value)}
                                    />
                                    <SecondaryButton onClick={() =>{
                                        addFieldValue(question.fieldTypeId, fieldTypeValue);
                                        setFieldTypeValue("");
                                    }} text="Add" />
                                </div>
                                
                                <div className="flex flex-col pl-4 space-y-1">
                                    {fieldValues.map(x => (
                                        <p>{x.value}</p>
                                    ))}
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
            } */}
            <div className="flex w-full space-x-4">
                <div className="min-w-[210px] md:w-[75%]">
                    <div className="flex flex-col items-start space-y-1">
                        <span>Note</span>
                        <TextArea
                            rows={4}
                            className="w-full mr-2"
                            value={question.description}
                            onChange={(e) => onChangeQuestion(getQuestionChange('description', e.target.value))}
                        />
                    </div>
                </div>
            </div>
            <div className="flex w-full space-x-4">
                <CheckIcon className='text-green-600 cursor-pointer hover:text-green-900' onClick={onAddQuestion} width={22} />
                <XMarkIcon className='text-gray-900 cursor-pointer hover:text-gray-600' onClick={onAddQuestionClose} width={22} />
            </div>
        </div>
    );
}

export default QuestionItemForm;