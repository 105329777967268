import { useNavigate, useParams } from "react-router-dom";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import caseApi from "../../services/apis/caseApi";
import { downloadA, isSuccess, showSuccess } from "../../shared/util";
import { useContext, useEffect, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import {
    DocumentArrowDownIcon,
    DocumentTextIcon,
    PencilIcon,
    PlusIcon
} from "@heroicons/react/24/outline";
import documentApi from "../../services/apis/documentApi";
import DocumentList from "./DocumentList";
import StatusDropdown from "../../components/dropdowns/StatusDropdown";
import BaseModal from "../../components/modals/BaseModal";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import axiosClient from "../../shared/axiosClient";
import TextArea from "../../components/forms/TextArea";
import FileDropzone from "../../components/upload/FileDropzone";
import questionnaireApi from "../../services/apis/questionnaireApi";
import { AuthContext } from "../../store/AuthContext";
import { applicationStatuses as applicationStatusList } from "../../data/applicationStatuses";
import { questionnaireStatuses as questionnaireStatusList } from "../../data/questionnaireStatuses";
import FieldSwitchDisplay from "../../components/forms/FieldSwitchDisplay";
import { DataContext } from "../../store/DataContext";
import EditApplicationMemberForm from "./forms/EditApplicationMemberForm"
import ApplicationRelatedMemberItem from "./ApplicationRelatedMemberItem";
import applicationMemberApi from "../../services/apis/applicationMemberApi";

const CaseDetails = () => {
    const { id } = useParams();
    const authContext = useContext(AuthContext);
    const dataContext = useContext(DataContext);

    const canEdit = authContext.session.role !== 'sales' && authContext.session.role !== 'compliance';
    const canEditCompliance = authContext.session.role === 'admin' || authContext.session.role === 'compliance';

    const applicationService = caseApi();
    const documentService = documentApi();
    const questionnaireService = questionnaireApi()
    const applicationMemberService = applicationMemberApi(id)

    const [application, setApplication] = useState(null);
    const [documents, setDocuments] = useState(null);
    const [applicationMembers, setApplicationMembers] = useState([]);
    const [activeApplicationMember, setActiveApplicationMember] = useState(null);
    const [questionnaire, setQuestionnaire] = useState(null);
    const [activeTab, setActiveTab] = useState('documents');

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const [showConfirmStatusModal, setShowConfirmStatusModal] = useState(false);
    const [showConfirmApplicationStatusModal, setShowConfirmApplicationStatusModal] = useState(false);
    const [showConfirmQuestionnaireStatusModal, setShowConfirmQuestionnaireStatusModal] = useState(false);
    const [showComplianceReportModal, setShowComplianceReportModal] = useState(false);
    const [note, setNote] = useState("");
    const [activeDocumentStatuses, setActiveDocumentStatuses] = useState([]);
    const [activeDocumentStatus, setActiveDocumentStatus] = useState("Pending agent's approval");

    const [rejectionLetterFile, setRejecetionLetterFile] = useState(null);
    const [acceptanceLetterFile, setAcceptanceLetterFile] = useState(null);
    const [complianceReportFile, setComplianceReportFile] = useState(null);

    const [changingApplicationStatus, setChangingApplicationStatus] = useState(false);
    const [changingQuestionnaireStatus, setChangingQuestionnaireStatus] = useState(false);
    const [additionalNote, setAdditionalNote] = useState();

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const navigate = useNavigate();

    const getDocuments = async (app) => {
        if (!app) {
            app = application;
        }

        const getDocumentsResponse = await documentService.getAll({
            params: {
                'filter.applicationId': app.id
            }
        });

        if (isSuccess(getDocumentsResponse.status)) {
            setDocuments(getDocumentsResponse.data.data);

            const activeStatuses = [...new Set(getDocumentsResponse.data.data.map(x => x.status))];
            if (!activeStatuses.includes(activeDocumentStatus)) {
                setActiveDocumentStatus("All");
            }

            setActiveDocumentStatuses(activeStatuses);
        }
    }

    const getQuestionnaire = async (app) => {
        if (!app) {
            app = application;
        }

        const questionnaireService = questionnaireApi(app.programId, 'programs/');
        const getQuestionnaire = await questionnaireService.getSingle(app.id);

        if (isSuccess(getQuestionnaire.status)) {
            setQuestionnaire(getQuestionnaire.data);
        }
    }

    const getApplication = async () => {
        const getApplicationResponse = await applicationService.getSingle(id);
        if (isSuccess(getApplicationResponse.status)) {
            setApplication(getApplicationResponse.data);
            setAdditionalNote(getApplicationResponse.data.additionalNote);
            getDocuments(getApplicationResponse.data);
            getQuestionnaire(getApplicationResponse.data);
            setApplicationMembers(getApplicationResponse.data.applicationMembers);
            setActiveApplicationMember(getApplicationResponse.data.applicationMembers[0]);
        }
    }

    const downloadContract = async () => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/clients/" + application.client.id + "/file");
        if (isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const downloadConsent = async () => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/clients/" + application.client.id + "/consent/file");
        if (isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const downloadRejectionLetter = async () => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/applications/" + application.id + "/rejection/file");
        if (isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const downloadAcceptenceLetter = async () => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/applications/" + application.id + "/acceptance/file");
        if (isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const downloadComplianceReport = async () => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/applications/" + application.id + "/compliance-report/file");
        if (isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const downloadFile = async (document) => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/documents/" + document.id + "/file");
        if (isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const downloadQuestionnaire = async () => {
        const exportResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/questionnaires/" + questionnaire.id + '/export',
            { responseType: 'blob' });

        if (isSuccess(exportResponse.status)) {
            // create file link in browser's memory
            const href = URL.createObjectURL(exportResponse.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'questionnaire.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
    }

    const changeDocumentStatus = async () => {
        setShowConfirmStatusModal(false);

        const patchDocumentResponse = await documentService.patch(selectedDocument.id, { status: selectedStatus, note: note });
        if (isSuccess(patchDocumentResponse.status)) {
            getDocuments();
            setSelectedDocument(null);
            setSelectedStatus(null);
            setNote("");
        }
    }

    const saveComplianceReport = async () => {
        setShowComplianceReportModal(false);

        let formData = new FormData();
        formData.append('file', complianceReportFile);

        const fileUploadResponse = await axiosClient.post("applications/" + application.id + "/compliance-report/file", formData, {
            headers: { "Content-type": "multipart/form-data" }
        });

        if (fileUploadResponse.status === 201) {
            application.status=fileUploadResponse.data.status;
            application.complianceReportFile = fileUploadResponse.data.complianceReportFile;
            showSuccess("Compliance report uploaded")
        }
    }

    const changeQuestionnarieStatus = async () => {
        setChangingQuestionnaireStatus(true);

        const patchQuestionnaireResponse = await questionnaireService.patch(questionnaire.id, { status: selectedStatus, additionalNote: additionalNote });
        if (isSuccess(patchQuestionnaireResponse.status)) {
            getQuestionnaire();
            setSelectedStatus(null);
            setAcceptanceLetterFile(null);
            setRejecetionLetterFile(null);
        }
        setChangingQuestionnaireStatus(false);
        setShowConfirmQuestionnaireStatusModal(false)
    }

    const changeApplicationStatus = async () => {
        setChangingApplicationStatus(true);

        const patchApplicationResponse = await applicationService.patch(application.id, { status: selectedStatus, additionalNote: additionalNote });
        if (isSuccess(patchApplicationResponse.status)) {
            if (selectedStatus === 'Rejected' && rejectionLetterFile) {
                let formData = new FormData();
                formData.append('file', rejectionLetterFile);

                const fileUploadResponse = await axiosClient.post("applications/" + application.id + "/rejection/file", formData, {
                    headers: { "Content-type": "multipart/form-data" }
                });
            }

            if (selectedStatus === 'Approved' && acceptanceLetterFile) {
                let formData = new FormData();
                formData.append('file', acceptanceLetterFile);

                const fileUploadResponse = await axiosClient.post("applications/" + application.id + "/acceptance/file", formData, {
                    headers: { "Content-type": "multipart/form-data" }
                });
            }

            getApplication();
            setSelectedStatus(null);
            setAcceptanceLetterFile(null);
            setRejecetionLetterFile(null);
        }

        setChangingApplicationStatus(false);
        setShowConfirmApplicationStatusModal(false);
    }

    useEffect(() => {
        getApplication();
    }, []);

    const applicationStatuses = applicationStatusList;
    const questionnaireStatuses = questionnaireStatusList;

    const getTitle = () => {
        if (!application || !application.client.program) {
            return <span></span>;
        }

        if (application.status === 'Know Your Client') {
            return (
                <div className="flex items-center space-x-2">
                    <p className="hidden md:block">Know Your Client</p>
                    <span className="hidden text-base font-normal text-gray-600 md:block">/</span>
                    <p className="text-base font-normal text-gray-600">{application.client.firstName} {application.client.lastName}</p>
                </div>
            );
        }

        return (
            <div className="flex items-center space-x-2">
                <CircleFlag className="hidden md:block" countryCode={application.client.program.country.slug} width={22} />
                <p className="hidden md:block">{application.client.program.country.name}</p>
                <span className="hidden text-base font-normal text-gray-600 md:block">/</span>
                <p className="hidden text-base font-normal text-gray-600 md:block">{application.client.program.name}</p>
                <span className="hidden text-base font-normal text-gray-600 md:block">/</span>
                <p className="text-base font-normal text-gray-600">{application.client.firstName} {application.client.lastName}</p>
            </div>
        );
    }

    const getDocsNumber = (status) => {
        if (!documents) {
            return null;
        }

        if (status === "All") {
            return documents.length;
        }

        return documents.filter(x => x.status === status).length;
    }

    const getDocs = () => {
        const memberDocuments = documents.filter(x => x.applicationMemberId === activeApplicationMember?.id);
        if (activeDocumentStatus === "All") {
            return memberDocuments;
        }

        return memberDocuments.filter(x => x.status === activeDocumentStatus);
    }

    const showNoteForm = selectedStatus === 'Changes required';
    const showRejectionForm = selectedStatus === 'Rejected';
    const showApprovedForm = selectedStatus === 'Approved';

    const allLength = getDocsNumber("All");
    const activeApplication = application && application.client && application.client.agent && application.status !== 'Waiting for payment';

    const [editApplicationMemberModalOpen, setEditApplicationMemberModalOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);

    const onEditMemberClicked = (member) => {
        setSelectedMember(member);
        setEditApplicationMemberModalOpen(true);
    }

    const getRightSidebarTitle = () => {
        return "Family Members"
    }

    const onRightSidebarClose = () => {
        setEditApplicationMemberModalOpen(false);
    }

    const handleDeleteMemberClicked = (member) => {
        setSelectedMember(member);
        setShowConfirmDeleteModal(true);
    }

    const memberAdded = (member) => {
        const updatedMember = applicationMembers.find(m => m.id === member.id);

        if (updatedMember) {
            Object.assign(updatedMember, { firstName: member.firstName, lastName: member.lastName });
        } else {
            setApplicationMembers([...applicationMembers, member])
        }

        setEditApplicationMemberModalOpen(false);
        getDocuments();
    }

    const getRightSidebarContent = () => {
        if (!editApplicationMemberModalOpen) {
            return null;
        }

        return <EditApplicationMemberForm onMemberAdded={memberAdded} member={selectedMember} edit={!!selectedMember} applicationId={application.id} />
    }

    const deleteMember = async () => {
        const deleteMemberResponse = await applicationMemberService.remove(selectedMember.id);

        setApplicationMembers(applicationMembers.filter(m => m.id !== selectedMember.id));

        if (isSuccess(deleteMemberResponse.status)) {
            showSuccess("Member has been deleted");
            setSelectedMember(null);
            setShowConfirmDeleteModal(false);
            getDocuments();
        }
    }

    return (
        <SidebarLayout title={getTitle()}
            showRightSidebar={editApplicationMemberModalOpen}
            rightSidebarTitle={getRightSidebarTitle()}
            rightSidebarContent={getRightSidebarContent()}
            onRightSidebarClose={onRightSidebarClose}>
            {application &&
                <div className="flex flex-col space-y-8">
                    <div className="flex flex-col w-full px-6 py-6 space-y-6 bg-white rounded-md shadow">
                        <p className="text-2xl font-medium">Application details</p>
                        <div className="flex flex-col space-y-4">
                            <div>
                                <span className="text-gray-600">Program</span>
                                {application.status === 'Know Your Client' ?
                                    <div>
                                        <p className="font-medium">Know Your Client</p>
                                    </div>
                                    :
                                    <div className="flex items-center space-x-2">
                                        <CircleFlag className="hidden md:block" countryCode={application.client.program.country.slug} width={16} />
                                        <p className="font-medium">{application.client.program.country.name} / {application.client.program.name}</p>
                                    </div>
                                }
                            </div>
                            {application.client &&
                                <div>
                                    <span className="text-gray-600">Client</span>
                                    <p className="font-medium">{application.client.firstName} {application.client.lastName}</p>
                                </div>}
                            {application.client && application.client.salesAgent &&
                                <div>
                                    <span className="text-gray-600">Sales Agent</span>
                                    <p className="font-medium">
                                        {application.client.salesAgent.firstName && application.client.salesAgent.lastName
                                            ? `${application.client.salesAgent.firstName} ${application.client.salesAgent.lastName}`
                                            : application.client.salesAgent.username}
                                    </p>
                                </div>}
                            {application.client && application.client.agent &&
                                <div>
                                    <span className="text-gray-600">Processing Agent</span>
                                    <p className="font-medium">{application.client.agent.firstName && application.client.agent.lastName
                                        ? `${application.client.agent.firstName} ${application.client.agent.lastName}`
                                        : application.client.agent.username}
                                    </p>
                                </div>}
                            <div>
                                <span className="text-gray-600">Status</span>
                                <StatusDropdown
                                    value={application.status}
                                    statuses={applicationStatuses}
                                    onChange={(status) => {
                                        setSelectedStatus(status.value);
                                        setShowConfirmApplicationStatusModal(true);
                                    }}
                                    canEdit={canEdit}
                                />
                            </div>
                            {application.client.contractFileId && <div>
                                <span className="text-gray-600">Contract</span>
                                <div onClick={downloadContract} className="flex space-x-1 text-orange-600 cursor-pointer hover:text-orange-800">
                                    <DocumentTextIcon width={16} />
                                    <span className="underline">{application.client.contractFile ? application.client.contractFile.name : "Contract"}</span>
                                </div>
                            </div>}

                            {application.client.consentFileId && <div>
                                <span className="text-gray-600">Consent</span>
                                <div onClick={downloadConsent} className="flex space-x-1 text-orange-600 cursor-pointer hover:text-orange-800">
                                    <DocumentTextIcon width={16} />
                                    <span className="underline">{application.client.consentFile ? application.client.consentFile.name : "Consent"}</span>
                                </div>
                            </div>}

                            {application.rejectionLetter && <div>
                                <span className="text-gray-600">Rejection letter</span>
                                <div onClick={downloadRejectionLetter} className="flex space-x-1 text-red-600 cursor-pointer hover:text-red-800">
                                    <DocumentTextIcon width={16} />
                                    <span className="underline">{application.rejectionLetter.name}</span>
                                </div>
                            </div>}

                            {application.acceptanceLetter && <div>
                                <span className="text-gray-600">Acceptence letter</span>
                                <div onClick={downloadAcceptenceLetter} className="flex space-x-1 text-green-600 cursor-pointer hover:text-green-800">
                                    <DocumentTextIcon width={16} />
                                    <span className="underline">{application.acceptanceLetter.name}</span>
                                </div>
                            </div>}

                            <div><span className="text-gray-600 cursor-pointer">Compliance report</span>
                                <div className="flex space-x-2">
                                    {application.complianceReportFile && <div onClick={downloadComplianceReport} className="flex space-x-1 text-green-600 cursor-pointer hover:text-green-800">
                                        <DocumentTextIcon width={16} />
                                        <span className="underline">{application.complianceReportFile.name}</span>
                                    </div>}
                                    {canEditCompliance && <div onClick={() => setShowComplianceReportModal(true)}
                                        className="flex space-x-2 text-gray-900 cursor-pointer w-fit min-w-min hover:text-gray-600">
                                        <PencilIcon width={22} />
                                        <span className='underline'>Edit</span>
                                    </div>}
                                </div>
                            </div>

                            {questionnaire && questionnaire.status !== 'Submitted' &&
                                <div>
                                    <span className="text-gray-600">Questionnaire Status</span>
                                    <StatusDropdown
                                        value={questionnaire.status}
                                        statuses={questionnaireStatuses}
                                        onChange={(status) => {
                                            setSelectedStatus(status.value);
                                            setShowConfirmQuestionnaireStatusModal(true);
                                        }}
                                        canEdit={canEdit}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="flex flex-col w-full px-6 py-6 space-y-4 bg-white rounded-md shadow">
                        <div>
                            <div className="flex items-center">
                                <div onClick={() => setActiveTab('documents')} className="w-[240px] relative cursor-pointer pb-4">
                                    <p className={`text-lg ${activeTab === 'documents' && "font-semibold"} hover:text-gray-700`}>Documents</p>
                                    {activeTab === 'documents' && <div className="absolute w-4/5 h-0.5 bg-dirt -bottom-0.5"></div>}
                                </div>
                                <div onClick={() => setActiveTab('questionnaire')} className="w-[240px] relative cursor-pointer pb-4">
                                    <p className={`text-lg ${activeTab === 'questionnaire' && "font-semibold"} hover:text-gray-700`}>Questionnaire
                                        {/* {questionnaire ? questionnaire.status === 'Changes required' ? ' (Not available)' : ' (Submitted)' : null} */}
                                    </p>
                                    {activeTab === 'questionnaire' && <div className="absolute w-4/5 h-0.5 bg-dirt -bottom-0.5"></div>}
                                </div>
                            </div>
                            <div className="w-full h-0.5 bg-gray-100"></div>
                        </div>
                        <div>
                            {activeTab === 'documents' &&
                                <div className="w-full">


                                    {documents && documents.length > 0 &&
                                        <div className="flex py-12 space-x-6">
                                            <div onClick={() => setActiveDocumentStatus("All")} className="flex items-center space-x-1 cursor-pointer hover:underline">
                                                <div className="flex items-center justify-center bg-blue-200 rounded-full w-11 h-11">
                                                    <span className="text-blue-700">{allLength}</span>
                                                </div>
                                                <span className={activeDocumentStatus === 'All' ? '' : ''}>All</span>
                                            </div>

                                            {activeDocumentStatuses.includes("Pending agent's approval") && <div onClick={() => setActiveDocumentStatus("Pending agent's approval")} className="flex items-center space-x-1 cursor-pointer hover:underline">
                                                <div className="flex items-center justify-center rounded-full w-11 h-11 bg-slate-200">
                                                    <span className="text-slate-700">{getDocsNumber("Pending agent's approval")}/{allLength}</span>
                                                </div>
                                                <span className={activeDocumentStatus === "Pending agent's approval" ? 'underline font-medium' : ''}>Pending agent's approval</span>
                                            </div>}

                                            {activeDocumentStatuses.includes("Changes required") && <div onClick={() => setActiveDocumentStatus("Changes required")} className="flex items-center space-x-1 cursor-pointer hover:underline">
                                                <div className="flex items-center justify-center bg-red-200 rounded-full w-11 h-11">
                                                    <span className="text-red-700">{getDocsNumber("Changes required")}/{allLength}</span>
                                                </div>
                                                <span className={activeDocumentStatus === 'Changes required' ? 'underline font-medium' : ''}>Changes Required</span>
                                            </div>}

                                            {activeDocumentStatuses.includes("Waiting for physical copy") && <div onClick={() => setActiveDocumentStatus("Waiting for physical copy")} className="flex items-center space-x-1 cursor-pointer hover:underline">
                                                <div className="flex items-center justify-center bg-orange-200 rounded-full w-11 h-11">
                                                    <span className="text-orange-700">{getDocsNumber("Waiting for physical copy")}/{allLength}</span>
                                                </div>
                                                <span className={activeDocumentStatus === 'Waiting for physical copy' ? 'underline font-medium' : ''}>Waiting for physical copy</span>
                                            </div>}

                                            {activeDocumentStatuses.includes("Accepted") && <div onClick={() => setActiveDocumentStatus("Accepted")} className="flex items-center space-x-1 cursor-pointer hover:underline">
                                                <div className="flex items-center justify-center bg-green-200 rounded-full w-11 h-11">
                                                    <span className="text-green-700">{getDocsNumber("Accepted")}/{allLength}</span>
                                                </div>
                                                <span className={activeDocumentStatus === 'Accepted' ? 'underline font-medium' : ''}>Accepted</span>
                                            </div>}
                                        </div>}

                                    {applicationMembers && applicationMembers.length > 0 &&
                                        <div className="flex pb-12 space-x-2 items-center">
                                            {applicationMembers.map((member, index) => (
                                                <ApplicationRelatedMemberItem
                                                    key={member.id}
                                                    member={member}
                                                    selected={activeApplicationMember?.id === member.id}
                                                    onClick={() => setActiveApplicationMember(member)}
                                                    canDelete={member.memberType !== 'applicant'}
                                                    onDeleteClicked={() => handleDeleteMemberClicked(member)}
                                                    canEdit={member.memberType !== 'applicant'}
                                                    onEditClicked={() => onEditMemberClicked(member)}
                                                />
                                            ))}
                                            <div title="Add new member"
                                                className="flex px-4 py-3 justify-center  shadow-md text-2xl h-[120px] w-[120px]"
                                                onClick={() => setEditApplicationMemberModalOpen(true)}>
                                                <PlusIcon width={52} className="text-gray-700" />
                                            </div>
                                        </div>
                                    }

                                    {canEdit && activeApplicationMember &&
                                        <div className="flex py-4 space-x-6 ml-2">
                                            {!activeApplication &&
                                                <div onClick={() => navigate({
                                                    pathname: '/applications/' + id + '/documents',
                                                    search: 'memberType=' + activeApplicationMember?.memberType + '&memberId=' + activeApplicationMember?.id
                                                })}
                                                    className="flex space-x-2 text-gray-900 cursor-pointer w-fit min-w-min hover:text-gray-600">
                                                    <PencilIcon width={22} />
                                                    <span className='underline'>Edit</span>
                                                </div>}
                                            {activeApplication &&
                                                <div onClick={() => navigate({
                                                    pathname: '/applications/' + id + '/request-documents',
                                                    search: 'memberType=' + activeApplicationMember?.memberType + '&memberId=' + activeApplicationMember?.id
                                                })}
                                                    className="flex space-x-2 text-gray-900 cursor-pointer w-fit min-w-min hover:text-gray-600">
                                                    <PencilIcon width={22} />
                                                    <span className='underline'>Edit</span>
                                                </div>}
                                        </div>
                                    }

                                    {documents && <DocumentList documents={getDocs()}
                                        getDocuments={getDocuments}
                                        onDocumentDownload={downloadFile}
                                        canEdit={canEdit}
                                        onStatusChangeClick={(item, statusValue) => {
                                            setSelectedDocument(item);
                                            setSelectedStatus(statusValue);
                                            setNote(item.note);
                                            setShowConfirmStatusModal(true);
                                        }} />}
                                </div>}
                            {activeTab === 'questionnaire' &&
                                <div>
                                    {questionnaire && canEdit &&
                                        <div className="flex py-4 space-x-6">
                                            <div onClick={() => navigate('/applications/' + id + '/questionnaires/' + questionnaire.id)}
                                                className="flex space-x-2 text-gray-900 cursor-pointer w-fit min-w-min hover:text-gray-600">
                                                <PencilIcon width={22} />
                                                <span className='underline'>Edit</span>
                                            </div>
                                            <div onClick={() => navigate('/applications/' + id + '/questionnaires/' + questionnaire.id + '/details')}
                                                className="flex space-x-2 text-gray-900 cursor-pointer w-fit min-w-min hover:text-gray-600">
                                                <DocumentTextIcon width={22} />
                                                <span className='underline'>Fill out</span>
                                            </div>
                                            <div onClick={downloadQuestionnaire}
                                                className="flex space-x-2 text-gray-900 cursor-pointer w-fit min-w-min hover:text-gray-600">
                                                <DocumentArrowDownIcon width={22} />
                                                <span className='underline'>Download</span>
                                            </div>
                                        </div>}
                                    {questionnaire && questionnaire.sections &&
                                        <div className="flex flex-col py-8 space-y-6">
                                            {questionnaire.sections.map((section, index) => (
                                                <div className="flex flex-col space-y-4">
                                                    <p className="text-lg font-medium underline">{index + 1}. {section.name}</p>
                                                    <div className="flex flex-col pl-8 space-y-2">
                                                        {section.items.map((item, index) => (
                                                            <div className="flex flex-col">
                                                                <p className="text-gray-700 ">{item.question}</p>
                                                                <FieldSwitchDisplay
                                                                    type={dataContext.getFieldType(item.fieldTypeId).slug}
                                                                    value={item.value}
                                                                    size="sm"
                                                                    className="w-full"
                                                                    item={item}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>}
                                </div>}
                        </div>
                    </div>
                    <BaseModal show={showComplianceReportModal} onClose={() => setShowComplianceReportModal(false)}>
                        <div className="flex flex-col space-y-12 w-[540px]">
                            <div className="flex flex-col space-y-6">
                                <p className="text-xl font-medium">Upload compliance file</p>
                                <div className="flex flex-col space-y-2 text-gray-700">
                                    <FileDropzone
                                        file={complianceReportFile}
                                        onUpload={(file) => setComplianceReportFile(file)}
                                    />
                                </div>
                            </div>
                            <div className="flex space-x-4">
                                <PrimaryButton className="w-1/2" onClick={() => saveComplianceReport()} text="Confirm" />
                                <SecondaryButton className="w-1/2" onClick={() => setShowComplianceReportModal(false)} text="Cancel" />
                            </div>
                        </div>
                    </BaseModal>
                    <BaseModal show={showConfirmStatusModal} onClose={() => setShowConfirmStatusModal(false)}>
                        <div className="flex flex-col space-y-12 w-[540px]">
                            <div className="flex flex-col space-y-6">
                                <p className="text-xl font-medium">Confirm status change</p>
                                <div className="flex flex-col space-y-4">
                                    <p className="">Are you sure you want to change the status of the document?</p>
                                    {showNoteForm &&
                                        <div className="flex flex-col space-y-2 text-gray-700">
                                            <p className="text-sm text-gray-700">Please provide the reason for its rejection and any necessary instructions for correction</p>
                                            <TextArea
                                                value={note}
                                                onChange={(e) => setNote(e.target.value)}
                                                rows={6}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex space-x-4">
                                <PrimaryButton className="w-1/2" onClick={() => changeDocumentStatus()} text="Confirm" />
                                <SecondaryButton className="w-1/2" onClick={() => setShowConfirmStatusModal(false)} text="Cancel" />
                            </div>
                        </div>
                    </BaseModal>
                    <BaseModal show={showConfirmQuestionnaireStatusModal} onClose={() => setShowConfirmStatusModal(false)}>
                        <div className="flex flex-col space-y-12 w-[540px]">
                            <div className="flex flex-col space-y-6">
                                <p className="text-xl font-medium">Confirm status change</p>
                                <div className="flex flex-col space-y-4">
                                    <p className="">Are you sure you want to change the status of the document?</p>
                                </div>
                            </div>
                            <div className="flex space-x-4">
                                <PrimaryButton spinning={changingQuestionnaireStatus} className="w-1/2" onClick={() => changeQuestionnarieStatus()} text="Confirm" />
                                <SecondaryButton className="w-1/2" onClick={() => setShowConfirmQuestionnaireStatusModal(false)} text="Cancel" />
                            </div>
                        </div>
                    </BaseModal>
                    <BaseModal show={showConfirmApplicationStatusModal} onClose={() => setShowConfirmApplicationStatusModal(false)}>
                        <div className="flex flex-col space-y-8">
                            <div className="flex flex-col space-y-6">
                                <div className="flex flex-col space-y-1">
                                    <p className="text-xl font-medium">Confirm status change</p>
                                    <p className="">Are you sure you want to change the status of the application?</p>
                                </div>

                                <div className="flex flex-col space-y-4">
                                    {showRejectionForm &&
                                        <div className="flex flex-col space-y-4 text-gray-700">
                                            <div className="flex flex-col space-y-2 text-gray-700">
                                                <p className="text-sm text-gray-700">Additional message</p>
                                                <TextArea
                                                    value={application.additionalNote}
                                                    onChange={(e) => setAdditionalNote(e.target.value)}
                                                    rows={6}
                                                />
                                            </div>
                                            <div className="flex flex-col space-y-2 text-gray-700">
                                                <p className="text-sm text-gray-700">Submit the rejection letter for our client's review.</p>
                                                <FileDropzone
                                                    file={rejectionLetterFile}
                                                    onUpload={(file) => setRejecetionLetterFile(file)}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {showApprovedForm &&
                                        <div className="flex flex-col space-y-4 text-gray-700">
                                            <div className="flex flex-col space-y-2 text-gray-700">
                                                <p className="text-sm text-gray-700">Additional message</p>
                                                <TextArea
                                                    value={application.additionalNote}
                                                    onChange={(e) => setAdditionalNote(e.target.value)}
                                                    rows={6}
                                                />
                                            </div>
                                            <div className="flex flex-col space-y-2 text-gray-700">
                                                <p className="text-sm text-gray-700">Submit the acceptance letter for our client's review.</p>
                                                <FileDropzone
                                                    file={acceptanceLetterFile}
                                                    onUpload={(file) => setAcceptanceLetterFile(file)}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex space-x-4">
                                <PrimaryButton spinning={changingApplicationStatus} className="w-1/2" onClick={() => changeApplicationStatus()} text="Confirm" />
                                <SecondaryButton className="w-1/2" onClick={() => setShowConfirmApplicationStatusModal(false)} text="Cancel" />
                            </div>
                        </div>
                    </BaseModal>
                    <BaseModal show={showConfirmDeleteModal} onClose={() => setShowConfirmDeleteModal(false)}>
                        <div className="flex flex-col space-y-12 w-[540px]">
                            <div className="flex flex-col space-y-6">
                                <p className="text-xl font-medium">Confirm family member deletion</p>
                                <div className="flex flex-col space-y-4">
                                    <p className="">Are you sure you want to delete this member?</p>
                                </div>
                            </div>
                            <div className="flex space-x-4">
                                <PrimaryButton className="w-1/2" onClick={() => deleteMember()} text="Confirm" />
                                <SecondaryButton className="w-1/2" onClick={() => setShowConfirmDeleteModal(false)} text="Cancel" />
                            </div>
                        </div>
                    </BaseModal>
                </div>
            }
        </SidebarLayout>
    );
}

export default CaseDetails;