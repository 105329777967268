import { useContext, useState } from "react";
import PublicLayout from "../../components/layouts/PublicLayout";
import PasswordField from "../../components/forms/PasswordField";
import EmailField from "../../components/forms/EmailField";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { changeField, getFirstError, isValid } from "../../shared/validate";
import { Link, useNavigate } from "react-router-dom";
import sessionApi from "../../services/apis/sessionApi";
import { isSuccess } from "../../shared/util";
import { AuthContext } from "../../store/AuthContext";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { DataContext } from "../../store/DataContext";
import resetPasswordEmailApi from "../../services/apis/resetPasswordEmail";
import colors from "../../shared/colors";

const ForgotPassword = () => {
    const [email, setEmail] = useState({
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'Email address is required.',
                isValid: true,
            },
            {
                type: 'email',
                message: 'Please provide a valid email address.',
                isValid: true,
            }
        ],
    });

    const [sendingInProgress, setSendingInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [error, setError] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    
    const navigate = useNavigate();

    const handleSubmitButtonClicked = async () => {
        setSendingInProgress(true);
        const reserPasswordEmailService = resetPasswordEmailApi();
        const sendResetPasswordResponse = await reserPasswordEmailService.post({ email: email.value });

        if(isSuccess(sendResetPasswordResponse.status)) {
            setEmailSent(true);
            setSendingInProgress(false);
        }

        setSendingInProgress(false);
    }

    const onFormSubmit = (e) => {
        e.preventDefault(); 
    }

    const isFormValid = () => {
        return isValid([email]);
    }

    return (
        <PublicLayout>
            <form className="text-center md:text-start" onSubmit={onFormSubmit}>
                <div className="flex flex-col mb-8 space-y-3">
                    <h2 className="text-4xl font-medium font-semibold font-primary">Forgot password</h2>
                    {!emailSent &&
                        <p className="text-thin font-primary text-silver600">Please provide your email.</p>
                    }
                </div>

                {emailSent &&
                    <div>
                        <p>An email has been sent. Please check your inbox and click on the provided link to reset your password.</p>
                        <div className="flex items-center w-full mt-6 space-x-2">
                            <ArrowLeftIcon width={22} color={colors.coyoteb} />
                            <Link to="/login" className="underline text-coyoteb">Back to Login</Link>
                        </div>
                    </div>
                }

                {!emailSent &&
                <>
                    <div className="flex flex-col mb-6 mb-10 space-y-6">
                        <EmailField
                            name='email'
                            placeholder='Email'
                            value={email.value}
                            isValid={email.isValid || !email.showValidation}
                            onChange={(e) => setEmail(changeField(e, { ...email }))}
                            onBlur={(e) => setEmail({ ...email, showValidation: true })}
                            errorMessage={email.showValidation ? getFirstError(email) : ''}
                        />
                    </div>

                    <div className="w-full">
                        <PrimaryButton
                            text="Send email"
                            icon={<ArrowRightIcon color="white" width={24} />}
                            spinning={sendingInProgress}
                            onClick={handleSubmitButtonClicked}
                            disabled={!isFormValid()}
                            type="submit"
                        />
                    </div>

                    <div className="flex flex-col pl-2 mt-4 space-y-1">
                        {error && 
                            <span className="text-red-600">This email is not registred</span>
                        }
                    </div>
                </>}
            </form>
        </PublicLayout>
    );
}

export default ForgotPassword;