import { DocumentTextIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";
import { useDropzone } from 'react-dropzone';

const FileDropzone = ({ file, onUpload }) => {
    const onDrop = useCallback(acceptedFiles => {
        onUpload(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {
                <div className="flex flex-col items-center px-4 py-6 space-y-4 border border-dashed rounded-md cursor-pointer">
                    <div className={`relative flex flex-col items-center p-4 space-y-1 cursor-default`}>
                        <DocumentTextIcon width={44} className={file ? "text-slate-600" : "text-gray-600"} />
                        {file && <span className="text-slate-600 text-md">{file.name}</span>}
                        {file && 
                            <div onClick={(event) => { event.stopPropagation(); onUpload(null); }} className="flex space-x-2 text-orange-700 underline cursor-pointer">
                                <span>Remove</span>
                                {/* <XMarkIcon onClick={(event) => { event.stopPropagation(); onUpload(null); }} width={22} className="rounded cursor-pointer bg-slate-100" /> */}
                            </div>
                        }
                    </div>
                    {isDragActive 
                        ? <p>Drop the files here ...</p>
                        : <p>Drag 'n' drop or click to select file</p>
                    }
                </div>
            }
        </div>
    );
}

export default FileDropzone;