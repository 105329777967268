import { useEffect, useState } from "react";
import EmailField from "../../../components/forms/EmailField";
import { changeField, getFirstError, isValid } from "../../../shared/validate";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { isSuccess } from "../../../shared/util";
import TextField from "../../../components/forms/TextField";
import clientApi from "../../../services/apis/clientApi";
import axiosClient from "../../../shared/axiosClient";
import questionnaireApi from "../../../services/apis/questionnaireApi";
import SSelect from "../../../components/forms/SSelect";
import userApi from "../../../services/apis/userApi";
import InlineFileDropzone from "../../../components/upload/InlineFileDropzone";
import Toggle from "../../../components/forms/Toggle";

const AddClientForm = ({ onClientAdded, edit, client }) => {
    const firstNameDefault = {
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        editable: true,
        validators: [
            {
                type: 'required',
                message: 'First name is required.',
                isValid: true,
            },
        ],
    };
    const [firstName, setFirstName] = useState(firstNameDefault);

    const lastNameDefault = {
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        editable: true,
        validators: [
            {
                type: 'required',
                message: 'Last name is required.',
                isValid: true,
            },
        ],
    };
    const [lastName, setLastName] = useState(lastNameDefault);

    const emailDefault = {
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        editable: false,
        validators: [
            {
                type: 'required',
                message: 'Email address is required.',
                isValid: true,
            },
            {
                type: 'email',
                message: 'Please provide a valid email address.',
                isValid: true,
            }
        ],
    };
    const [email, setEmail] = useState(emailDefault);
    const [sendFormToClient, setSendFormToClient] = useState(true);

    const phoneNumberDefault = {
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'Email address is required.',
                isValid: true,
            },
            {
                type: 'email',
                message: 'Please provide a valid email address.',
                isValid: true,
            }
        ],
    };

    const [phoneNumber, setPhoneNumber] = useState(phoneNumberDefault);

    const [contractFile, setContractFile] = useState(null);
    const [consentFile, setConsentFile] = useState(null);

    const [saveClientInProgress, setSaveClientInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const [forms, setForms] = useState([]);
    const [form, setForm] = useState(null);

    const [salesAgents, setSalesAgents] = useState([]);
    const [salesAgent, setSalesAgent] = useState(null);
    const [agent, setAgent] = useState(null);
    const [agents, setAgents] = useState([]);

    const clientService = clientApi();
    const userService = userApi();
    const questionnaireService = questionnaireApi();

    useEffect(() => {
        if (edit && client) {
            setFirstName({ ...firstName, value: client.firstName, isValid: true });
            setLastName({ ...lastName, value: client.lastName, isValid: true });
            setEmail({ ...email, value: client.email, isValid: true });
        }

        getForms();
        getSalesAgents();
        getProcessingAgents();
    }, []);

    const getForms = async () => {
        const getQuestionnairesResponse = await questionnaireService.getAll({
            params: {
                "limit": 0,
                "filter.isTemplate": true,
            }
        });

        if (isSuccess(getQuestionnairesResponse.status)) {
            setForms(getQuestionnairesResponse.data.data.map(x => {
                return {
                    value: x.id,
                    display: x.name,
                }
            }));

            if (getQuestionnairesResponse.data.data && getQuestionnairesResponse.data.data.length > 0) {
                if (edit) {
                    setForm(getQuestionnairesResponse.data.data[0].id)
                } else {
                    const defaultQuestionnaire = getQuestionnairesResponse.data.data.find(questionnaire => questionnaire.isDefault === true);
                    setForm(defaultQuestionnaire.id);
                }
            }
        }
    }

    const getSalesAgents = async () => {
        const getSalesAgentsResponse = await userService.getAll({
            params: {
                "limit": 0,
                "filter.role": '$in:admin,sales',
            }
        });

        if (isSuccess(getSalesAgentsResponse.status)) {
            setSalesAgents(getSalesAgentsResponse.data.data.map(x => {
                return {
                    value: x.id,
                    display: x.firstName && x.lastName ? x.firstName + " " + x.lastName : x.username,
                }
            }));

            if (getSalesAgentsResponse.data.data && getSalesAgentsResponse.data.data.length > 0) {
                setSalesAgent(client?.salesAgent?.id);
            }
        }
    }

    const getProcessingAgents = async () => {
        const getProcessingAgentsResponse = await userService.getAll({
            params: {
                "filter.role": "$in:agent,admin",
                "limit": 0
            }
        })
        if (isSuccess(getProcessingAgentsResponse.status)) {
            setAgents(getProcessingAgentsResponse.data.data.map(x => {
                return {
                    value: x.id,
                    display: x.firstName && x.lastName ? x.firstName + " " + x.lastName : x.username,
                };
            }));

            if (getProcessingAgentsResponse.data.data && getProcessingAgentsResponse.data.data.length > 0) {
                setAgent(client?.agent?.id);
            }
        }
    }

    const resetForm = () => {
        setEmail(emailDefault);
        setFirstName(firstNameDefault);
        setLastName(lastNameDefault);
    }

    const handleSaveClientButtonClicked = async () => {
        setSaveClientInProgress(true);
        console.log('pozdrav')
        console.log(agents.find(agent => agent.id === client?.agent?.id))
        console.log('pozdrav')
        const createUserResponse = edit && client
            ?
            await clientService.put(client.id, {
                ...client,
                firstName: firstName.value,
                lastName: lastName.value,
                salesAgentId: salesAgent,
                salesAgent: salesAgents.find(agent => agent.id === client?.salesAgent?.id),
                agentId: agent,
                agent: agents.find(agent => agent.id === agent)
            })
            :
            await clientService.post({
                email: email.value,
                role: 'client',
                firstName: firstName.value,
                lastName: lastName.value,
                phone: '83297238723',
                formId: form,
                salesAgentId: salesAgent,
                agentId: agent,
                sendFormToClient: sendFormToClient,
            });
        if (isSuccess(createUserResponse.status)) {
            if (contractFile) {
                let formData = new FormData();
                formData.append('file', contractFile);

                const fileUploadResponse = await axiosClient.post("clients/" + createUserResponse.data.id + "/file", formData, {
                    headers: { "Content-type": "multipart/form-data" }
                });
            }

            if (consentFile) {
                let formData = new FormData();
                formData.append('file', contractFile);

                const fileUploadResponse = await axiosClient.post("clients/" + createUserResponse.data.id + "/consent/file", formData, {
                    headers: { "Content-type": "multipart/form-data" }
                });
            }

            onClientAdded();
            setSaveClientInProgress(false);
            resetForm();
        } else {
            setErrorMessages([{ message: 'Something went wrong. Please contact system administrator.' }]);
            setSaveClientInProgress(false);
        }
    }

    const isFormValid = () => {
        return isValid([firstName, lastName, email]) && form && salesAgent;
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <form className="flex flex-col space-y-10" onSubmit={onFormSubmit}>
            <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">First name</label>
                    <TextField
                        name='first_name'
                        placeholder='First name'
                        value={firstName.value}
                        disabled={edit && !lastName.editable}
                        isValid={firstName.isValid || !firstName.showValidation}
                        onChange={(e) => setFirstName(changeField(e, { ...firstName }))}
                        onBlur={(e) => setFirstName({ ...firstName, showValidation: true })}
                        errorMessage={firstName.showValidation ? getFirstError(firstName) : ''}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Last name</label>
                    <TextField
                        name='last_name'
                        placeholder='Last name'
                        disabled={edit && !firstName.editable}
                        value={lastName.value}
                        isValid={lastName.isValid || !lastName.showValidation}
                        onChange={(e) => setLastName(changeField(e, { ...lastName }))}
                        onBlur={(e) => setLastName({ ...lastName, showValidation: true })}
                        errorMessage={lastName.showValidation ? getFirstError(lastName) : ''}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Email</label>
                    <EmailField
                        name='email'
                        placeholder='Email address'
                        disabled={edit && !email.editable}
                        value={email.value}
                        isValid={email.isValid || !email.showValidation}
                        onChange={(e) => setEmail(changeField(e, { ...email }))}
                        onBlur={(e) => setEmail({ ...email, showValidation: true })}
                        errorMessage={email.showValidation ? getFirstError(email) : ''}
                    />
                </div>
                {!edit &&
                    <div className="flex flex-col space-y-1">
                        <label className="text-sm text-gray-700">Form</label>
                        <SSelect
                            name='client_form'
                            placeholder='Form'
                            onChange={(option) => setForm(option.value)}
                            value={form}
                            options={forms}
                        />
                    </div>
                }
                {!edit &&
                    <div className="flex flex-col space-y-1">
                        <label className="text-sm text-gray-700">Send form link to client's email</label>
                        <Toggle
                            name='send_form_to_client'
                            placeholder='Send Email'
                            onChange={(option) => setSendFormToClient(!sendFormToClient)}
                            value={sendFormToClient}
                        />
                    </div>
                }
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Sales agent</label>
                    <SSelect
                        name='client_sales'
                        placeholder='Sales Agent'
                        onChange={(option) => setSalesAgent(option.value)}
                        value={salesAgent}
                        options={salesAgents}
                    />
                </div>
                {agent && <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Processing officer</label>
                    <SSelect
                        name='agent'
                        placeholder='Processing officer'
                        onChange={(option) => setAgent(option.value)}
                        value={agent}
                        options={agents}
                    />
                </div>}
                {!edit &&
                    <div className="flex flex-col space-y-4 text-center">
                        <div className="flex flex-col space-y-1">
                            <label className="text-sm text-gray-700 text-start">Contract</label>
                            <InlineFileDropzone
                                file={contractFile}
                                onUpload={(file) => setContractFile(file)}
                            />
                        </div>
                        <div className="flex flex-col space-y-1 text-start">
                            <label className="text-sm text-gray-700">Consent</label>
                            <InlineFileDropzone
                                file={consentFile}
                                onUpload={(file) => setConsentFile(file)}
                            />
                        </div>
                    </div>
                }
            </div>
            <div className="">
                <PrimaryButton
                    text="Save Client"
                    spinning={saveClientInProgress}
                    onClick={handleSaveClientButtonClicked}
                    disabled={!isFormValid()}
                    type="submit"
                />
            </div>
            {/* <div className="flex flex-col pl-2 mt-4 space-y-1">
                {errorMessages && errorMessages.map((errorMessage, index) => (
                    <span key={'add-user-err-message-' + index} className="text-red-600">{errorMessage.message}</span>
                ))}
            </div> */}
        </form>
    );
}

export default AddClientForm;