import QuestionItemForm from "./QuestionItemForm";
import QuestionItem from "./QuestionItem";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";

const QuestionListSortable = ({ items, onSetItems, question, onChangeQuestion, onEdit, onDeleteClicked, isValid,
    showAddQuestionForm, onEditClicked, onAddQuestionClose, selectedIndex, loadingFieldValues, fieldValues, addFieldValue }) => {

    const reorderSectionList = (e) => {
        if(!e.over) {
            return;
        }
    
        if(e.active.id !== e.over.id) {
            const oldIndex = items.findIndex(x => x.id === e.active.id);
            const newIndex = items.findIndex(x => x.id === e.over.id);
    
            const tmpItems = arrayMove(items, oldIndex, newIndex); 
            onSetItems(tmpItems);
        }
    }

    return (
        <DndContext onDragEnd={reorderSectionList}>
            <SortableContext items={items}>
                {items.map((questionItem, index) => (
                    showAddQuestionForm && selectedIndex === index
                        ?
                        <QuestionItemForm
                            question={question}
                            onChangeQuestion={onChangeQuestion}
                            onAddQuestion={() => onEdit(index)}
                            onAddQuestionClose={onAddQuestionClose}
                            isValid={isValid}
                            loadingFieldValues={loadingFieldValues}
                            fieldValues={fieldValues}
                            addFieldValue={addFieldValue}
                        />
                        :
                        <QuestionItem key={`question-item-${index}`} i={index} 
                            question={questionItem}
                            showAddQuestionForm={showAddQuestionForm}
                            onEditClicked={onEditClicked}
                            onDeleteClicked={() => onDeleteClicked(index)}
                            onAddQuestionClose={onAddQuestionClose}
                            index={index}
                        />
                ))}
            </SortableContext>
        </DndContext>
    );
};

export default QuestionListSortable;