import { useState } from "react";
import { useOutsideClick } from "../../hooks/CustomHooks";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import colors from "../../shared/colors";

const DropdownField = ({ value, options, onChange, placeholder, className }) => {
    const [open, setOpen] = useState(false);
    const ref = useOutsideClick(() => setOpen(false));
    const [width, setWidth] = useState(null);

    const handleOpen = () => {
        setWidth(ref.current.offsetWidth);
        setOpen(!open);
    };

    const onOptionClick = (option) => {
        onChange(option);
        setOpen(false);
    }

    const getClassName = () => {
        return className ? className + " relative" : "relative";
    }

    let icon = null;
    let display = null;
    if (value && options) {
        const selectedOption = options.find(x => x.value === value);
        if (selectedOption) {
            display = selectedOption.display;
            icon = selectedOption.icon;
        }
    }

    return (
        <div ref={ref} className={getClassName()}>
            <div title={display} onClick={handleOpen} className="relative flex space-x-2 rounded-md cursor-pointer border-silver100 border-[1.4px]
            items-center pr-10 drop-shadow-sm focus:drop-shadow-none border-silver200 focus:border-indigo-200 bg-white">
                <div className="flex items-center pl-3 space-x-2 overflow-x-hidden">
                    {icon && icon}
                    <p className={`py-3 truncate ${display ? "text-gray-900" : "text-gray-400"}`}>{display ? display : placeholder}</p>
                </div>
                {open && <ChevronUpIcon width={18} color={colors.gray[900]} className="absolute right-3" />}
                {!open && <ChevronDownIcon width={18} color={colors.gray[900]} className="absolute right-3" />}
            </div>
            {open ? (
                <div>
                    <div style={{ width: width + "px" }} className="bg-white border-silver100 border-[1px] z-[14] shadow-sm max-h-[282px] overflow-x-hidden overflow-y-auto absolute">
                        <ul>
                            {options && options.map(option => (
                                <li key={"dropdown-field-" + option.value} onClick={() => onOptionClick(option)} className="flex items-center px-3 py-2 space-x-2 cursor-pointer hover:bg-gray-100">
                                    {option.icon && option.icon}
                                    <button>{option.display}</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default DropdownField;

DropdownField.defaultProps = {
    placeholder: "Select value"
}