import { ApiCore } from "../utilities/core";

const fieldTypeValueApi = (id) => {
    return new ApiCore({
        getAll: true,
        getSingle: true,
        post: true,
        put: true,
        patch: true,
        remove: true,
        url: 'field-types/' + id + '/field-type-values'
    });
}

export default fieldTypeValueApi;