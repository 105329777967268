import LogoBlack from '../images/logo-black.svg';
import ReactCountryFlag from "react-country-flag";
import PrimaryButton from "../buttons/PrimaryButton";
import { useContext } from "react";
import { AuthContext } from "../../store/AuthContext";
import UserAccountDropdown from "../dropdowns/UserAccountDropdown";
import { UIContext } from "../../store/UIContext";
import NotificationDropdown from "../dropdowns/NotificationDropdown";
import colors from '../../shared/colors';
import {  XMarkIcon } from '@heroicons/react/24/outline';
import ConversationPane from '../../pages/ClientBoard/ConversationPane';
import { Link } from 'react-router-dom';

const ClientLayout = ({ children, footer, paddingB }) => {
    const authContext = useContext(AuthContext);
    const uiContext = useContext(UIContext);

    return (
        <div className="relative w-screen h-screen overflow-x-hidden overflow-y-hidden bg-cultured">
            <div className={`absolute top-0 left-0 w-full h-full bg-gray-900 z-[17] transition-opacity ease-in-out backdrop-blur-xl duration-300 opacity-50 ${uiContext.showBackdrop ? "visible" : "invisible"}`}></div>
            <div className={`absolute top-0 left-0 w-full h-full bg-gray-900 z-[17] transition-opacity ease-in-out backdrop-blur-xl duration-300 opacity-25 ${uiContext.showLightBackdrop ? "visible" : "invisible"}`}></div>
            <div className='flex h-full'>
                <div className="relative h-full pb-72 md:pb-20 grow">
                    <div className="relative flex items-center py-6 w-full px-4 md:px-[136px] space-x-4 bg-white z-[8] drop-shadow">
                        <Link to="/">
                            <img width={92} src={LogoBlack} alt="Soland" />
                        </Link>
                        <div className="flex items-center justify-end space-x-8 grow">
                            <NotificationDropdown />
                            <UserAccountDropdown />
                        </div>
                    </div>
                    <div className="relative flex items-center py-4 w-full px-4 md:px-[136px] space-x-4 bg-white drop-shadow z-[8]">
                        <div className="flex flex-col items-center w-full space-y-4 md:space-y-0 md:flex-row">
                            <div className="flex flex-col w-full md:space-x-6 md:items-center md:flex-row md:divide-x">
                                <div className="flex items-center space-x-4">
                                    <ReactCountryFlag countryCode={authContext.application.program.country.slug} className="drop-shadow" style={{ fontSize: '2.4em', lineHeight: '2em', }} svg />
                                    <p className="text-lg font-semibold">{authContext.application.program.country.name}</p>
                                </div>
                                <div className="flex md:px-6 grow">
                                    <p className="text-right text-gray-600">{authContext.application.program.name}</p>
                                </div>
                            </div>
                            <div className="flex flex-col items-start w-full space-y-2 md:items-center md:space-x-6 md:space-y-0 md:justify-end md:flex-row grow">
                                <p className="text-gray-700">
                                    Agent: <span className="font-semibold text-black">{authContext.application.client.agent.firstName && authContext.application.client.agent.lastName 
                                    ? `${authContext.application.client.agent.firstName} ${authContext.application.client.agent.lastName}` 
                                    : authContext.application.client.agent.username}</span>
                                </p>
                                <PrimaryButton onClick={() => uiContext.setShowSecondPane(true)} text="Contact Agent" />
                            </div>
                            {uiContext.showAccountDropdown &&
                                <div className="bg-white absolute z-[39] right-1 md:right-[136px] md:top-1 -top-3 shadow">
                                    {uiContext.accountDropdownContent}
                                </div>
                            }
                            {uiContext.showNotificationDropdown &&
                                <div className="bg-white absolute z-[39] right-1 md:right-[136px] md:top-1 -top-3 shadow">
                                    {uiContext.notificationDropdownContent}
                                </div>
                            }
                        </div>
                    </div>
                    <div className={`px-4 md:px-[136px] py-4 md:p-8 h-full flex flex-col items-center overflow-y-auto grow`}>
                        <div className={`max-w-[920px] w-full pb-[200px]`}>
                            {children}
                        </div>
                    </div>
                    {footer && footer}
                </div>
                {uiContext.showSecondPane && <div className={`bg-white shadow w-full md:w-[520px] h-full shadow-sm ease-in-out duration-300 ${uiContext.showSecondPane ? "translate-x-0" : "translate-x-full"}`}>
                  <ConversationPane />
                </div>}
            </div>
            <div className={`bg-white w-[520px] flex flex-col space-y-4 max-w-full h-full max-h-full absolute top-0 right-0 z-[21] shadow-sm ease-in-out duration-300 ${uiContext.showRightSidebar ? "translate-x-0" : "translate-x-full"}`}>
                <div className="flex items-center px-6 py-6 space-x-4">
                    <XMarkIcon width={38} color={colors.gray[700]} className="cursor-pointer hover:text-gray-500" onClick={() => uiContext.setShowRightSidebar(false)} />
                    <h3 className="text-xl font-semibold text-gray-600">{uiContext.rightSidebarTitle}</h3>
                </div>
                <div className="px-6 py-4">
                    {uiContext.rightSidebarContent}
                </div>
            </div>
        </div>
    );
}

export default ClientLayout;