import { useContext } from 'react';
import { DataContext } from '../../store/DataContext';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DocumentsListSortable from './DocumentsListSortable';
import DocumentItemForm from './DocumentItemForm';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

const DocumentsTable = ({ items, onSetItems, showAddDocumentForm, onAddDocumentClick, selectedIndex,
    onAddDocumentClose, onAddDocument, document, onChangeDocument, onEditClicked, onEdit, isValid, onDeleteClicked, memberType, memberId }) => {
    const dataContext = useContext(DataContext);

    return (
        <div className="overflow-hidden bg-white rounded-md">
            <PerfectScrollbar>
                <div className="flex w-full px-8 py-4 bg-gray-50 rounded-t-md border-b-[1.5px] border-b-slate-200 min-w-min">
                    <div className="flex space-x-1 min-w-[30%] md:w-[30%] justify-start items-center">
                        <span className="text-sm text-gray-500">Document</span>
                    </div>
                    <div className="flex space-x-1 min-w-[30%] md:w-[30%] justify-start items-center mx-2">
                        <span className="text-sm text-gray-500">Note</span>
                    </div>
                    {memberType == null && <>
                        <div className="flex min-w-[7%] justify-center items-center mx-2">
                            <span className="text-sm text-gray-500">Principal Applicant</span>
                        </div>
                        <div className="flex min-w-[7%] justify-center items-center mx-2">
                            <span className="text-sm text-gray-500">Spouse</span>
                        </div>
                        <div className="flex min-w-[7%] justify-center items-center mx-2">
                            <span className="text-sm text-gray-500">Adult dependent</span>
                        </div>
                        <div className="flex min-w-[7%] justify-center items-center mx-2">
                            <span className="text-sm text-gray-500">Minor dependent</span>
                        </div>
                    </>}
                </div>
                {items && items.length === 0 &&
                    <div className="flex justify-center w-full py-8">
                        <span className="w-full text-left text-gray-400 px-14 text-md">There are currently no items in the list.</span>
                    </div>
                }
                <div>
                    <DocumentsListSortable
                        items={items}
                        useDragHandle
                        document={document}
                        onChangeDocument={onChangeDocument}
                        onEdit={onEdit}
                        isValid={isValid}
                        showAddDocumentForm={showAddDocumentForm}
                        dataContext={dataContext}
                        onEditClicked={onEditClicked}
                        onAddDocumentClose={onAddDocumentClose}
                        onDeleteClicked={onDeleteClicked}
                        selectedIndex={selectedIndex}
                        onSetItems={onSetItems}
                        memberType={memberType}
                        memberId={memberId}
                    />
                    {showAddDocumentForm && document && !document.id
                        ?
                        <DocumentItemForm
                            document={document}
                            onChangeDocument={onChangeDocument}
                            onAddDocument={onAddDocument}
                            onAddDocumentClose={onAddDocumentClose}
                            memberType={memberType}
                        />
                        :
                        <div onClick={onAddDocumentClick}
                            className="flex w-full min-w-min px-8 py-5 text-gray-900 hover:text-gray-600 border-b-[1.5px] border-b-slate-200 space-x-2 cursor-pointer">
                            <PlusCircleIcon width={22} />
                            <span className='underline'>Add document</span>
                        </div>
                    }
                </div>
            </PerfectScrollbar>
        </div>
    );
}

export default DocumentsTable;