import PublicLayoutBackground from "./PublicLayoutBackground";
import LogoWhite from '../images/logo-white.svg';
import LogoBlack from '../images/logo-black.svg';
import PerfectScrollbar from "react-perfect-scrollbar";

const PublicLayout = ({children}) => {
    return (
        <div className="w-full h-screen">
            <PublicLayoutBackground />
            <PerfectScrollbar className="w-full h-full px-4 pt-8 pb-4 md:pt-8 md:px-12">
                <div className="flex w-full h-full md:min-h-[760px]">
                    <div className="flex justify-center w-full h-full px-4 pt-16 bg-white md:w-3/5 md:pt-36">
                        <div className="w-[480px]">
                            <div className="flex justify-center w-full md:justify-start">
                                <img width={114} height={80} src={LogoBlack} alt="Soland" />
                            </div>
                            <div className="pt-24">
                                {children}
                            </div>
                        </div>
                    </div>
                    <div className="relative h-full pb-6 collapse md:visible md:w-2/5">
                        <div className="absolute flex flex-col items-end text-right bottom-14 right-8">
                            <img width={114} height={80} src={LogoWhite} alt="Soland" />
                            <div className="mt-8 text-2xl font-light text-right text-white font-primary">
                                <p>YOUR WORLD.</p>
                                <p>YOUR CHOICE.</p>
                            </div>
                        </div>
                        <div className="w-full h-full bg-[url('components/images/image-guy-w-case.png')] bg-right-top bg-cover bg-no-repeat">
                            <div className="w-full h-full bg-[url('components/images/s-image.png')] bg-no-repeat bg-center bg-[length:64%_auto]" />
                        </div>
                    </div>
                </div>
            </PerfectScrollbar>
        </div>
    );
}

export default PublicLayout;