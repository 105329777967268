import Datetime from 'react-datetime';

const DateField = ({ value, onChange }) => {
    return (
        <Datetime
            inputProps={{
                className: "w-full p-3 px-4 rounded-md cursor-pointer text-gray-900 drop-shadow-sm focus:drop-shadow-none placeholder:silver600 border-silver200 focus:border-indigo-200 border-[1.4px] outline-0 font-inter"
            }}
            dateFormat="DD/MM/yyyy"
            value={value}
            closeOnSelect
            onChange={onChange}
            timeFormat={false}
        />
    );
}

export default DateField;