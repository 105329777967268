import { DocumentMagnifyingGlassIcon, ExclamationCircleIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import TextField from "../../components/forms/TextField";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import colors from "../../shared/colors";
import FilterButton from "../../components/buttons/FilterButton";
import { getInitials, isSuccess } from "../../shared/util";
import { useContext, useEffect, useState } from "react";
import caseApi from "../../services/apis/caseApi";
import RichTable from "../../components/tables/RichTable";
import { CircleFlag } from "react-circle-flags";
import StatusTag from "../../components/tags/StatusTag";
import { useNavigate } from "react-router-dom";
import userApi from "../../services/apis/userApi";
import clientApi from "../../services/apis/clientApi";
import { AuthContext } from "../../store/AuthContext";
import { DataContext } from "../../store/DataContext";
import SSelect from "../../components/forms/SSelect";
import { applicationStatuses as applicationStatusList } from "../../data/applicationStatuses";
import DateField from "../../components/forms/DateField";
import moment from "moment";
import PrimaryButton from "../../components/buttons/PrimaryButton";

const Cases = () => {
    const authContext = useContext(AuthContext);
    const dataContext = useContext(DataContext);

    const [cases, setCases] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [sortByField, setSortByField] = useState('id');
    const [sortBy, setSortBy] = useState('DESC');
    const [showFilters, setShowFilters] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [exporting, setExporting] = useState(false);

    const [agents, setAgents] = useState([]);
    const [agent, setAgent] = useState(null);
    const [salesAgents, setSalesAgents] = useState([]);
    const [salesAgent, setSalesAgent] = useState(null);
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState(null);

    const [countries, setCountries] = useState(dataContext.countries.map(x => {
        return { value: x.id, display: x.name }
    }));
    const [country, setCountry] = useState(null);

    const [status, setStatus] = useState(null);
    const [statuses, setStatuses] = useState(applicationStatusList);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const caseService = caseApi();
    const userService = userApi();
    const clientService = clientApi();
    const navigate = useNavigate();
    const [hoveredAgent, setHoveredAgentId] = useState(null);
    const [hoveredSales, setHoveredSalesId] = useState(null);
    const getParams = (includePagination) => {
        const params = {
            "filter.isTemplate": false,
            "sortBy": sortByField + ":" + sortBy,
        };

        if (includePagination) {
            params["limit"] = 8;
            params["page"] = page;
        } else {
            params["limit"] = 0;
        }

        if (agent) {
            params["filter.client.agentId"] = agent;
        }

        if (salesAgent) {
            params["filter.client.salesAgentId"] = salesAgent;
        }

        if (client) {
            params["filter.client.id"] = client;
        }

        if (country && country.length > 0) {
            params["filter.program.country.id"] = "$in:" + country.map(x => x.value).join(',');
        }

        if (status && status.length > 0) {
            params["filter.status"] = "$in:" + status.map(x => x.value).join(',');
        }

        if (startDate) {
            params["filter.createdAt"] = "$gte:" + moment(startDate).format("YYYY-MM-DD");
        }

        if (endDate) {
            params["filter.createdAt"] = "$lte:" + moment(endDate).format("YYYY-MM-DD");
        }

        if (searchTerm) {
            params["filter.client.firstName"] = "$ilike:$or:" + searchTerm;
            params["filter.client.lastName"] = "$ilike:$or:" + searchTerm;
            params["filter.client.agent.firstName"] = "$ilike:$or:" + searchTerm;
            params["filter.client.agent.lastName"] = "$ilike:$or:" + searchTerm;
            params["filter.program.name"] = "$ilike:$or:" + searchTerm;
        }

        return params;
    }

    const getCases = async () => {
        setIsLoading(true);

        const getCasesResponse = await caseService.getAll({ params: getParams(true) });
        if (isSuccess(getCasesResponse.status)) {
            setTotalPages(getCasesResponse.data.meta.totalPages);
            setCases(getCasesResponse.data.data);
        }

        setIsLoading(false);
    }

    const filtersActive = () => {
        let numberOfActiveFilters = 0;

        if (agent) {
            numberOfActiveFilters++;
        }

        if (salesAgent) {
            numberOfActiveFilters++;
        }

        if (client) {
            numberOfActiveFilters++;
        }

        if (country && country.length > 0) {
            numberOfActiveFilters++;
        }

        if (status && status.length > 0) {
            numberOfActiveFilters++;
        }

        if (startDate) {
            numberOfActiveFilters++;
        }

        if (endDate) {
            numberOfActiveFilters++;
        }

        return numberOfActiveFilters;
    }

    const getProcessingAgents = async () => {
        const getProcessingAgentsResponse = await userService.getAll({
            params: {
                "filter.role": "$in:agent,admin",
                "limit": 0
            }
        })
        if (isSuccess(getProcessingAgentsResponse.status)) {
            setAgents(getProcessingAgentsResponse.data.data.map(x => {
                return {
                    value: x.id,
                    display: x.firstName && x.lastName ? x.firstName + " " + x.lastName : x.username,
                };
            }));
        }
    }

    const handleAgentMouseEnter = (agentId) => {
        setHoveredAgentId(agentId);
    };

    const handleAgentMouseLeave = () => {
        setHoveredAgentId(null);
    };


    const getSalesAgents = async () => {
        const getSalesAgentsResponse = await userService.getAll({
            params: {
                "filter.role": "$in:admin,sales",
                "limit": 0
            }
        })
        if (isSuccess(getSalesAgentsResponse.status)) {
            setSalesAgents(getSalesAgentsResponse.data.data.map(x => {
                return {
                    value: x.id,
                    display: x.firstName && x.lastName ? x.firstName + " " + x.lastName : x.username,
                };
            }));
        }
    }

    const handleSalesMouseEnter = (salesId) => {
        setHoveredSalesId(salesId);
    };

    const handleSalesMouseLeave = () => {
        setHoveredSalesId(null);
    };

    const getClients = async () => {
        const getClientsResponse = await clientService.getAll({ params: { "limit": 0 } })
        if (isSuccess(getClientsResponse.status)) {
            setClients(getClientsResponse.data.data.map(x => {
                return {
                    value: x.id,
                    display: x.firstName + " " + x.lastName,
                };
            }));
        }
    }

    useEffect(() => {
        getCases(true);
        if (authContext.session.role === 'admin') {
            getProcessingAgents();
            getSalesAgents();
        }
        getClients();
    }, [agent, salesAgent, client, sortBy, sortByField, page, searchTerm, country, status, startDate, endDate]);

    const changeOrder = (field) => {
        if (field !== sortByField) {
            setSortByField(field);
            setSortBy("ASC");
        }

        setSortBy(sortBy === "ASC" ? "DESC" : "ASC");
    }

    const casesTableColumns = [
        // {
        //     display: 'Country',
        //     sortable: true,
        //     sortName: 'program.country.id',
        //     width: 19,
        //     column: 'country',
        //     generate: (item) => {
        //         return item.status === "Know Your Client"
        //         ? "-"
        //         :
        //         (
        //             <div className="flex items-center space-x-3">
        //                 <CircleFlag countryCode={item.program.country.slug} width="22" />
        //                 <span className="text-gray-900">{item.program.country.name}</span>
        //             </div>
        //         );
        //     }
        // },
        {
            display: 'Client',
            sortable: true,
            sortName: 'client.firstName',
            width: 19,
            column: 'client',
            generate: (item) => {
                return (
                    <div className="flex items-center space-x-3">
                        {item.client && item.client.firstName && item.client.lastName ?
                            <>
                                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-slate-100">
                                    <span className="text-slate-900">{getInitials(item.client.firstName + " " + item.client.lastName)}</span>
                                </div>
                                <span className="text-gray-900">{item.client.firstName} {item.client.lastName}</span>
                            </> :
                            <span className="pl-12 text-gray-900">-</span>}
                    </div>
                );
            }
        },
        {
            display: 'Program',
            sortable: true,
            sortName: 'programId',
            width: 19,
            column: 'program',
            generate: (item) => {
                return item.status === "Know Your Client"
                    ? "KYC"
                    :
                    (
                        <div className="flex items-center space-x-3">
                            <CircleFlag title={item.program.country.name} countryCode={item.program.country.slug} width="22" />
                            <div className="flex flex-col space-y-1">
                                <span className="text-gray-900">{item.program.name}</span>
                                {/* <span className="text-sm text-gray-600">{item.program.country.name}</span>                             */}
                            </div>
                        </div>
                    );
            }
        },
        {
            display: 'Sales Agent',
            sortable: true,
            sortName: 'client.salesAgent.firstName',
            width: 19,
            column: 'sales',
            generate: (item) => {
                return (
                    <div className="flex items-center space-x-3">
                        {item.client && item.client.salesAgent && item.client.salesAgent.firstName && item.client.salesAgent.lastName ?
                            <>
                                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-slate-100">
                                    <span className="text-slate-900">{getInitials(item.client.salesAgent.firstName + " " + item.client.salesAgent.lastName)}</span>
                                </div>
                                <span className="text-gray-900">{item.client.salesAgent.firstName} {item.client.salesAgent.lastName}</span>
                            </> :
                            item.client && item.client.salesAgent && item.client.salesAgent.username ?
                                <>
                                    <span
                                        className="flex items-center justify-center w-8 h-8 relative cursor-pointer"
                                        onMouseEnter={() => handleSalesMouseEnter(item.id)}
                                        onMouseLeave={() => handleSalesMouseLeave()}
                                    >
                                        <ExclamationCircleIcon className="left-[-80px] cursor-pointer mr-1" width={22} color={colors.red[500]} onMouseEnter={() => handleSalesMouseEnter(item.id)} onMouseLeave={() => handleSalesMouseLeave()} />
                                        {hoveredSales === item.id && (
                                            <div className="absolute top-full left-0 mt-1 bg-red-400 text-white px-2 py-1 rounded text-sm z-10">Inactive User</div>
                                        )}
                                    </span>
                                    <span className="text-gray-900 flex-grow truncate">{item.client.salesAgent.username}</span>


                                </> :
                                <span className="pl-12 text-gray-900">-</span>
                        }

                    </div>
                );
            }
        },
        {
            display: 'Processing Agent',
            sortable: true,
            sortName: 'client.agent.firstName',
            width: 19,
            column: 'agent',
            generate: (item) => {
                return (
                    <div className="flex items-center space-x-3">
                        {item.client && item.client.agent && item.client.agent.firstName && item.client.agent.lastName ?
                            <>
                                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-slate-100">
                                    <span className="text-slate-900">{getInitials(item.client.agent.firstName + " " + item.client.agent.lastName)}</span>
                                </div>
                                <span className="text-gray-900">{item.client.agent.firstName} {item.client.agent.lastName}</span>
                            </> :
                            item.client && item.client.agent && item.client.agent.username ?
                                <>
                                    <span
                                        className="flex items-center justify-center w-8 h-8 relative cursor-pointer"
                                        onMouseEnter={() => handleAgentMouseEnter(item.id)}
                                        onMouseLeave={() => handleAgentMouseLeave()}
                                    >
                                        <ExclamationCircleIcon className="left-[-80px] cursor-pointer mr-1" width={22} color={colors.red[500]} onMouseEnter={() => handleAgentMouseEnter(item.id)} onMouseLeave={() => handleAgentMouseLeave()} />
                                        {hoveredAgent === item.id && (
                                            <div className="absolute top-full left-0 mt-1 bg-red-400 text-white px-2 py-1 rounded text-sm z-10">Inactive User</div>
                                        )}
                                    </span>
                                    <span className="text-gray-900 flex-grow truncate">{item.client.agent.username}</span>
                                </> :
                                <span className="pl-12 text-gray-900">-</span>
                        }
                    </div>
                );
            }
        },
        {
            display: 'Status',
            sortable: true,
            sortName: 'status',
            width: 23,
            column: 'status',
            textTransform: (item) => <StatusTag status={item.status} />
        },
    ];

    const tableActions = [
        {
            title: 'Details',
            icon: <DocumentMagnifyingGlassIcon width={20} />,
            confirm: false,
            onClick: (item) => { navigate('/applications/' + item.id) }
        }
    ];

    const exportApplications = async () => {
        const applicationExportService = caseApi('/export');

        setExporting(true);
        const exportResponse = await applicationExportService.getAll({ responseType: 'blob', params: getParams() });

        if (isSuccess(exportResponse.status)) {
            // create file link in browser's memory
            const href = URL.createObjectURL(exportResponse.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'applications.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }

        setExporting(false);
    }

    const numberOfActiveFilters = filtersActive();

    return (
        <SidebarLayout title="Applications">
            <div className="flex flex-col mb-8 space-y-3 md:space-y-0 md:space-x-4 md:flex-row">
                <div className="w-full md:w-[380px]">
                    <TextField placeholder="Search" icon={<MagnifyingGlassIcon width={20} color={colors.silver600} />}
                        value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
                <div className="flex space-x-3 grow">
                    <div className="w-full md:w-1/2">
                        <FilterButton text="Filters" className="md:w-[220px]" filtersCount={numberOfActiveFilters} onClick={() => setShowFilters(!showFilters)} />
                    </div>
                </div>
                <div className="flex justify-end w-full">
                    <PrimaryButton className="w-full md:w-[220px]" text="Export" onClick={exportApplications} spinning={exporting} />
                </div>
            </div>
            <div className={`mb-8 ${showFilters ? "block" : "hidden"} rounded=md bg-white py-2 px-8`}>
                <div className="flex flex-col pt-8 space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
                    <div className="flex flex-col w-full space-y-1 md:w-1/4">
                        <label className="text-sm text-gray-700">Country</label>
                        <SSelect
                            name='country'
                            placeholder='Country'
                            onChange={(option) => setCountry(option)}
                            isMulti
                            value={country}
                            options={countries}
                            isClearable={false}
                        />
                    </div>
                    <div className="flex flex-col space-y-1 md:w-1/4">
                        <label className="text-sm text-gray-700">Status</label>
                        <SSelect
                            name='status'
                            placeholder='Status'
                            onChange={(option) => setStatus(option)}
                            isMulti
                            value={status}
                            options={statuses}
                            isClearable={false}
                        />
                    </div>
                </div>
                <div className="flex flex-col pt-8 space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
                    <div className="flex flex-col w-full space-y-1 md:w-1/4">
                        <label className="text-sm text-gray-700">Client</label>
                        <SSelect
                            name='client'
                            placeholder='Client'
                            onChange={(option) => setClient(option ? option.value : null)}
                            value={client}
                            options={clients}
                            isClearable={true}
                        />
                    </div>
                    {authContext.session.role === 'admin' &&
                        <div className="flex flex-col w-full space-y-1 md:w-1/4">
                            <label className="text-sm text-gray-700">Sales agent</label>
                            <SSelect
                                name='sales'
                                placeholder='Sales agent'
                                onChange={(option) => setSalesAgent(option ? option.value : null)}
                                value={salesAgent}
                                options={salesAgents}
                                isClearable={true}
                            />
                        </div>}
                    {authContext.session.role === 'admin' &&
                        <div className="flex flex-col w-full space-y-1 md:w-1/4">
                            <label className="text-sm text-gray-700">Processing officer</label>
                            <SSelect
                                name='agent'
                                placeholder='Processing officer'
                                onChange={(option) => setAgent(option ? option.value : null)}
                                value={agent}
                                options={agents}
                                isClearable={true}
                            />
                        </div>}
                </div>
                <div className="flex flex-col pt-4 pb-8 space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
                    <div className="flex flex-col space-y-1 md:w-1/4">
                        <label className="text-sm text-gray-700">Start Date</label>
                        <DateField
                            value={startDate}
                            onChange={(value) => setStartDate(value)}
                        />
                    </div>
                    <div className="flex flex-col space-y-1 md:w-1/4">
                        <label className="text-sm text-gray-700">End Date</label>
                        <DateField
                            value={endDate}
                            onChange={(value) => setEndDate(value)}
                            isClearable
                        />
                    </div>
                </div>
            </div>

            <RichTable
                columns={casesTableColumns}
                items={cases}
                tableActions={tableActions}
                loading={isLoading}
                page={page}
                setPage={(page) => setPage(page)}
                totalPages={totalPages}
                changeOrder={changeOrder}
                sortByField={sortByField}
                sortBy={sortBy}
            />
        </SidebarLayout>
    );
}

export default Cases;