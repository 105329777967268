import { ApiCore } from "../utilities/core";

const caseApi = (suffix) => {
    return new ApiCore({
        getAll: true,
        getSingle: true,
        post: true,
        put: true,
        patch: true,
        remove: true,
        url: suffix ? 'applications' + suffix : 'applications',
    });
}

export default caseApi;