import { ArrowRightCircleIcon, ArrowUturnRightIcon, CheckCircleIcon, ClockIcon, CreditCardIcon, DocumentChartBarIcon, ExclamationCircleIcon, NoSymbolIcon, RocketLaunchIcon, UserPlusIcon, XCircleIcon, DocumentCheckIcon } from "@heroicons/react/24/outline";
import Tag from "./Tag"
import colors from "../../shared/colors";

const StatusTag = ({ status, ...props }) => {

    const colorList = {
        "Know Your Client": {
            bgColor: 'blue-100',
            color: 'blue-700',
            display: 'Know Your Client',
            icon: <DocumentChartBarIcon width={22} color={colors.blue[500]} />
        },
        "Waiting for agent assignment": {
            bgColor: 'purple-100',
            color: 'purple-700',
            display: 'Waiting for agent assignment',
            icon: <UserPlusIcon width={22} color={colors.purple[500]} />
        },
        "Compliance completed investigation": {
            bgColor: 'green-100',
            color: 'green-700',
            display: 'Compliance completed investigation',
            icon: <DocumentCheckIcon width={22} color={colors.green[500]} />
        },
        "Waiting for payment": {
            bgColor: 'orange-100',
            color: 'orange-700',
            display: 'Waiting for payment',
            icon: <CreditCardIcon width={22} color={colors.orange[500]} />
        },
        "Active": {
            bgColor: 'blue-100',
            color: 'blue-700',
            display: 'Active',
            icon: <ArrowRightCircleIcon width={22} color={colors.blue[500]} />
        },
        "Rejected": {
            bgColor: 'red-200',
            color: 'red-700',
            display: 'Rejected',
            icon: <NoSymbolIcon width={22} color={colors.red[500]} />
        },
        "Pending agent's approval": {
            bgColor: 'slate-200',
            color: 'slate-700',
            display: 'Pending agent\'s approval',
            icon: <ArrowUturnRightIcon width={22} color={colors.slate[500]} />
        },
        "Changes required": {
            bgColor: 'red-200',
            color: 'red-700',
            display: 'Changes required',
            icon: <ExclamationCircleIcon width={22} color={colors.red[500]} />
        },
        "Waiting for physical copy": {
            bgColor: 'orange-100',
            color: 'orange-700',
            display: 'Waiting for physical copy',
            icon: <ClockIcon width={22} color={colors.orange[500]} />
        },
        "Submitting to authority": {
            bgColor: 'yellow-100',
            color: 'yellow-700',
            display: 'Submitting to authority',
            icon: <ArrowUturnRightIcon width={22} color={colors.yellow[500]} />
        },
        "Submitted to authority": {
            bgColor: 'teal-100',
            color: 'teal-700',
            display: 'Submitted to authority',
            icon: <CheckCircleIcon width={22} color={colors.teal[500]} />
        },
        "Approved": {
            bgColor: 'green-100',
            color: 'green-700',
            display: 'Approved',
            icon: <RocketLaunchIcon width={22} color={colors.green[500]} />
        },
        "Save as draft": {
            bgColor: 'gray-200',
            color: 'gray-700',
            display: 'Save as draft',
        },
        "Default": {
            bgColor: 'gray-200',
            color: 'gray-700',
            display: status
        },
        "Accepted": {
            bgColor: 'green-100',
            color: 'green-700',
            display: 'Accepted',
            icon: <CheckCircleIcon width={22} color={colors.green[500]} />
        },
    };

    const getItem = () => {
        return colorList[status] ? colorList[status] : colorList["Default"];
    }

    const getColor = () => {
        return getItem().color;
    }

    const getBgColor = () => {
        return getItem().bgColor;
    }

    const getText = () => {
        return getItem().display;
    }

    const getIcon = () => {
        return getItem().icon;
    }

    return <Tag
        {...props}
        color={getColor()}
        bgColor={getBgColor()}
        text={getText()}
        icon={getIcon()}
    />
}

export default StatusTag;