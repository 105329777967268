import DropdownField from "../../../components/forms/DropdownField";
import { useEffect, useState } from "react";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { PlusIcon } from "@heroicons/react/24/outline";
import { getCountryList, isSuccess, showSuccess } from "../../../shared/util";
import programApi from "../../../services/apis/programApi";
import userApi from "../../../services/apis/userApi";
import TextField from "../../../components/forms/TextField";
import { CircleFlag } from "react-circle-flags";
import caseApi from "../../../services/apis/caseApi";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import DateField from "../../../components/forms/DateField";
import TextArea from "../../../components/forms/TextArea";
import Toggle from "../../../components/forms/Toggle";

const AddCaseForm = ({ client, onCaseAdded }) => {
    const programService = programApi();
    const userService = userApi();
    const caseService = caseApi();

    const [country, setCountry] = useState(null);
    const [countries, setCountries] = useState([]);
    const [program, setProgram] = useState(null);
    const [programs, setPrograms] = useState([]);
    const [agent, setAgent] = useState(null);
    const [agents, setAgents] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [description, setDescription] = useState("");
    const [autoAssignAgent, setAutoAssignAgent] = useState(false);

    const [addCaseInProgress, setAddCaseInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getPrograms();
        getProcessingAgents();
    }, []);

    const getProcessingAgents = async () => {
        const getProcessingAgentsResponse = await userService.getAll({
            params: {
                "filter.role": "$in:agent,admin",
                "limit": 0
            }
        })
        if (isSuccess(getProcessingAgentsResponse.status)) {
            setAgents(getProcessingAgentsResponse.data.data.map(x => {
                return {
                    value: x.id,
                    display: x.firstName && x.lastName ? x.firstName + " " + x.lastName : x.username,
                };
            }));
        }
    }

    const getPrograms = async () => {
        const getProgramsResponse = await programService.getAll({ params: { "filter": 0 } });
        if (isSuccess(getProgramsResponse.status)) {
            const distinctCountryIds = [...new Set(getProgramsResponse.data.data.map(x => x.countryId))];
            const countryList = distinctCountryIds.map(x => {
                const obj = getProgramsResponse.data.data.find(y => y.countryId === x);
                return {
                    value: obj.countryId,
                    display: obj.country.name,
                    icon: <CircleFlag countryCode={obj.country.slug} width={22} />
                };
            });

            if (countryList.length > 0) {
                setCountry(countryList[0]);
            }
            setCountries(countryList);
            setPrograms(getProgramsResponse.data.data);
        }
    }

    const getProgramsForDisplay = () => {
        if (!country) {
            return [];
        }

        return programs.filter(x => x.countryId === country).map(x => {
            return {
                value: x.id,
                display: x.name
            }
        });
    }

    const resetForm = () => {
        setDescription("");
        setCountry(null);
        setProgram(null);
        setAgent(null);
    }

    const handleAddCaseButtonClicked = async () => {
        setErrorMessages([]);
        setAddCaseInProgress(true);

        const addCaseResponse = await caseService.post({
            programId: program,
            clientId: client.id,
            agentId: autoAssignAgent ? null : agent,
            startDate: startDate,
            dueDate: endDate,
            description: description,
            autoAssignAgent: autoAssignAgent,
        });

        if (isSuccess(addCaseResponse.status)) {
            setAddCaseInProgress(false);
            resetForm();

            if (onCaseAdded) {
                onCaseAdded();
            } else {
                showSuccess("Application has been added");
                navigate("/applications/" + addCaseResponse.data.id);
            }
        } else {
            setErrorMessages([{ message: 'Something went wrong. Please contact system administrator.' }]);
            setAddCaseInProgress(false);
        }
    }

    const isFormValid = () => {
        return country && (agent || autoAssignAgent) && client && program;
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
    }


    return (
        <form className="flex flex-col space-y-10" onSubmit={onFormSubmit}>
            <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Country</label>
                    <DropdownField
                        name='country'
                        placeholder='Country'
                        onChange={(option) => setCountry(option.value)}
                        value={country}
                        options={countries}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Program</label>
                    <DropdownField
                        name='program'
                        placeholder='Program'
                        onChange={(option) => setProgram(option.value)}
                        value={program}
                        options={getProgramsForDisplay()}
                    />
                </div>
                {/* <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Client</label>
                    <DropdownField
                        name='client'
                        placeholder='Client'
                        onChange={(option) => setClient(option.value)}
                        value={client}
                        options={clients}
                    />
                </div> */}
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Auto assign processing agent</label>
                    <Toggle
                        value={autoAssignAgent}
                        onChange={() => setAutoAssignAgent(!autoAssignAgent)}
                    />
                </div>
                {!autoAssignAgent && <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Processing officer</label>
                    <DropdownField
                        name='agent'
                        placeholder='Processing officer'
                        onChange={(option) => setAgent(option.value)}
                        value={agent}
                        options={agents}
                    />
                </div>}
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Start Date</label>
                    <DateField
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        className={`w-full p-3 px-4 rounded-md text-gray-900 drop-shadow-sm focus:drop-shadow-none
                        placeholder:silver600 border-silver200 focus:border-indigo-200 border-[1.4px] outline-0`}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">End Date</label>
                    <DateField
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        className={`w-full p-3 px-4 rounded-md text-gray-900 drop-shadow-sm focus:drop-shadow-none
                        placeholder:silver600 border-silver200 focus:border-indigo-200 border-[1.4px] outline-0`}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Description</label>
                    <TextArea
                        name='description'
                        placeholder='Description'
                        value={description}
                        rows={4}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
            </div>
            <div className="">
                <PrimaryButton
                    text="Add Application"
                    icon={<PlusIcon width={24} />}
                    spinning={addCaseInProgress}
                    onClick={handleAddCaseButtonClicked}
                    disabled={!isFormValid() || addCaseInProgress}
                    type="submit"
                />
            </div>
            {/* <div className="flex flex-col pl-2 mt-4 space-y-1">
                {errorMessages && errorMessages.map((errorMessage, index) => (
                    <span key={'add-user-err-message-' + index} className="text-red-600">{errorMessage.message}</span>
                ))}
            </div> */}
        </form>
    );
}

export default AddCaseForm;