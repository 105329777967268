import { useEffect, useState } from "react";
import { changeField, getFirstError, isValid } from "../../../shared/validate";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { isSuccess } from "../../../shared/util";
import TextField from "../../../components/forms/TextField";
import userApi from "../../../services/apis/userApi";

const AddClientForm = ({onEditSent, user}) => {
    const firstNameDefault = {
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        editable: true,
        validators: [
            {
                type: 'required',
                message: 'First name is required.',
                isValid: true,
            },
        ],
    };
    const [firstName, setFirstName] = useState(firstNameDefault);

    const lastNameDefault = {
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        editable: true,
        validators: [
            {
                type: 'required',
                message: 'Last name is required.',
                isValid: true,
            },
        ],
    };
    const [lastName, setLastName] = useState(lastNameDefault);

    const [saveClientInProgress, setSaveClientInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const userService = userApi();

    useEffect(() => {
        if(user) {
            setFirstName({...firstName, value: user.firstName, isValid: true});
            setLastName({...lastName, value: user.lastName, isValid: true});
        }
    }, []);

    const resetForm = () => {
        setFirstName(firstNameDefault);
        setLastName(lastNameDefault);
    }

    const handleSaveClientButtonClicked = async () => {
        setSaveClientInProgress(true);
    
        const createUserResponse = await userService.patch(user.id, 
            { 
                firstName: firstName.value, 
                lastName: lastName.value, 
            });

        if (isSuccess(createUserResponse.status)) {
            onEditSent()
            setSaveClientInProgress(false);
            resetForm();
        } else {
            setErrorMessages([{ message: 'Something went wrong. Please contact system administrator.' }]);
            setSaveClientInProgress(false);
        }

    }

    const isFormValid = () => {
        return isValid([firstName, lastName]);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <form className="flex flex-col space-y-10" onSubmit={onFormSubmit}>
            <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">First name</label>
                    <TextField
                        name='first_name'
                        placeholder='First name'
                        value={firstName.value}
                        isValid={firstName.isValid || !firstName.showValidation}
                        onChange={(e) => setFirstName(changeField(e, { ...firstName }))}
                        onBlur={(e) => setFirstName({ ...firstName, showValidation: true })}
                        errorMessage={firstName.showValidation ? getFirstError(firstName) : ''}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Last name</label>
                    <TextField
                        name='last_name'
                        placeholder='Last name'
                        value={lastName.value}
                        isValid={lastName.isValid || !lastName.showValidation}
                        onChange={(e) => setLastName(changeField(e, { ...lastName }))}
                        onBlur={(e) => setLastName({ ...lastName, showValidation: true })}
                        errorMessage={lastName.showValidation ? getFirstError(lastName) : ''}
                    />
                </div>
            </div>
            <div className="">
                <PrimaryButton
                    text="Save Client"
                    spinning={saveClientInProgress}
                    onClick={handleSaveClientButtonClicked}
                    disabled={!isFormValid()}
                    type="submit"
                />
            </div>
            <div className="flex flex-col pl-2 mt-4 space-y-1">
                {errorMessages && errorMessages.map((errorMessage, index) => (
                    <span key={'add-user-err-message-' + index} className="text-red-600">{errorMessage.message}</span>
                ))}
            </div>
        </form>
    );
}

export default AddClientForm;