import { useContext, useEffect, useState } from "react";
import DropdownField from "./DropdownField";
import TextField from "./TextField"
import { DataContext } from "../../store/DataContext";
import { CircleFlag } from "react-circle-flags";
import Toggle from "./Toggle";
import { getErrorForChain } from "../../shared/validate";
import TextArea from "./TextArea";
import DateTimeField from "./DateTimeField";
import moment from "moment";
import SSelect from "./SSelect";
import DateField from "./DateField";
import NumberField from "./NumberField";
import InlineFileDropzone from "../upload/InlineFileDropzone";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const FieldSwitch = ({ item, type, value, isRequired, onChange, ...props }) => {
    const dataContext = useContext(DataContext);
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const change = (val) => {
        validate(val);
        onChange(val);
    }

    useEffect(() => {
        if (type === 'toggle' && (!value || value === 'false')) {
            onChange(false);
        }
    }, [])

    const validations = [
        {
            type: 'text-email',
            validators: [{
                type: 'email',
                message: 'Not a valid email address'
            }],
        },
    ];

    const validate = (val) => {
        const validator = validations.find(x => x.type === type);
        const chainValidators = validator ? validator.validators : [];

        if (isRequired && type !== 'toggle') {
            chainValidators.unshift({
                type: 'required',
                message: 'This is required field',
            })
        }

        const errorMessage = getErrorForChain(val, chainValidators);

        setIsValid(!errorMessage);
        setErrorMessage(errorMessage);
    }

    const getField = () => {
        switch (type) {
            case "text": {
                return (
                    <TextField
                        onChange={(e) => change(e.target.value)}
                        value={value}
                        {...props}
                    />
                )
            }
            case "number": {
                return (
                    <NumberField
                        onChange={(e) => change(e.target.value)}
                        value={value}
                        {...props}
                    />
                )
            }
            case "text-email": {
                return (
                    <TextField
                        onChange={(e) => change(e.target.value)}
                        value={value}
                        {...props}
                    />
                )
            }
            case "text-phone-number": {
                return (
                    
                    <PhoneInput
                    className="w-full p-3 px-4 rounded-md text-gray-900 drop-shadow-sm focus:drop-shadow-none
                    placeholder:silver600 border-silver200 focus:border-indigo-200 border-[1.4px] outline-0"
                        onChange={(e) => change(e)}
                        value={value}
                        international
                    />
                )
            }
            case "textarea": {
                return (
                    <TextArea
                        onChange={(e) => change(e.target.value)}
                        value={value}
                        {...props}
                        rows="4"
                    />
                )
            }
            case "datetime": {
                return (
                    <DateTimeField
                        {...props}
                        onChange={(newValue) => change(newValue)}
                        value={value ? moment(value).format("DD/MM/yyyy hh:mm") : null}
                    />
                )
            }
            case "date": {
                return (
                    <DateField
                        {...props}
                        onChange={(newValue) => change(newValue)}
                        value={value ? moment(value).format("DD/MM/yyyy") : null}
                        timeFormat={false}
                    />
                )
            }
            case "dropdown-countries": {
                return (
                    <SSelect
                        name='country'
                        placeholder='Country'
                        onChange={(option) => change(option.value)}
                        value={value}
                        options={dataContext.countries.map(x => {
                            return {
                                value: x.name,
                                display: x.name,
                                icon: <CircleFlag countryCode={x.slug} width={22} />
                            }
                        })}
                        {...props}
                    />
                )
            }
            case "dropdown-languages": {
                return (
                    <SSelect
                        name='language'
                        placeholder='Language'
                        onChange={(option) => change(option.value)}
                        value={value}
                        options={dataContext.languages.map(x => {
                            return {
                                value: x,
                                display: x,
                            }
                        })}
                        {...props}
                    />
                )
            }
            case "dropdown-industries": {
                return (
                    <SSelect
                        name='industries'
                        placeholder='Industry'
                        onChange={(option) => change(option.value)}
                        value={value}
                        options={dataContext.industries.map(x => {
                            return {
                                value: x,
                                display: x,
                            }
                        })}
                        {...props}
                    />
                )
            }
            case "dropdown-source-of-discovery": {
                return (
                    <SSelect
                        name='sourceOfDiscovery'
                        placeholder='Source of discovery'
                        onChange={(option) => change(option.value)}
                        value={value}
                        options={dataContext.sourceOfDiscoveries.map(x => {
                            return {
                                value: x,
                                display: x,
                            }
                        })}
                        {...props}
                    />
                )
            }
            case "dropdown-budget": {
                return (
                    <SSelect
                        name='budget'
                        placeholder='Budget'
                        onChange={(option) => change(option.value)}
                        value={value}
                        options={dataContext.budgets.map(x => {
                            return {
                                value: x,
                                display: x,
                            }
                        })}
                        {...props}
                    />
                )
            }
            case "dropdown-programs": {
                return (
                    <SSelect
                        name='program'
                        placeholder='Program'
                        onChange={(option) => change(option.value)}
                        value={value}
                        options={dataContext.programs.map(x => {
                            return {
                                value: x,
                                display: x,
                            }
                        })}
                        {...props}
                    />
                )
            }
            case "dropdown-gender": {
                return (
                    <SSelect
                        name='gender'
                        placeholder='Gender'
                        onChange={(option) => change(option.value)}
                        value={value}
                        options={dataContext.genders.map(x => {
                            return {
                                value: x,
                                display: x,
                            }
                        })}
                        {...props}
                    />
                )
            }
            case "dropdown-marital-status": {
                return (
                    <SSelect
                        name='marital-status'
                        placeholder='Marital status'
                        onChange={(option) => change(option.value)}
                        value={value}
                        options={dataContext.maritalStatuses.map(x => {
                            return {
                                value: x,
                                display: x,
                            }
                        })}
                        {...props}
                    />
                )
            }
            case "toggle": {
                return (
                    <Toggle
                        onChange={(e) => change(!value)}
                        value={value}
                        className="items-start"
                        {...props}
                    />
                )
            }
            case "file": {
                return (
                    <InlineFileDropzone
                        onUpload={(file) => change(file)}
                        file={value}
                        className="items-start"
                        fileName={item.fileId ? "File" : null}
                        {...props}
                    />
                )
            }
            default: {
                return null;
            }
        }
    }

    return (
        <div className="w-full">
            {getField()}
            {!isValid && <div className="">
                <span className="text-sm text-red-500">{errorMessage}</span>
            </div>}
        </div>
    );
}

export default FieldSwitch;