import { XMarkIcon } from "@heroicons/react/24/outline";

export const TAG_DYNAMICLY_GENERATED_CLASSES =
    'bg-gray-100'  |
    'text-gray-700' |
    'bg-green-100' |
    'text-green-700' |
    'bg-orange-100'  |
    'text-orange-700' |
    'bg-red-200'  |
    'text-red-700'  |
    'text-red-700' |
    'bg-blue-100'  |
    'text-blue-700' |
    'bg-slate-100'  |
    'text-slate-700' |
    'bg-yellow-100'  |
    'text-yellow-700' |
    'bg-lime-100'  |
    'text-lime-700' |
    'bg-teal-100'  |
    'text-teal-700' |
    'bg-cyan-100'  |
    'text-cyan-700' |
    'bg-purple-100'  |
    'text-purple-700' 
;

const Tag = ({text, closable, icon, bgColor, color, className, onClick}) => {
    const getClassName = () => {
        const baseClassName = "min-w-min w-fit";
        return className ? baseClassName + " " + className : baseClassName;
    }

    return (
        <div onClick={onClick} className={getClassName()}>
            <div className={`inline-flex items-center px-4 py-1 space-x-2 bg-${bgColor} rounded-2xl`}>
                {icon}
                <span className={`text-${color}`}>{text}</span>
                {closable && <XMarkIcon className="cursor-pointer" width={20} />}
            </div>
        </div>
    );
}

export default Tag;

Tag.defaultProps = {
    bgColor: 'gray-100',
    color: 'gray-700',
}
