import { ApiCore } from "../utilities/core";
const applicationMemberApi = (id) => {
    return new ApiCore({
        getAll: true,
        getSingle: true,
        post: true,
        put: true,
        patch: true,
        remove: true,
        url: 'applications/' + id + '/application-members'
    });
}

export default applicationMemberApi;