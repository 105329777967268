import { ChevronDownIcon } from "@heroicons/react/24/outline";
import colors from "../../shared/colors";
import Select, {
    components,
} from 'react-select'
import { CircleFlag } from "react-circle-flags";

const SSelect = ({ value, options, onChange, placeholder, isSearchable, className, ...props }) => {
    const formatOptionLabel = ({ value, label, icon }) => (
        <div className="flex space-x-2">
            {icon && icon}
            <span>{label}</span>
        </div>
    );

    const MultiValueLabel = (item) => {
        return (
            <div className="flex px-3 py-1 space-x-2">
                {item.data.icon && item.data.icon}
                <span>{item.data.label}</span>
            </div>
        );
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ChevronDownIcon width={22} color={colors.gray[900]} />
            </components.DropdownIndicator>
        );
    };

    const getOptions = () => {
        return options.map(x => { return { value: x.value, label: x.display, icon: x.icon } });
    }

    const getSelectedOption = () => {
        return getOptions().find(x => x.value === value);
    }

    return (
        <div className={className}>
            <Select
                {...props}
                options={getOptions()}
                unstyled
                menuPlacement="auto"
                minMenuHeight={300}
                isSearchable={isSearchable}
                onChange={onChange}
                components={{ DropdownIndicator, MultiValueLabel }}
                value={getSelectedOption()}
                placeholder={placeholder}
                classNames={{
                    valueContainer: () => "cursor-pointer",
                    control: () => "rounded-md text-gray-900 bg-white py-3 px-4 focus:drop-shadow-none cursor-pointer placeholder:silver600 border-silver200 focus:border-indigo-200 border-[1.4px] outline-0",
                    menu: () => "shadow bg-white cursor-pointer",
                    option: () => "hover:bg-gray-50 px-4 py-2 cursor-pointer",
                    multiValue: () => "bg-gray-100 pr-2 rounded-full"
                }}
            />
        </div>
    );
}

export default SSelect;

SSelect.defaultProps = {
    placeholder: "Select value"
}