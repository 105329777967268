import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "../buttons/SecondaryButton";
import DocumentRelatedFileListItem from "./DocumentRelatedFileListItem";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../store/AuthContext";
import axiosClient from "../../shared/axiosClient";
import { downloadA, isSuccess } from "../../shared/util";
import { useDropzone } from "react-dropzone";
import BaseModal from "../modals/BaseModal";
import PrimaryButton from "../buttons/PrimaryButton";
import ConfirmModal from "../modals/ConfirmModal";

const DocumentRelatedFileList = ({ document, canEdit }) => {
    const authContext = useContext(AuthContext);

    const AdminRole = authContext.session.role === 'admin';
    const [uploading, setUploading] = useState(false);
    const [tmpRelatedFiles, setTmpRelatedFiles] = useState(document.documentRelatedFiles ?? []);
    const [selectedRelatedDocument, setSelectedRelatedDocument] = useState(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);


    const onDrop = useCallback(acceptedFiles => {
        upload(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const { onClick, ...rootProps } = getRootProps();

    const documentRef = useRef(document)
    const tmpRelatedFilesRef = useRef(tmpRelatedFiles)

    useEffect(() => {
        documentRef.current = document
    }, [document])

    useEffect(() => {
        tmpRelatedFilesRef.current = tmpRelatedFiles
    }, [tmpRelatedFiles])

    const uploadFile = async (file) => {
        if (file) {
            let formData = new FormData();
            formData.append('file', file);

            const fileUploadResponse = await axiosClient.post("documents/" + documentRef.current.id + "/related-file", formData, {
                headers: { "Content-type": "multipart/form-data" }
            });

            if (isSuccess(fileUploadResponse.status)) {
                let t = [...tmpRelatedFilesRef.current, {
                    ...fileUploadResponse.data,
                    file: {
                        name: file.name
                    }
                }]
                setTmpRelatedFiles(t)
            }
        }
    }

    const upload = async (file) => {
        setUploading(true);
        await uploadFile(file);
        setUploading(false);

    }

    const downloadFile = async (documentRelatedFile) => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/documents/" + documentRelatedFile.documentId + "/related-file/" + documentRelatedFile.id);
        if (isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const onDeleteClick = (selectedRelatedDocument) => {
        setSelectedRelatedDocument(selectedRelatedDocument);
        setShowConfirmDeleteModal(true);
    }

    const onDelete = async () => {
        const fileUploadResponse = await axiosClient.delete("documents/" + selectedRelatedDocument.documentId + "/related-file/" + selectedRelatedDocument.id);

        if (isSuccess(fileUploadResponse.status)) {
            setTmpRelatedFiles(tmpRelatedFiles.filter(a => a.id !== selectedRelatedDocument?.id));
            setSelectedRelatedDocument(null);
        }

        setShowConfirmDeleteModal(false);
    }

    return (<>
        <div className="flex flex-col space-y-4 max-w-[700px]">
            {canEdit && <div {...rootProps}>
                <input {...getInputProps()} />
                {
                    <SecondaryButton
                        lIcon={<ArrowUpOnSquareIcon width={22} />}
                        text="Upload related file"
                        className="max-w-[280px]"
                        size="sm"
                        onClick={onClick}
                        spinning={uploading}
                        disabled={!AdminRole}
                    />
                }
            </div>}

            {tmpRelatedFiles?.length > 0 && <div className="font-bold">Related files</div>}


            {tmpRelatedFiles && tmpRelatedFiles.map((relatedDocument, index) => (
                <DocumentRelatedFileListItem
                    key={"document-related-file-item-" + index}
                    document={relatedDocument}
                    index={index}
                    canEdit={canEdit}
                    onFileClick={downloadFile}
                    onDeleteClick={onDeleteClick}
                />
            ))}
            <div className="absolute w-full">
                <ConfirmModal
                    key={document.id}
                    show={showConfirmDeleteModal}
                    title="Confirm document related file deletion"
                    message="Are you sure you want to delete this file?"
                    onConfirm={onDelete}
                    onClose={() => setShowConfirmDeleteModal(false)}
                />
            </div>

        </div>

    </>
    );
}

export default DocumentRelatedFileList;