import { useParams } from "react-router-dom";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import { useEffect, useState } from "react";
import questionnaireApi from "../../services/apis/questionnaireApi";
import { isSuccess, showSuccess } from "../../shared/util";
import FormForm from "./forms/FormForm";

const EditForm = () => {
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [saveQuestionnaireInProgress, setSaveQuestionnaireInProgress] = useState(false);

    const [questionnaire, setQuestionnaire] = useState(null);
    const questionnaireService = questionnaireApi();

    useEffect(() => {
        getQuestionnaire();
    }, []);

    const getQuestionnaire = async () => {
        const getQuestionnaireResponse = await questionnaireService.getSingle(id);
        if(isSuccess(getQuestionnaireResponse.status)) {
            setQuestionnaire(getQuestionnaireResponse.data);
        }

        setIsLoading(false);
    }

    const saveQuestionnaire = async (newQuestionnaire) => {
        setSaveQuestionnaireInProgress(true);

        const tmpQestionnaire = { ...newQuestionnaire };

        tmpQestionnaire.sections.forEach((section, index, theArray) => {
            theArray[index].orderNumber = index + 1;

            section.items.forEach((item, yIndex, itemArray) => {
                itemArray[yIndex].orderNumber = yIndex + 1;
                if (item.id === -1) {
                    delete itemArray[yIndex].id;
                }
            });
        });

        const patchProgramQuestionnaireResponse = await questionnaireService.patch(id, tmpQestionnaire);
        if (isSuccess(patchProgramQuestionnaireResponse.status)) {
            getQuestionnaire();
            showSuccess("Questionnaire has been saved");
        }

        setSaveQuestionnaireInProgress(false);
    }

    return (
        <SidebarLayout title="Edit Form">
            {questionnaire && 
                <FormForm 
                    questionnaire={questionnaire} 
                    setQuestionnaire={setQuestionnaire}
                    save={saveQuestionnaire}
                    showDetailsForm={true}
                />
            }
        </SidebarLayout>
    );
}

export default EditForm;