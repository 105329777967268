import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { generatePaginationArray } from "../../shared/util";
import SecondaryButton from "../buttons/SecondaryButton";
import colors from "../../shared/colors";

const TablePagination = ({ page, setPage, totalPages }) => {
    const pages = generatePaginationArray(page, totalPages);

    const onPageClick = (page) => {
        if(page === -1) {
            return;
        }

        setPage(page);
    }

    if (!pages || pages.length < 2) {
        return null;
    }

    return (
        <div className="flex flex-col justify-center w-full px-8 py-6 space-y-3 rounded-b-md">
            <div className="w-full md:hidden">
                <div className="flex items-center justify-center w-full space-x-6">
                    {pages.map((pageItem, index) => (
                        <span key={"pagination-item-mobile-" + index} onClick={() => onPageClick(pageItem)} className={`text-center ${page === pageItem ? "text-coyoteb" : "text-brand-600"} grow`}>{pageItem === -1 ? "..." : pageItem}</span>
                    ))}
                </div>
            </div>

            <div className="flex w-full">
                <div className="flex justify-start w-1/2 md:w-1/3">
                    <SecondaryButton disabled={page === 1} onClick={() => onPageClick(page - 1)} size="sm" text="Previous" lIcon={<ArrowLeftIcon width={20} color={colors.gray[700]} />} />
                </div>
                <div className="items-center justify-center hidden w-full space-x-6 md:w-1/3 md:flex">
                    {pages.map((pageItem, index) => (
                        <div key={"pagination-item-" + index} onClick={() => onPageClick(pageItem)} className={`flex items-center justify-center w-10 h-10 rounded-md 
                            ${pageItem !== -1 && "cursor-pointer hover:bg-cultured"} ${pageItem === page && "bg-cultured"}`}>
                            <span className={page === pageItem ? "text-coyoteb" : "text-gray-500"}>{pageItem === -1 ? "..." : pageItem}</span>
                        </div>
                    ))}
                </div>
                <div className="flex justify-end w-1/2 md:w-1/3">
                    <SecondaryButton size="sm" disabled={page === totalPages} onClick={() => onPageClick(page + 1)} text="Next" rIcon={<ArrowRightIcon width={20} color={colors.gray[700]} />} />
                </div>
            </div>
        </div>
    );
}

export default TablePagination;