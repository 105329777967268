import Spinner from "../icons/Spinner";
import { Link } from "react-router-dom";

const PrimaryButton = ({text, icon, spinning, onClick, to, disabled, className, type}) => {
    const getButton = () => {
        return (
            <button 
                type={type}
                disabled={disabled} 
                onClick={onClick} 
                className="flex items-center justify-center w-full px-4 py-3 space-x-3 font-medium text-white rounded-md cursor-pointer active:drop-shadow-none drop-shadow disabled:opacity-75 disabled:cursor-default disabled:bg-coyoteb bg-coyoteb hover:bg-fielddrab font-primary outline-0">
                    <div>{text}</div>
                    {icon && !spinning && icon}
                    {spinning && <Spinner />}
            </button>
        );
    }

    return (
        <div className={className}>
            {onClick ? getButton() : <Link to={to}>{getButton()}</Link> }
        </div>
    );
}

PrimaryButton.defaultProps = {
    text: 'Button', 
    spinning: false,
    type: 'button',
}

export default PrimaryButton;