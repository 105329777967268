import { useContext } from "react";
import { AuthContext } from "../store/AuthContext";
import { Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const ProtectedRoute = ({children, roles}) => {
    const authContext = useContext(AuthContext);
    const location = useLocation();

    if(!authContext.session) {
        return <Navigate to="/login" />;
    }

    if(authContext.session.role === 'client' && !['/board', '/questionnaire', '/documents'].includes(location.pathname)) {
        return <Navigate to="/board" />;
    }

    if(roles && !roles.includes(authContext.session.role)) {
        return <Navigate to="/" />;
    }

    return children;
}

export default ProtectedRoute;