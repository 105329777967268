import { DndContext } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import DocumentItem from "./DocumentItem";
import DocumentItemForm from "./DocumentItemForm";

const DocumentsListSortable = ({ items, document, onChangeDocument, onEdit, isValid, onSetItems,
    showAddDocumentForm, dataContext, onEditClicked, onAddDocumentClose, onDeleteClicked, selectedIndex, memberType, memberId }) => {

    const reorderList = (e) => {
        if (!e.over) {
            return;
        }

        if (e.active.id !== e.over.id) {
            const oldIndex = items.findIndex(x => x.id === e.active.id);
            const newIndex = items.findIndex(x => x.id === e.over.id);

            const tmpItems = arrayMove(items, oldIndex, newIndex);
            onSetItems(tmpItems);
        }
    }

    return (
        <DndContext onDragEnd={reorderList}>
            <SortableContext items={items}>
                {items.map((documentItem, index) => (
                    showAddDocumentForm && index === selectedIndex
                        ?
                        <DocumentItemForm
                            document={document}
                            onChangeDocument={onChangeDocument}
                            onAddDocument={() => onEdit(index)}
                            onAddDocumentClose={onAddDocumentClose}
                            isValid={isValid}
                            memberType={memberType}
                        />
                        :
                        <DocumentItem
                            key={`doc-item-${index}`}
                            index={index}
                            document={documentItem}
                            showAddDocumentForm={showAddDocumentForm}
                            dataContext={dataContext}
                            onEditClicked={onEditClicked}
                            onAddDocumentClose={onAddDocumentClose}
                            onDeleteClicked={() => onDeleteClicked(index)}
                            memberType={memberType}
                        />
                ))}
            </SortableContext>
        </DndContext>
    );
};

export default DocumentsListSortable;