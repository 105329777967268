import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import colors from "../../shared/colors";

const FilterButton = ({text, filtersCount, onClick, className}) => {
    return (
        <div className={className}>
            <button 
                onClick={onClick} 
                className="flex items-center border-silver200 justify-center w-full drop-shadow-sm border-[1.4px] px-4 py-3 space-x-3 active:drop-shadow-none font-medium text-gray-700 bg-white rounded-md cursor-pointer disabled:opacity-75 disabled:cursor-default disabled:bg-gray-200 hover:bg-gray-100 font-primary">
                    <AdjustmentsHorizontalIcon width={20} color={colors.gray[700]} />
                    <div>{text}</div>
                    {filtersCount !== 0 &&
                        <div className="flex items-center justify-center w-6 h-6 rounded-full bg-dirt">
                            <span className="text-sm text-white">{filtersCount}</span>
                        </div>
                    }
            </button>
        </div>
    );
}

export default FilterButton;