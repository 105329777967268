import moment from "moment";
import axiosClient from "../../shared/axiosClient";
import { downloadA, isSuccess } from "../../shared/util";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

const FieldSwitchDisplay = ({ item, type, value, isRequired, onChange, ...props }) => {
    const downloadFile = async () => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/questionnaires/" + item.questionnaireId + "/items/" + item.id + "/file");
        if(isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const getField = () => {
        switch (type) {
            case "text" : {
                return (
                    <span>{value}</span>
                )
            }
            case "number" : {
                return (
                    <span>{value}</span>
                )
            }
            case "text-email" : {
                return (
                    <span>{value}</span>
                )
            }
            case "text-phone-number" : {
                return (
                    <span>{value}</span>
                )
            }
            case "textarea" : {
                return (
                    <span>{value}</span>
                )
            }
            case "datetime" : {
                return (
                    <span>{value ? moment(value).format("DD/MM/yyyy hh:mm") : null}</span>
                )
            }
            case "date" : {
                return (
                    <span>{value ? moment(value).format("DD/MM/yyyy") : null}</span>
                )
            }
            case "dropdown-countries" : {
                return (
                    <span>{value}</span>
                )
            }
            case "dropdown-languages" : {
                return (
                    <span>{value}</span>
                )
            }
            case "dropdown-industries" : {
                return (
                    <span>{value}</span>
                )
            }
            case "dropdown-source-of-discovery": {
                return (
                    <span>{value}</span>
                )
            }
            case "dropdown-budget": {
                return (
                    <span>{value}</span>
                )
            }
            case "dropdown-programs": {
                return (
                    <span>{value}</span>
                )
            }
            case "dropdown-gender": {
                return (
                    <span>{value}</span>
                )
            }
            case "dropdown-marital-status": {
                return (
                    <span>{value}</span>
                )
            }
            case "toggle" : {
                return (
                    <span>{value ? "Yes" : "No"}</span>
                )
            }
            case "file" : {
                return item.fileId ?
                    <span className="text-blue-500 cursor-pointer" onClick={downloadFile}><DocumentArrowDownIcon width={22} /></span> :
                    <span>-</span>;
            }
            default: {
                return null;
            }
        }
    }

    if(!value && !item.fileId) {
        return <span className="">-</span>
    }

    return getField();
}

export default FieldSwitchDisplay;