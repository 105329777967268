import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import TablePagination from './TablePagination';
import { useState } from 'react';

const RichTable = ({ items, columns, tableActions, changeOrder, loading, page, setPage, totalPages, sortBy, sortByField, actionsStyle }) => {
    const [confirmModalRow, setConfrimModalRow] = useState(-1);
    const [confirmMessage, setConfirmMessage] = useState("");

    const handleActionClick = (action, item, index) => {
        if (action.confirm) {
            setConfirmMessage(action.confirmMessage);
            setConfrimModalRow(index);
            return;
        }

        action.onClick(item);
    }

    const generateCell = (column, item) => {
        if (column.generate) {
            return column.generate(item);
        }

        if (column.textTransform) {
            return column.textTransform(item);
        }

        return (
            <span className="pr-2 text-gray-900 truncate">{item[column.column]}</span>
        );
    }

    const getActionsWidth = () => {
        const sum = columns.reduce((a, b) => {
            return a + b.width;
        }, 0);

        return 100 - sum;
    }
    const actionsWidth = getActionsWidth();

    return (
        <div className="overflow-hidden bg-white rounded-md">
            <div className='overflow-x-auto'>
                <div className="flex w-full px-8 py-4 bg-gray-50 min-w-min rounded-t-md border-b-[1.5px] border-b-slate-200">
                    {columns.map((column, index) => (
                        <div key={"table-column-title-" + index} onClick={() => column.sortable && changeOrder(column.sortName)}
                            className={`flex space-x-1 ${column.width && "md:w-[" + column.width + "%]"} justify-start items-center min-w-[210px] cursor-pointer`}>
                            <span className={`text-sm ${column.sortName === sortByField ? "text-gray-700" : "text-gray-500"}`}>{column.display}</span>
                            {column.sortable &&
                                <span className={`${column.sortName === sortByField ? "text-gray-700" : "text-gray-500"}`} onClick={() => changeOrder(column.sortName)}>
                                    {column.sortName === sortByField && sortBy === "DESC" ? <ArrowUpIcon width={12} /> : <ArrowDownIcon width={12} />}
                                </span>}
                        </div>
                    ))}
                    <div className={`flex space-x-1 grow`}>
                    </div>
                </div>
                <div>
                    {items && items.map((item, index) => {
                        return (
                            <div key={"user-table-row-" + index} className={`flex w-full min-w-min px-8 py-5 border-b-[1.5px] border-b-slate-200 relative ${item.isDefault ? 'bg-gray-200' : ''}`}>
                                {columns.map((column, indexY) => (
                                    <div key={"cell-container-" + index + "-" + indexY} className={`flex w-[50%] ${column.width && "md:w-[" + column.width + "%]"} items-center min-w-[210px]`}>
                                        {generateCell(column, item)}
                                    </div>
                                ))}
                                <div className={`flex space-x-4 items-center justify-end ${actionsStyle}`}>
                                    {tableActions && tableActions.map(action => (
                                        (!action.show || action.show(item)) && (
                                            action.generate ?
                                                action.generate(item) :
                                                <span title={action.title} onClick={() => handleActionClick(action, item, index)} className="text-gray-700 cursor-pointer hover:text-gray-500">
                                                    {action.icon}
                                                </span>
                                        )
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <TablePagination
                page={page}
                setPage={setPage}
                totalPages={totalPages}
            />
        </div>
    );
}

export default RichTable;

export const RICH_TABLE_DYNAMICLY_GENERATED_CLASSES =
    'md:[w-1%]' |
    'md:[w-2%]' |
    'md:[w-3%]' |
    'md:[w-3%]' |
    'md:[w-4%]' |
    'md:[w-5%]' |
    'md:[w-6%]' |
    'md:[w-7%]' |
    'md:[w-8%]' |
    'md:[w-9%]' |
    'md:[w-10%]' |
    'md:[w-11%]' |
    'md:[w-12%]' |
    'md:[w-13%]' |
    'md:[w-14%]' |
    'md:[w-15%]' |
    'md:[w-16%]' |
    'md:[w-17%]' |
    'md:[w-18%]' |
    'md:[w-19%]' |
    'md:[w-20%]' |
    'md:[w-21%]' |
    'md:[w-22%]' |
    'md:[w-23%]' |
    'md:[w-24%]' |
    'md:[w-25%]' |
    'md:[w-26%]' |
    'md:[w-27%]' |
    'md:[w-28%]' |
    'md:[w-29%]' |
    'md:[w-30%]' |
    'md:[w-31%]' |
    'md:[w-32%]' |
    'md:[w-33%]' |
    'md:[w-34%]' |
    'md:[w-35%]' |
    'md:[w-36%]' |
    'md:[w-37%]' |
    'md:[w-38%]' |
    'md:[w-39%]' |
    'md:[w-40%]' |
    'md:[w-41%]' |
    'md:[w-42%]' |
    'md:[w-43%]' |
    'md:[w-44%]' |
    'md:[w-45%]' |
    'md:[w-46%]' |
    'md:[w-47%]' |
    'md:[w-48%]' |
    'md:[w-49%]' |
    'md:[w-50%]' |
    'md:[w-51%]' |
    'md:[w-52%]' |
    'md:[w-53%]' |
    'md:[w-54%]' |
    'md:[w-55%]' |
    'md:[w-56%]' |
    'md:[w-57%]' |
    'md:[w-58%]' |
    'md:[w-59%]' |
    'md:[w-60%]' |
    'md:[w-61%]' |
    'md:[w-62%]' |
    'md:[w-63%]' |
    'md:[w-64%]' |
    'md:[w-65%]' |
    'md:[w-66%]' |
    'md:[w-67%]' |
    'md:[w-68%]' |
    'md:[w-69%]' |
    'md:[w-70%]' |
    'md:[w-71%]' |
    'md:[w-72%]' |
    'md:[w-73%]' |
    'md:[w-74%]' |
    'md:[w-75%]' |
    'md:[w-76%]' |
    'md:[w-77%]' |
    'md:[w-78%]' |
    'md:[w-79%]' |
    'md:[w-80%]' |
    'md:[w-81%]' |
    'md:[w-82%]' |
    'md:[w-83%]' |
    'md:[w-84%]' |
    'md:[w-85%]' |
    'md:[w-86%]' |
    'md:[w-87%]' |
    'md:[w-88%]' |
    'md:[w-89%]' |
    'md:[w-90%]' |
    'md:[w-91%]' |
    'md:[w-92%]' |
    'md:[w-93%]' |
    'md:[w-94%]' |
    'md:[w-95%]' |
    'md:[w-96%]' |
    'md:[w-97%]' |
    'md:[w-98%]' |
    'md:[w-99%]' |
    'md:[w-100%]'
    ;