import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../store/AuthContext";
import { isSuccess } from "../../shared/util";
import activateApi from "../../services/apis/activateApi";
import AppLoader from "../../components/loader/AppLoader";
import { DataContext } from "../../store/DataContext";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import colors from "../../shared/colors";

const Activate = () => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const dataContext = useContext(DataContext);

    const [activationInProgress, setActivationInProgress] = useState(true);
    const activateService = activateApi();
    const [invalidToken, setInvalidToken] = useState(false);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        activateAccount();
    }, [])

    const activateAccount = async () => {
        const token = searchParams.get('access');
        const activateAccountResponse = await activateService.getAll({
            params: {
                'access': token,
            }
        });

        if (!isSuccess(activateAccountResponse.status)) {
            setInvalidToken(true);
            setActivationInProgress(false);
            return;
        }

        authContext.login(activateAccountResponse.data);
        await authContext.fetchClientData(activateAccountResponse.data);
        await dataContext.fetchData();
        navigate("/reset-password", { state: { activate: true } });
    }

    if (activationInProgress) {
        return <AppLoader />
    }

    if (!activationInProgress && invalidToken) {
        return (
            <div className="flex flex-col items-center justify-center w-screen h-screen">
                <div className="text-gray-800 w-[600px] text-center w-max-full text-lg">
                    <p className="flex flex-col text-lg text-center text-gray-800">
                        The invitation token you provided is not valid. Please double-check the token or contact the sender for a valid invitation.
                    </p>
                    <div className="flex items-center justify-center w-full mt-6 space-x-2">
                        <ArrowLeftIcon width={22} color={colors.coyoteb} />
                        <Link to="/login" className="underline text-coyoteb">Back to Login</Link>
                    </div>
                </div>
            </div>
        );
    }

    return null;
}

export default Activate;