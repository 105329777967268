import { ApiCore } from "../utilities/core";

const clientApi = () => {
    return new ApiCore({
        getAll: true,
        getSingle: true,
        post: true,
        put: true,
        patch: true,
        remove: true,
        url: 'clients'
    });
}

export default clientApi;