import { BrowserRouter, Route, Routes } from "react-router-dom";
import Users from "./pages/Users/Users";
import LogIn from "./pages/Auth/LogIn";
import 'react-perfect-scrollbar/dist/css/styles.css';
import Clients from "./pages/Users/Clients";
import Dashboard from "./pages/Dashboard/Dashboard";
import SidebarMenuContextProvider from "./store/SidebarMenuContext";
import { AuthContext } from "./store/AuthContext";
import ProtectedRoute from "./shared/ProtectedRoute";
import { useContext, useEffect, useState } from "react";
import sessionApi from "./services/apis/sessionApi";
import { getToken, isSuccess } from "./shared/util";
import AppLoader from "./components/loader/AppLoader";
import Cases from "./pages/Cases/Cases";
import Activate from "./pages/Auth/Activate";
import AccountSetup from "./pages/Auth/AccountSetup";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Programs from "./pages/Programs/Programs";
import ManageDocuments from "./pages/Programs/ManageDocuments";
import ManageQuestionnaire from "./pages/Programs/ManageQuestionnaire";
import ClientQuestionnaire from "./pages/Questionnaire/ClientQuestionnaire";
import ResetPassword from "./pages/Auth/ResetPassword";
import "react-datepicker/dist/react-datepicker.css";
import ClientBoard from "./pages/ClientBoard/ClientBoard";
import { DataContext } from "./store/DataContext";
import Documents from "./pages/ClientBoard/Documents";
import CaseDetails from "./pages/Cases/CaseDetails";
import Support from "./pages/Support/Support";
import "react-datetime/css/react-datetime.css";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Forms from "./pages/Forms/Forms";
import EditForm from "./pages/Forms/EditForm";
import EditApplicationDocuments from "./pages/Cases/EditApplicationDocuments";
import RequestNewDocuments from "./pages/Cases/RequestNewDocuments";
import PublicQuestionnaire from "./pages/PublicQuestionnaire/PublicQuestionnaire";
import NotFound from "./pages/Errors/NotFound";
import EditApplicationQuestionnaire from "./pages/Cases/EditApplicationQuestionnaire";
import FillOutQuestionnaire from "./pages/Cases/FillOutQuestionnaire";

function App() {
  const [loading, setLoading] = useState(true);
  const sessionService = sessionApi();
  const authContext = useContext(AuthContext);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    removeCookies();
    const token = getToken();

    if (!token && authContext.session) {
      authContext.logout();
    }

    if (!authContext.session && token) {
      fetchSession(token);
    } else {
      setLoading(false);
    }
  }, []);

  const removeCookies = () => {
    if (!document.__defineGetter__) {
      Object.defineProperty(document, 'cookie', {
        get: function () { return '' },
        set: function () { return true },
      });
    } else {
      document.__defineGetter__("cookie", function () { return ''; });
      document.__defineSetter__("cookie", function () { });
    }
  }

  const fetchSession = async (token) => {
    const getSessionResponse = await sessionService.getSingle(token, { headers: { 'Authorization': `Bearer ${token}` } });

    if (isSuccess(getSessionResponse.status)) {
      const session = { ...getSessionResponse.data, token: token };
      authContext.login(session);
      await authContext.fetchClientData(session);
      await dataContext.fetchData();
    } else {
      authContext.logout();
      window.location.replace('/login');
    }

    setLoading(false);
  };

  const getDefaultRoute = () => {
    if (!authContext.session) {
      return null;
    }

    switch (authContext.session.role) {
      case "admin": {
        return <Users />;
      }
      case "sales": {
        return <Clients />
      }
      case "agent": {
        return <Cases />
      }
      case "compliance": {
        return <Cases />
      }
      default: {
        return null;
      }
    }
  }

  if (loading) {
    return <AppLoader />;
  }

  return (
    <SidebarMenuContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={authContext.session ?
            <ProtectedRoute>
              {getDefaultRoute()}
            </ProtectedRoute> :
            <LogIn />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/activate" element={<Activate />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/questionnaires/:id" element={<PublicQuestionnaire />} />

          <Route path="/not-found" element={<NotFound />} />

          <Route path="/account-setup" element={
            <ProtectedRoute>
              <AccountSetup />
            </ProtectedRoute>
          } />

          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />

          <Route path="/users" element={
            <ProtectedRoute roles={["admin"]}>
              <Users />
            </ProtectedRoute>
          } />

          <Route path="/clients" element={
            <ProtectedRoute roles={["admin", "sales"]}>
              <Clients />
            </ProtectedRoute>
          } />

          <Route path="/forms" element={
            <ProtectedRoute roles={["admin"]}>
              <Forms />
            </ProtectedRoute>
          } />

          <Route path="/forms/:id/edit" element={
            <ProtectedRoute roles={["admin"]}>
              <EditForm />
            </ProtectedRoute>
          } />

          <Route path="/applications" element={
            <ProtectedRoute roles={["admin", "agent", "sales", "compliance"]}>
              <Cases />
            </ProtectedRoute>
          } />

          <Route path="/applications/:id" element={
            <ProtectedRoute roles={["admin", "agent", "sales", "compliance"]}>
              <CaseDetails />
            </ProtectedRoute>
          } />

          <Route path="/applications/:id/request-documents" element={
            <ProtectedRoute roles={["admin", "agent"]}>
              <RequestNewDocuments />
            </ProtectedRoute>
          } />

          <Route path="/applications/:id/documents" element={
            <ProtectedRoute roles={["admin", "agent"]}>
              <EditApplicationDocuments />
            </ProtectedRoute>
          } />


          <Route path="/applications/:applicationId/questionnaires/:id" element={
            <ProtectedRoute roles={["admin", "agent"]}>
              <EditApplicationQuestionnaire />
            </ProtectedRoute>
          } />

          <Route path="/applications/:applicationId/questionnaires/:id/details" element={
            <ProtectedRoute roles={["admin", "agent"]}>
              <FillOutQuestionnaire />
            </ProtectedRoute>
          } />

          <Route path="/board" element={
            <ProtectedRoute roles={["client"]}>
              <ClientBoard />
            </ProtectedRoute>
          } />

          <Route path="/programs" element={
            <ProtectedRoute roles={["admin"]}>
              <Programs />
            </ProtectedRoute>
          } />

          <Route path="/programs/:programId/documents" element={
            <ProtectedRoute roles={["admin"]}>
              <ManageDocuments />
            </ProtectedRoute>
          } />

          <Route path="/programs/:programId/questionnaire" element={
            <ProtectedRoute roles={["admin"]}>
              <ManageQuestionnaire />
            </ProtectedRoute>
          } />

          <Route path="/questionnaire" element={
            <ProtectedRoute roles={["client"]}>
              <ClientQuestionnaire />
            </ProtectedRoute>
          } />

          <Route path="/documents" element={
            <ProtectedRoute roles={["client"]}>
              <Documents />
            </ProtectedRoute>
          } />

          <Route path="/support" element={
            <ProtectedRoute roles={["admin", "sales", "agent"]}>
              <Support />
            </ProtectedRoute>
          } />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </SidebarMenuContextProvider>
  );
}

export default App;
