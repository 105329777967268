import { Link } from "react-router-dom";
import Spinner from "../icons/Spinner";

export const SECONDARY_BUTTON_DYNAMICLY_GENERATED_CLASSES =
    'py-2'  |
    'py-3' |
    'py-4'
;

const SecondaryButton = ({text, lIcon, rIcon, onClick, to, className, size, disabled, spinning}) => {
    const getPY = () => {
        switch(size) {
            case "sm": {
                return "py-2";
            }
            case "md": {
                return "py-3";
            }
            case "lg": {
                return "py-4";
            }
           default: {
                return "py-3";
            }
        }
    }

    const getButton = () => {
        return (
            <button 
                type="button"
                onClick={onClick} 
                disabled={disabled}
                className={`flex items-center justify-center w-full px-4 ${getPY()} drop-shadow-sm active:drop-shadow-none space-x-3 font-medium text-gray-700 bg-white border-silver200 rounded-md cursor-pointer border-[1.4px] disabled:opacity-75 disabled:cursor-default disabled:bg-gray-200 hover:bg-gray-100 font-primary`}>
                    {lIcon}
                    <div>{text}</div>
                    {rIcon}
                    {spinning && <Spinner />}
            </button>
        );
    }
    return (
        <div className={className}>
            {onClick ? getButton() : <Link to={to}>{getButton()}</Link>}
        </div>
    );
}

export default SecondaryButton;


SecondaryButton.defaultProps = {
    text: 'Button', 
    size: 'md',
}