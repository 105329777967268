import DropdownField from "../../../components/forms/DropdownField";
import { useContext, useEffect, useState } from "react";
import { changeField, getFirstError } from "../../../shared/validate";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { PlusIcon } from "@heroicons/react/24/outline";
import { isSuccess } from "../../../shared/util";
import programApi from "../../../services/apis/programApi";
import TextField from "../../../components/forms/TextField";
import { CircleFlag } from "react-circle-flags";
import investmentCategoryApi from "../../../services/apis/categoriesApi";
import countryApi from "../../../services/apis/countryApi";
import { DataContext } from "../../../store/DataContext";
import InputField from "../../../components/forms/InputField";
import SSelect from "../../../components/forms/SSelect";

const AddProgramForm = ({ onProgramAdded, selectedCountry, programs }) => {
    const programService = programApi();
    const dataContext = useContext(DataContext);

    const nameDefault = {
        value: '',
        isValid: true,
        isChanged: false,
        showValidation: false,
    };

    const [name, setName] = useState(nameDefault);
    const [country, setCountry] = useState(null);
    const [countries, setCountries] = useState([]);
    const [processLengthInDays, setProcessLengthInDays] = useState(0);

    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [programId, setProgramId] = useState(null);

    const [addProgramInProgress, setAddProgramInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const countryService = countryApi();
    const categoryService = investmentCategoryApi();

    useEffect(() => {
        getCountries(selectedCountry);
        getCategories();
    }, [selectedCountry])

    const getCountries = async (country) => {
        setCountries(dataContext.countries.map(x => {
            return {
                value: x.id,
                display: x.name,
                icon: <CircleFlag countryCode={x.slug} width={22} />
            }
        }));
        setCountry(country);
    }

    const getCategories = async () => {
        const getCategoriesResponse = await categoryService.getAll();
        if (isSuccess(getCategoriesResponse.status)) {

            setCategories(getCategoriesResponse.data.data);
        }
    }

    const resetForm = () => {
        setName(nameDefault);
        setCategoryId(null);
    }

    const handleAddProgramButtonClicked = async () => {
        setErrorMessages([]);
        setAddProgramInProgress(true);

        const addProgramResponse = await programService.post({
            countryId: country,
            investmentCategoryId: categoryId,
            programId: programId ? programId : null,
            name: name.value ? name.value : categories.find(x => x.id === categoryId).name,
            processLengthInDays: processLengthInDays,
        });

        if (isSuccess(addProgramResponse.status)) {
            onProgramAdded(country);
            setAddProgramInProgress(false);
            resetForm();
        } else {
            setErrorMessages([{ message: 'Something went wrong. Please contact system administrator.' }]);
            setAddProgramInProgress(false);
        }
    }

    const isFormValid = () => {
        return country && categoryId;
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <form className="flex flex-col space-y-10" onSubmit={onFormSubmit}>
            <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">From Template</label>
                    <SSelect
                        name='program'
                        placeholder='Template'
                        onChange={(option) => setProgramId(option.value)}
                        value={programId}
                        options={programs ? programs.map(program => ({ value: program.id, display: program.name })) : []}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Country</label>
                    <SSelect
                        key={`country__${country}`}
                        name='country'
                        placeholder='Country'
                        onChange={(option) => setCountry(option.value)}
                        value={country}
                        options={countries}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Program type</label>
                    <SSelect
                        key={`investment_category_id__${categoryId}`}
                        name='investment_category_id'
                        placeholder='Program'
                        onChange={(option) => setCategoryId(option.value)}
                        value={categoryId}
                        options={categories.map(category => { return { value: category.id, display: category.name }; })}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Name (optional)</label>
                    <TextField
                        name='name'
                        placeholder='Name'
                        value={name.value}
                        isValid={true}
                        onChange={(e) => setName(changeField(e, { ...name }))}
                        onBlur={(e) => setName({ ...name, showValidation: true })}
                        errorMessage={name.showValidation ? getFirstError(name) : ''}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Program duration (months)</label>
                    <InputField
                        type='number'
                        name='processLengthInDays'
                        placeholder='Duration'
                        value={processLengthInDays}
                        isValid={true}
                        onChange={(e) => setProcessLengthInDays(e.target.value)}
                    />
                </div>
            </div>
            <div className="">
                <PrimaryButton
                    text="Add Program"
                    icon={<PlusIcon width={24} />}
                    spinning={addProgramInProgress}
                    onClick={handleAddProgramButtonClicked}
                    disabled={!isFormValid() || addProgramInProgress}
                    type="submit"
                />
            </div>
            <div className="flex flex-col pl-2 mt-4 space-y-1">
                {errorMessages && errorMessages.map((errorMessage, index) => (
                    <span key={'add-user-err-message-' + index} className="text-red-600">{errorMessage.message}</span>
                ))}
            </div>
        </form>
    );
}

export default AddProgramForm;