import { ArrowRightIcon } from "@heroicons/react/24/outline";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import PasswordField from "../../components/forms/PasswordField";
import PublicLayout from "../../components/layouts/PublicLayout";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../store/AuthContext";
import userApi from "../../services/apis/userApi";
import { changeField, getFirstError, isValid } from "../../shared/validate";
import { isSuccess, showSuccess } from "../../shared/util";
import resetPasswordApi from "../../services/apis/resetPassword";

const ResetPassword = () => {
    const [password, setPassword] = useState({
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'Password is required.',
                isValid: true,
            },
            {
                type: 'password',
                message: 'Your password must be at least 8 characters long and include at least one symbol, one uppercase letter, one lowercase letter, and one number.',
                isValid: true,
            }
        ]
    });

    const [passwordRepeat, setPasswordRepeat] = useState({
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'Repeat password is required.',
                isValid: true,
            },
            {
                type: 'same_as',
                message: 'Passwords do not match. Please ensure they are identical.',
                isValid: true,
            }
        ]
    });

    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const { state } = useLocation();

    const [saveInProgress, setSaveInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const userService = userApi();
    const resetPasswordService = resetPasswordApi();

    const [searchParams] = useSearchParams();

    const handleSaveButtonClick = async () => {
        if(state && state.activate) {
            await updateUser();
        } else {
            await resetPassword();
        }
    }

    const updateUser = async () => {
        setSaveInProgress(true);
        setErrorMessages([]);

        const updateUserResponse = await userService.patch(authContext.session.userId, {
            password: password.value
        });

        if(!isSuccess(updateUserResponse.status)) {
            setErrorMessages([{ message: 'Something went wrong. Please contact system administrator.' }]);
            setSaveInProgress(false);
            return;
        }
                
        setSaveInProgress(false);
        navigate('/account-setup');
    }

    const resetPassword = async () => {
        setSaveInProgress(true);
        setErrorMessages([]);

        const token = searchParams.get('access');
        const resetPasswordResponse = await resetPasswordService.post({
            password: password.value
        }, { params: { access: token } });

        if(!isSuccess(resetPasswordResponse.status)) {
            setErrorMessages([{ message: 'Something went wrong. Please contact system administrator.' }]);
            setSaveInProgress(false);
            return;
        }
                
        setSaveInProgress(false);
        showSuccess("Your password has been reset.");
        navigate('/login');
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
    }

    const isFormValid = () => {
        return isValid([password, passwordRepeat]);
    }

    return (
        <PublicLayout>
            <form className="text-center md:text-start" onSubmit={onFormSubmit}>
                <div className="flex flex-col mb-8 space-y-3">
                    <h2 className="text-4xl font-medium font-semibold font-primary">Activate account</h2>
                    <p className="text-thin font-primary text-silver600">Welcome! Please set your password.</p>
                </div>

                <div className="flex flex-col mb-6 mb-10 space-y-6">
                    <PasswordField
                        name='password'
                        placeholder='Password'
                        value={password.value}
                        isValid={password.isValid || !password.showValidation}
                        onChange={(e) => setPassword(changeField(e, { ...password }))}
                        onBlur={(e) => setPassword({ ...password, showValidation: true })}
                        errorMessage={password.showValidation ? getFirstError(password) : ''}
                    />
                    <PasswordField
                        name='password_confirmation'
                        placeholder='Repeat password'
                        value={passwordRepeat.value}
                        isValid={passwordRepeat.isValid || !passwordRepeat.showValidation}
                        onChange={(e) => setPasswordRepeat(changeField(e, { ...passwordRepeat }, {...password}))}
                        onBlur={(e) => setPasswordRepeat({ ...passwordRepeat, showValidation: true })}
                        errorMessage={password.showValidation ? getFirstError(passwordRepeat) : ''}
                    />
                </div>

                <div className="w-full">
                    <PrimaryButton
                        text="Save"
                        icon={<ArrowRightIcon color="white" width={24} />}
                        spinning={saveInProgress}
                        onClick={handleSaveButtonClick}
                        disabled={!isFormValid()}
                        type="submit"
                    />
                </div>

                <div className="flex flex-col pl-2 mt-4 space-y-1">
                    {errorMessages && errorMessages.map((errorMessage, index) => (
                        <span key={'login-err-message-' + index} className="text-red-600">{errorMessage.message}</span>
                    ))}
                </div>
            </form>
        </PublicLayout>
    );
}

export default ResetPassword;