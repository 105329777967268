import { ApiCore } from "../utilities/core";

const documentApi = (id, prefix) => {
    return new ApiCore({
        getAll: true,
        getSingle: true,
        post: true,
        put: true,
        patch: true,
        remove: true,
        url: id ? prefix + id + '/documents' : 'documents'
    });
}

export default documentApi;