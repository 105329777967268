import { useContext, useState } from "react";
import SectionListSortable from "../../../components/questionnaire/SectionListSortable";
import QuestionnaireTable from "../../../components/questionnaire/QuestionnaireTable";
import { DataContext } from "../../../store/DataContext";
import QuestionnaireDetailsSection from "../../../components/questionnaire/QuestionnaireDetailsSection";
import { PencilIcon } from "@heroicons/react/24/outline";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import SectionDetailsForm from "../../../components/questionnaire/SectionDetailsForm";
import fieldTypeValueApi from "../../../services/apis/fieldTypeValueApi";
import { isSuccess, showSuccess } from "../../../shared/util";

const FormForm = ({ questionnaire, setQuestionnaire, save, showDetailsForm }) => {
    const [selectedSection, setSelectedSection] = useState(0);
    const [showAddQuestionForm, setShowAddQuestionForm] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [editDetailsFormOpen, setEditDetailsFormOpen] = useState(false);
    const [addSectionFormOpen, setAddSectionFormOpen] = useState(false);
    const [editSectionFormOpen, setEditSectionFormOpen] = useState(false);

    const [confirmSectionDeleteModalShow, setConfirmSectionDeleteModalShow] = useState(false);
    const [confirmSectionDeleteModalData, setConfirmSectionDeleteModalData] = useState(null);

    const [confirmQuestionDeleteModalShow, setConfirmQuestionDeleteModalShow] = useState(false);
    const [confirmQuestionDeleteModalData, setConfirmQuestionDeleteModalData] = useState(null);

    const [name, setName] = useState(questionnaire.name);
    const [description, setDescription] = useState(questionnaire.description);
    const [isDefault, setIsDefault] = useState(false);

    const [sectionName, setSectionName] = useState("");
    const [sectionDescription, setSectionDescription] = useState("");

    const [loadingFieldValues, setLoadingFieldValues] = useState(false);
    const [fieldValues, setFieldValues] = useState([]);

    const dataContext = useContext(DataContext);

    const getSection = () => {
        return questionnaire.sections[selectedSection];
    }

    const getQuestions = () => {
        const tmpSection = getSection();
        if (!tmpSection || !tmpSection.items) {
            return [];
        }

        return tmpSection.items;
    }

    const setItems = (items) => {
        const tmpQuestionnaire = { ...questionnaire };
        tmpQuestionnaire.sections[selectedSection] = { ...tmpQuestionnaire.sections[selectedSection], items: items };

        setQuestionnaire(tmpQuestionnaire);
        save(tmpQuestionnaire);
    }

    const setSectionsUp = (sections) => {
        const tmpQuestionnaire = { ...questionnaire };
        tmpQuestionnaire.sections = sections;

        setQuestionnaire(tmpQuestionnaire);
        save(tmpQuestionnaire);
    }

    const deleteSectionClicked = (section, index) => {
        setConfirmSectionDeleteModalData({
            section: section,
            index: index,
        });
        setConfirmSectionDeleteModalShow(true);
    }

    const deleteQuestionClicked = (question, index) => {
        setConfirmQuestionDeleteModalData({
            question: question,
            index: index,
        });
        setConfirmQuestionDeleteModalShow(true);
    }

    const deleteSection = (data) => {
        if (!questionnaire.sections || questionnaire.sections.length < 2) {
            return;
        }

        const tmpQuestionnaire = { ...questionnaire };
        tmpQuestionnaire.sections.splice(data.index, 1);

        if (data.index === selectedSection) {
            setSelectedSection(0);
        }

        setQuestionnaire(tmpQuestionnaire);
        save(tmpQuestionnaire);

        setConfirmSectionDeleteModalShow(false);
    }

    const onAddQuestionButtonClick = () => {
        setSelectedIndex(null);
        setSelectedQuestion({
            question: '',
            description: '',
            fieldTypeId: dataContext.fieldTypes && dataContext.fieldTypes[0] ? dataContext.fieldTypes[0].id : null,
            isRequired: true,
        });
        setShowAddQuestionForm(true);
    }

    const addQuestion = () => {
        if (!isValid()) {
            return;
        }

        const selectedSection = getSection();
        selectedSection.items.push({ ...selectedQuestion, id: -1, new: true });

        const tmpQuestionnaire = { ...questionnaire };
        tmpQuestionnaire.sections[selectedSection] = selectedSection;

        setQuestionnaire(tmpQuestionnaire);
        resetTable();

        save(tmpQuestionnaire);
    }

    const onEditClicked = (questionId, index) => {
        const question = getQuestions()[index];
        setSelectedQuestion(question);
        setSelectedIndex(index);
        setShowAddQuestionForm(true);
    }

    const addSectionClicked = () => {
        setSectionName('');
        setSectionDescription('');
        setAddSectionFormOpen(true);
    }

    const addNewSection = () => {
        const newSection = {
            name: sectionName,
            description: sectionDescription,
            items: [],
        }

        const tmpQuestionnaire = { ...questionnaire };
        tmpQuestionnaire.sections.push(newSection);

        setQuestionnaire(tmpQuestionnaire);
        save(tmpQuestionnaire);

        setAddSectionFormOpen(false);
        setSelectedSection(tmpQuestionnaire.sections.length - 1);
    }

    const saveSectionDetails = () => {
        const tmpQuestionnaire = { ...questionnaire };
        tmpQuestionnaire.sections[selectedSection] = {
            ...tmpQuestionnaire.sections[selectedSection],
            name: sectionName,
            description: sectionDescription,
        };

        setQuestionnaire(tmpQuestionnaire);
        setEditSectionFormOpen(false);

        save(tmpQuestionnaire);
    }

    const editQuestion = (index) => {
        if (!isValid()) {
            return;
        }

        const selectedSection = getSection();
        selectedSection.items[index] = { ...selectedQuestion, edited: true };

        const tmpQuestionnaire = { ...questionnaire };
        tmpQuestionnaire.sections[selectedSection] = selectedSection;

        setQuestionnaire(tmpQuestionnaire);
        resetTable();

        save(tmpQuestionnaire);
    }

    const deleteQuestion = (data) => {
        const selectedSection = getSection();
        selectedSection.items.splice(data.index, 1);

        const tmpQuestionnaire = { ...questionnaire };
        tmpQuestionnaire.sections[selectedSection] = selectedSection;

        setQuestionnaire(tmpQuestionnaire);
        save(tmpQuestionnaire);
        setConfirmQuestionDeleteModalShow(false);
    }

    const saveDetails = () => {
        const tmpQestionnaire = { ...questionnaire };
        tmpQestionnaire.name = name;
        tmpQestionnaire.description = description;
        tmpQestionnaire.isDefault = isDefault
        setQuestionnaire(tmpQestionnaire);
        setEditDetailsFormOpen(false);

        save(tmpQestionnaire);
    }

    const isValid = () => {
        return selectedQuestion && selectedQuestion.question && selectedQuestion.fieldTypeId;
    }

    const resetTable = () => {
        setShowAddQuestionForm(false);
        setSelectedQuestion(null);
    }

    const openDetailsSection = () => {
        setName(questionnaire.name);
        setDescription(questionnaire.description);
        setIsDefault(questionnaire.isDefault)
        setEditDetailsFormOpen(true);
    }

    const openEditSectionForm = () => {
        setSectionName(getSection().name);
        setSectionDescription(getSection().description);
        setEditSectionFormOpen(true);
    }

    const sectionDetails = () => {
        return (
            <div className='flex flex-col px-8 py-4 space-y-4'>
                {!editSectionFormOpen &&
                    <div className='flex flex-col space-y-1'>
                        <p className='text-xl font-semibold'>{getSection().name}</p>
                        <p className='text-gray-700'>{getSection().description}</p>
                    </div>
                }
                {editSectionFormOpen &&
                    <div className="w-full max-w-[640px]">
                        <SectionDetailsForm
                            name={sectionName}
                            setName={setSectionName}
                            description={sectionDescription}
                            setDescription={setSectionDescription}
                            onClose={() => setEditSectionFormOpen(false)}
                            onSave={saveSectionDetails}
                        />
                    </div>
                }
                {!editSectionFormOpen &&
                    <div onClick={openEditSectionForm} className="flex space-x-2 text-gray-900 cursor-pointer w-fit min-w-min hover:text-gray-600">
                        <PencilIcon className='text-gray-900 cursor-pointer hover:text-gray-600' width={22} />
                        <span className='underline'>Edit details</span>
                    </div>
                }
            </div>
        );
    }

    // const getFieldValues = async (fieldTypeId) => {

    // }

    const addFieldValue = async (fieldTypeId, value) => {
        const fieldTypeValueService = fieldTypeValueApi(fieldTypeId);
        const createFieldTypeValueResponse = await fieldTypeValueService.post({
            key: value,
            value: value,
        });

        if (isSuccess(createFieldTypeValueResponse.status)) {
            showSuccess('Value has been added');
            setFieldValues([...fieldValues].push(createFieldTypeValueResponse.data))
        }
    }

    if (questionnaire.sections && questionnaire.sections.length > 0) return (
        <>
            <div className="flex items-center justify-center h-full md:hidden">
                <p className="text-center">This form is not supported on mobile view. Please use the desktop version.</p>
            </div>
            <div className="flex flex-col hidden space-y-8 md:block">
                {showDetailsForm && <div className='flex flex-col space-y-4'>
                    {!editDetailsFormOpen &&
                        <div className='flex flex-col space-y-1'>
                            <p className='text-xl font-semibold'>{questionnaire.name}</p>
                            <p className='text-gray-700'>{questionnaire.description}</p>
                        </div>
                    }
                    {editDetailsFormOpen &&
                        <div className="bg-white rounded-md w-full max-w-[640px]">
                            <QuestionnaireDetailsSection
                                name={name}
                                setName={setName}
                                description={description}
                                setDescription={setDescription}
                                isDefault={isDefault}
                                setIsDefault={setIsDefault}
                                onClose={() => setEditDetailsFormOpen(false)}
                                onSave={saveDetails}
                            />
                        </div>
                    }
                    {!editDetailsFormOpen &&
                        <div onClick={openDetailsSection} className="flex space-x-2 text-gray-900 cursor-pointer w-fit min-w-min hover:text-gray-600">
                            <PencilIcon className='text-gray-900 cursor-pointer hover:text-gray-600' width={22} />
                            <span className='underline'>Edit details</span>
                        </div>
                    }
                </div>}
                <SectionListSortable
                    sections={questionnaire.sections}
                    setSections={setSectionsUp}
                    selected={selectedSection}
                    setSelectedSection={setSelectedSection}
                    onClick={(section, index) => setSelectedSection(index)}
                    canAdd={true}
                    canDelete={true}
                    onDeleteClicked={deleteSectionClicked}
                    onAddClicked={addSectionClicked}
                />
                {addSectionFormOpen &&
                    <div className="bg-white rounded-md w-full max-w-[640px]">
                        <div className="p-4">
                            <p className="text-lg">Add new section</p>
                        </div>
                        <SectionDetailsForm
                            name={sectionName}
                            setName={setSectionName}
                            description={sectionDescription}
                            setDescription={setSectionDescription}
                            onClose={() => setAddSectionFormOpen(false)}
                            onSave={addNewSection}
                        />
                    </div>
                }
                <div>
                    <QuestionnaireTable
                        items={getQuestions()}
                        onSetItems={(items) => setItems(items)}
                        showAddQuestionForm={showAddQuestionForm}
                        onAddQuestionClick={onAddQuestionButtonClick}
                        onAddQuestionClose={() => setShowAddQuestionForm(false)}
                        question={selectedQuestion}
                        onAddQuestion={addQuestion}
                        onEditClicked={onEditClicked}
                        onChangeQuestion={(question) => { setSelectedQuestion(question) }}
                        onEdit={editQuestion}
                        onDeleteClicked={deleteQuestionClicked}
                        isValid={isValid()}
                        selectedIndex={selectedIndex}
                        sectionDetailsForm={sectionDetails()}
                        loadingFieldValues={loadingFieldValues}
                        fieldValues={fieldValues}
                        addFieldValue={addFieldValue}
                    />
                </div>
            </div>
            <ConfirmModal
                show={confirmSectionDeleteModalShow}
                onClose={() => setConfirmSectionDeleteModalShow(false)}
                onConfirm={deleteSection}
                data={confirmSectionDeleteModalData}
                title="Confirm section delete"
                message="Are you sure you want to delete this section?"
            />
            <ConfirmModal
                show={confirmQuestionDeleteModalShow}
                onClose={() => setConfirmQuestionDeleteModalShow(false)}
                onConfirm={deleteQuestion}
                data={confirmQuestionDeleteModalData}
                title="Confirm question delete"
                message="Are you sure you want to delete this question?"
            />
        </>
    );

    return (
        <>
            <SectionListSortable
                sections={questionnaire.sections}
                setSections={setSectionsUp}
                selected={selectedSection}
                setSelectedSection={setSelectedSection}
                onClick={(section, index) => setSelectedSection(index)}
                canAdd={true}
                canDelete={true}
                onDeleteClicked={deleteSectionClicked}
                onAddClicked={addSectionClicked}
            />
            {addSectionFormOpen &&
                <div className="bg-white rounded-md w-full max-w-[640px] my-4">
                    <div className="p-4">
                        <p className="text-lg">Add new section</p>
                    </div>
                    <SectionDetailsForm
                        name={sectionName}
                        setName={setSectionName}
                        description={sectionDescription}
                        setDescription={setSectionDescription}
                        onClose={() => setAddSectionFormOpen(false)}
                        onSave={addNewSection}
                    />
                </div>
            }
        </>
    )
}

export default FormForm;