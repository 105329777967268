export const applicationMemberRelationTypes = [
  {
    value: "applicant",
    display: "Applicant",
  },
  {
    value: "spouse",
    display: "Spouse",
  },
  {
    value: "dependent16AndOver",
    display: "Adult dependent",
  },
  {
    value: "dependentUnder16",
    display: "Minor dependent",
  },
]