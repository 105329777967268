import { useEffect, useState } from "react";
import DocumentsTable from "./DocumentsTable";
import ConfirmModal from "../modals/ConfirmModal";

const DocumentsForm = ({ documents, onSetDocuments, save, singleSave, memberType, memberId }) => {
    const [showAddDocumentForm, setShowAddDocumentForm] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const [confirmDocumentDeleteModalShow, setConfirmDocumentDeleteModalShow] = useState(false);
    const [confirmDocumentDeleteModalData, setConfirmDocumentDeleteModalData] = useState(null);
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [hiddenDocuments, setHiddenDocuments] = useState([]);

    useEffect(() => {
        setFilteredDocuments(documents?.filter(document => {
            if (memberId == null) {
                return true;
            }
            return document.applicationMemberId === +memberId || document.new;
        }));
        setHiddenDocuments(documents?.filter(document => {
            if (memberId == null) {
                return false;
            }
            return document.applicationMemberId !== +memberId && !document.new;
        }))
    }, [documents, memberId])

    const setDocuments = (tmpDocuments) => {
        onSetDocuments([...hiddenDocuments, ...tmpDocuments])
    }

    const onAddDocumentButtonClick = () => {
        setSelectedDocument({
            name: '',
            description: '',
            applicant: memberType == null || memberType === 'applicant',
            spouse: memberType === 'spouse',
            dependent16AndOver: memberType === 'dependent16AndOver',
            dependentUnder16: memberType === 'dependentUnder16',
        });

        setSelectedIndex(null);
        setShowAddDocumentForm(true);
    }

    const onEditClicked = (index) => {
        const document = filteredDocuments[index]
        setSelectedDocument(document);
        setSelectedIndex(index);
        setShowAddDocumentForm(true);
    }
    const resetTable = () => {
        setShowAddDocumentForm(false);
        setSelectedDocument(null);
    }

    const addDocument = () => {
        if (!isValid()) {
            return;
        }

        const tmpDocuments = [...filteredDocuments];
        tmpDocuments.push({ ...selectedDocument, id: -1, new: true });

        setDocuments(tmpDocuments);
        save(tmpDocuments);

        resetTable();
    }

    const deleteDocument = (index) => {
        const tmpDocuments = [...filteredDocuments];
        tmpDocuments.splice(index, 1);

        setDocuments(tmpDocuments);
        if (!singleSave) {
            save(tmpDocuments);
        }

        setConfirmDocumentDeleteModalShow(false);
    }

    const editDocument = (index) => {
        if (!isValid()) {
            return;
        }

        const tmpDocuments = [...filteredDocuments];
        tmpDocuments[index] = { ...selectedDocument, edited: true };


        setDocuments(tmpDocuments);

        save(tmpDocuments);
        resetTable();
    }

    const deleteDocumentClicked = (index) => {
        setConfirmDocumentDeleteModalData(index);
        setConfirmDocumentDeleteModalShow(true);
    }

    const onSetItems = (items) => {
        setDocuments(items);
        if (!singleSave) {
            save(items);
        }
    }

    const isValid = () => {
        return selectedDocument && selectedDocument.name
            && (selectedDocument.applicant
                || selectedDocument.spouse
                || selectedDocument.dependent16AndOver
                || selectedDocument.dependentUnder16);
    }

    return (
        <>
            {filteredDocuments &&
                <DocumentsTable
                    items={filteredDocuments}
                    onSetItems={onSetItems}
                    showAddDocumentForm={showAddDocumentForm}
                    onAddDocumentClick={onAddDocumentButtonClick}
                    onAddDocumentClose={() => setShowAddDocumentForm(false)}
                    onAddDocument={addDocument}
                    document={selectedDocument}
                    onChangeDocument={(document) => { setSelectedDocument(document) }}
                    onEditClicked={onEditClicked}
                    isValid={isValid()}
                    onEdit={editDocument}
                    onDeleteClicked={deleteDocumentClicked}
                    selectedIndex={selectedIndex}
                    memberType={memberType}
                    memberId={memberId}
                />
            }
            <ConfirmModal
                show={confirmDocumentDeleteModalShow}
                onClose={() => setConfirmDocumentDeleteModalShow(false)}
                onConfirm={deleteDocument}
                data={confirmDocumentDeleteModalData}
                title="Confirm document delete"
                message="Are you sure you want to delete this document?"
            />
        </>
    );
}

export default DocumentsForm;