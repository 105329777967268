import { ApiCore } from "../utilities/core";

const conversationApi = () => {
    return new ApiCore({
        getAll: true,
        getSingle: true,
        post: true,
        put: false,
        patch: true,
        remove: false,
        url: 'chat'
    });
}

export default conversationApi;