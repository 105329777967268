import { ArrowRightOnRectangleIcon, ChevronDownIcon, ChevronUpIcon, UserIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../store/AuthContext";
import { getInitials } from "../../shared/util";
import colors from "../../shared/colors";
import { useOutsideClick } from "../../hooks/CustomHooks";
import { UIContext } from "../../store/UIContext";

const UserAccountDropdown = () => {
  const contentRef = useRef();
  const [open, setOpen] = useState(false);
  const ref = useOutsideClick(() => closeMenu(), contentRef);
  const authContext = useContext(AuthContext);
  const uiContext = useContext(UIContext);

  const getContent = () => {
    return (
        <div ref={contentRef} className="w-screen md:w-[230px] p-2">
          {/* <li className="flex items-center px-3 py-3 space-x-2 cursor-pointer hover:bg-gray-100 text-slate-900">
            <UserIcon width={22} />
            <button>Profile</button>
          </li> */}
          <li onClick={authContext.logout} className="flex items-center px-3 py-3 space-x-2 cursor-pointer hover:bg-gray-100 text-slate-900">
            <ArrowRightOnRectangleIcon width={22} />
            <button>Sign out</button>
          </li>
        </div>
    );
  }

  const openMenu = () => {
    uiContext.setAccountDropdownContent(getContent());
    uiContext.setShowAccountDropdown(true);
    setOpen(true);
  }

  const closeMenu = () => {
    uiContext.setAccountDropdownContent(null);
    uiContext.setShowAccountDropdown(false);
    setOpen(false);
  }

  const onProfileClick = () => {
    if(uiContext.showAccountDropdown) {
      closeMenu();
    } else {
      openMenu();
    }
  }

  useEffect(() => {
    return(() => {
      closeMenu()
    });
  }, []);

  const getName = () => {
    return authContext.session.role === 'client' && authContext.session.clients && authContext.session.clients.length > 0 
     ? authContext.session.clients[0].firstName + " " + authContext.session.clients[0].lastName
     : authContext.session.firstName && authContext.session.lastName 
     ? authContext.session.firstName + " " + authContext.session.lastName 
     : authContext.session.email;
  }

  return (
    <div className="relative" ref={ref}>
      <div className="flex items-center space-x-2 cursor-pointer" onClick={onProfileClick}>
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-slate-100">
          <span className="font-medium text-slate-900">{getInitials(getName())}</span>
        </div>
        <p className="hidden font-medium text-gray-900 md:block">{getName()}</p>
        {open ?
          <ChevronUpIcon className="hidden md:block" width={24} height={24} color={colors.silver600} /> :
          <ChevronDownIcon className="hidden md:block" width={24} height={24} color={colors.silver600} />}
      </div>
    </div>
  );
}

export default UserAccountDropdown;