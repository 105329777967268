import { DndContext } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import SectionItem from "./SectionItem";
import { PlusIcon } from "@heroicons/react/24/outline";

const SectionListSortable = ({ sections, setSections, selected, onClick, canAdd, canDelete, onDeleteClicked, onAddClicked, setSelectedSection }) => {
    const reorderSectionList = (e) => {
        if(!e.over) {
            return;
        }

        if(e.active.id !== e.over.id) {
            const oldIndex = sections.findIndex(x => x.id === e.active.id);
            const newIndex = sections.findIndex(x => x.id === e.over.id);

            const tmpSections = arrayMove(sections, oldIndex, newIndex);

            if(newIndex === selected) {
                setSelectedSection(oldIndex);
            }
            if(oldIndex === selected) {
                setSelectedSection(newIndex);
            }

            setSections(tmpSections);
        }
    }

    return (
        <DndContext onDragEnd={reorderSectionList}>
          <SortableContext items={sections}>
            <div className="flex space-x-2 h-[120px]">
                {sections.map((section, index) => (
                    <SectionItem 
                        key={section.id} 
                        section={section}
                        selected={selected === index}
                        onClick={() => onClick(section, index)}
                        canDelete={canDelete}
                        onDeleteClicked={() => onDeleteClicked(section, index)}
                    >
                    </SectionItem>
                ))}
                {canAdd && <div title="Add new section" 
                    className="flex items-center justify-center h-full bg-white border rounded shadow-sm cursor-pointer aspect-square hover:bg-gray-50"
                    onClick={onAddClicked}>
                    <PlusIcon width={52} className="text-gray-700" />
                </div>}
             </div>
          </SortableContext>
        </DndContext>
      );
}

export default SectionListSortable;