import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { ArrowsUpDownIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import DocumentRelatedFileList from "./DocumentRelatedFileList";
import { useState } from "react";

const DocumentItem = ({ document, index, showAddDocumentForm, onEditClicked, onDeleteClicked, memberType }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: document.id });

    const memberStatus = (status) => {
        return status ? (<CheckIcon className='text-green-600' width={22} />) : (<XMarkIcon className='text-red-600' width={22} />);
    }

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return `${text.substring(0, maxLength)}...`;
        }
        return text;
    };

    return (
        <div>
            <div className={`flex w-full px-8 py-4 bg-gray-100 min-w-min rounded-t-md border-b-[1.5px] border-b-slate-200`}
                key={document.id}
                ref={setNodeRef}
                style={{
                    transform: CSS.Transform.toString(transform),
                    transition: transition
                }}>
                <div className="flex space-x-2 md:mr-3 md:w-[2%] items-center">
                    {!showAddDocumentForm &&
                        <ArrowsUpDownIcon  {...listeners} {...attributes} width={20} className="text-gray-600 cursor-pointer outline-0" />
                    }
                </div>
                <div className="flex min-w-[30%] md:w-[30%] items-center">
                    <span>{document.name}</span>
                </div>
                <div className="flex min-w-[30%] space-x-2 md:w-[30%] relative"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <span className="text-lg md:text-base whitespace-pre-line">
                    {truncateText(document.description, 200)}
                </span>
                {
                showTooltip && <span
                    className="absolute z-10 text-lg md:text-base whitespace-pre-line bg-gray-200 border border-gray-300 p-2 rounded shadow cursor-pointer"
                    style={{ zIndex: 10 }}
                >
                    {document.description}
                </span>
                }
            </div>
                {memberType == null &&
                    <>
                        <div className="flex min-w-[7%] space-x-2 justify-center mx-2">
                            <div >{memberStatus(document.applicant)}</div>
                        </div>
                        <div className="flex min-w-[7%] space-x-2 justify-center mx-2">
                            <div >{memberStatus(document.spouse)}</div>
                        </div>
                        <div className="flex min-w-[7%] space-x-2 justify-center mx-2">
                            <div >{memberStatus(document.dependent16AndOver)}</div>
                        </div>
                        <div className="flex min-w-[7%] space-x-2 justify-center mx-2">
                            <div >{memberStatus(document.dependentUnder16)}</div>
                        </div>
                    </>
                }

                <div className="flex min-w-[5%] space-x-4 md:w-[5%] items-center justify-center">
                    <PencilIcon onClick={() => onEditClicked(index, index)}
                        className='text-gray-900 cursor-pointer hover:text-gray-600' width={22} />
                    <TrashIcon className='text-gray-900 cursor-pointer hover:text-gray-600' onClick={onDeleteClicked} width={22} />
                </div>
            </div>
            <div className="m-4">
                <DocumentRelatedFileList document={document} canEdit={true} />
            </div>
        </div>
    );
}

export default DocumentItem;