import { createContext, useEffect, useState } from "react";
import { languages as languageList } from "../data/languages";
import { industries as industryList } from "../data/industries";
import { sourceOfDiscoveries as sourceOfDiscoveryList } from "../data/sourceOfDiscoveries";
import { budgets as budgetList } from "../data/budgets";
import { programs as programList } from "../data/programs";
import countryApi from "../services/apis/countryApi";
import fieldTypeApi from "../services/apis/fieldTypeApi";
import { isSuccess, sortbyProperty } from "../shared/util";
import { maritalStatuses as maritalStatusList } from "../data/maritalStatus";
import { genders as genderList } from "../data/gender";

export const DataContext = createContext({
    countries: null,
    fieldTypes: null,
    languages: null,
    industries: null,
    sourceOfDiscoveries: null,
    budgests: null,
    programs: null,
    genders: null,
    maritalStatuses: null,
});

const DataContextProvider = ({ children }) => {
    const [countries, setCountries] = useState(null);
    const [fieldTypes, setFieldTypes] = useState(null);
    const [languages, setLanguages] = useState(null);
    const [industries, setIndustries] = useState(null);
    const [sourceOfDiscoveries, setSourceOfDiscoveries] = useState(null);
    const [budgets, setBudgets] = useState(null);
    const [programs, setPrograms] = useState(null);
    const [genders, setGenders] = useState(null);
    const [maritalStatuses, setMaritalStatuses] = useState(null);

    const countryService = countryApi();
    const fieldTypeService = fieldTypeApi();

    useEffect(() => {
        setLanguages(languageList);
        setIndustries(industryList);
        setSourceOfDiscoveries(sourceOfDiscoveryList);
        setBudgets(budgetList);
        setPrograms(programList);
        setGenders(genderList);
        setMaritalStatuses(maritalStatusList);
    }, [])

    const getFieldType = (id) => {
        return fieldTypes.find(x => x.id === id);
    }

    const getDropdownFieldTypes = () => fieldTypes.map(x => { return { value: x.id, display: x.name } })

    const fetchData = async () => {
        await fetchCountries();
        await fetchFieldTypes();
    }

    const fetchCountries = async () => {
        const getCountriesResponse = await countryService.getAll({ params: { "limit": 0 } });
        if (isSuccess(getCountriesResponse.status)) {
          setCountries(sortbyProperty(getCountriesResponse.data.data, 'id'));
        }
      }
    
      const fetchFieldTypes = async () => {
        const getFieldTypesResponse = await fieldTypeService.getAll({ params: { "limit": 0 } });
        if (isSuccess(getFieldTypesResponse.status)) {
          setFieldTypes(sortbyProperty(getFieldTypesResponse.data.data, 'id'));
        }
      }

    const value = {
        countries: countries,
        fieldTypes: fieldTypes,
        languages: languages,
        industries: industries,
        sourceOfDiscoveries: sourceOfDiscoveries,
        budgets: budgets,
        programs: programs,
        genders: genders,
        maritalStatuses: maritalStatuses,
        setCountries: setCountries,
        setFieldTypes: setFieldTypes,
        getFieldType: getFieldType,
        setLanguages: setLanguages,
        setIndustries: setIndustries,
        getDropdownFieldTypes: getDropdownFieldTypes,
        fetchData: fetchData,
    };

    return (
        <DataContext.Provider value={value}>{children}</DataContext.Provider>
    );
}

export default DataContextProvider;