import { XMarkIcon, HomeIcon, UsersIcon, FlagIcon, DocumentTextIcon, ChatBubbleOvalLeftIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import LogoWhite from '../images/logo-white.svg';
import PerfectScrollbar from 'react-perfect-scrollbar'
import SidebarItem from "./SidebarItem";
import { useContext } from "react";
import { SidebarMenuContext } from "../../store/SidebarMenuContext";
import ProtectedElement from "../../shared/ProtectedElement";

const Sidebar = ({menuVisible, setMenuVisible}) => {
    const sidebarMenuContext = useContext(SidebarMenuContext);

    const isActive = (link) => {
        const activePath = window.location.pathname.split('/')[1];
        return link && activePath === link.replace('/', '');
    }
    
    return (
        <div className={`md:visible sidebar overflow-y-hidden absolute top-0 pb-20 bottom-0 lg:left-0 w-[78%] md:w-[320px] overflow-y-auto text-center bg-dirt z-[14] ease-in-out duration-300 md:translate-x-0 ${menuVisible ? 'translate-x-0' : '-translate-x-full invisible'}`}>
                <div className="flex items-center w-full h-20 px-12 bg-dirt200">
                    <img width={92} src={LogoWhite} alt="Soland" />
                    <XMarkIcon className="absolute cursor-pointer md:collapse right-4" width={32} color="white" onClick={() => setMenuVisible(false)} />
                </div>
                <PerfectScrollbar className="pb-4">
                    <div className="flex flex-col px-3 mt-10 space-y-1">
                        {/* <SidebarItem 
                            title="Dashboard"
                            link="/dashboard"
                            isActive={isActive("/dashboard")}
                            icon={<HomeIcon width={22} />}
                        /> */}
                        <ProtectedElement roles={["admin", "sales"]}>
                            <SidebarItem 
                                title="User management"
                                icon={<UsersIcon width={22} />}
                                showSubitems={sidebarMenuContext.userManagementShowSubItems}
                                setShowSubitems={sidebarMenuContext.setUserManagementShowSubItems}
                                subitems={[
                                    {
                                        title: "Users",
                                        link: "/users",
                                        isActive: isActive("/users"),
                                        protected: ["admin"]
                                    },
                                    {
                                        title: "Clients",
                                        link: "/clients",
                                        isActive: isActive("/clients"),
                                        protected: ["admin", "sales"]
                                    }
                                ]}
                            />
                        </ProtectedElement>
                        <ProtectedElement roles={["admin", "agent", "sales"]}>
                            <SidebarItem 
                                title="Applications"
                                link="/applications"
                                isActive={isActive("/applications")}
                                icon={<DocumentDuplicateIcon width={22} />}
                            />
                        </ProtectedElement>
                        <ProtectedElement roles={["admin"]}>
                            <SidebarItem 
                                title="Programs"
                                link="/programs"
                                isActive={isActive("/programs")}
                                icon={<FlagIcon width={22} />}
                            />
                        </ProtectedElement>
                        <ProtectedElement roles={["admin"]}>
                            <SidebarItem 
                                title="Forms"
                                link="/forms"
                                isActive={isActive("/forms")}
                                icon={<DocumentTextIcon width={22} />}
                            />
                        </ProtectedElement>
                        <ProtectedElement roles={["admin", "sales", "agent"]}>
                            <SidebarItem 
                                title="Support"
                                link="/support"
                                isActive={isActive("/support")}
                                icon={<ChatBubbleOvalLeftIcon width={22} />}
                            />
                        </ProtectedElement>
                    </div>
                </PerfectScrollbar>
            </div>
    );
}

export default Sidebar;