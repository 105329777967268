import { useContext, useState } from "react";
import PublicLayout from "../../components/layouts/PublicLayout";
import PasswordField from "../../components/forms/PasswordField";
import EmailField from "../../components/forms/EmailField";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { changeField, getFirstError, isValid } from "../../shared/validate";
import { Link, useNavigate } from "react-router-dom";
import sessionApi from "../../services/apis/sessionApi";
import { isSuccess } from "../../shared/util";
import { AuthContext } from "../../store/AuthContext";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import caseApi from "../../services/apis/caseApi";
import clientApi from "../../services/apis/clientApi";
import { DataContext } from "../../store/DataContext";

const LogIn = () => {
    const [email, setEmail] = useState({
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'Email address is required.',
                isValid: true,
            },
            {
                type: 'email',
                message: 'Please provide a valid email address.',
                isValid: true,
            }
        ],
    });

    const [password, setPassword] = useState({
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'Password is required.',
                isValid: true,
            },
        ]
    });

    const [loginInProgress, setLoginInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [error, setError] = useState(false);

    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const dataContext = useContext(DataContext);

    const sessionService = sessionApi();

    const handleLoginButtonClick = async () => {
        setLoginInProgress(true);
        setErrorMessages([]);
        setError(false);

        const createSessionResponse = await sessionService.post({
            username: email.value,
            password: password.value,
        });

        if(!isSuccess(createSessionResponse.status)) {
            const errorMessage = createSessionResponse.data && createSessionResponse.data.message ? createSessionResponse.data.message
                : 'Something went wrong. Please contact system administrator.';
             
            setError(true);    
            setErrorMessages([{ message: errorMessage }]);
            setLoginInProgress(false);
            return;
        }

        authContext.login(createSessionResponse.data);
        await authContext.fetchClientData(createSessionResponse.data);
        await dataContext.fetchData();

        setLoginInProgress(false);
        navigate('/');
    }

    const onFormSubmit = (e) => {
        e.preventDefault(); 
    }

    const isFormValid = () => {
        return isValid([email, password]);
    }

    return (
        <PublicLayout>
            <form className="text-center md:text-start" onSubmit={onFormSubmit}>
                <div className="flex flex-col mb-8 space-y-3">
                    <h2 className="text-4xl font-medium font-semibold font-primary">Log in</h2>
                    <p className="text-thin font-primary text-silver600">Welcome back! Please provide your informations.</p>
                </div>

                <div className="flex flex-col mb-6 mb-10 space-y-6">
                    <EmailField
                        name='email'
                        placeholder='Email'
                        value={email.value}
                        isValid={email.isValid || !email.showValidation}
                        onChange={(e) => setEmail(changeField(e, { ...email }))}
                        onBlur={(e) => setEmail({ ...email, showValidation: true })}
                        errorMessage={email.showValidation ? getFirstError(email) : ''}
                    />
                    <PasswordField
                        name='password'
                        placeholder='Password'
                        value={password.value}
                        isValid={password.isValid || !password.showValidation}
                        onChange={(e) => setPassword(changeField(e, { ...password }))}
                        onBlur={(e) => setPassword({ ...password, showValidation: true })}
                        errorMessage={password.showValidation ? getFirstError(password) : ''}
                    />
                </div>

                <div className="w-full">
                    <PrimaryButton
                        text="Log in"
                        icon={<ArrowRightIcon color="white" width={24} />}
                        spinning={loginInProgress}
                        onClick={handleLoginButtonClick}
                        disabled={!isFormValid()}
                        type="submit"
                    />
                </div>

                <div className="flex justify-end w-full mt-2">
                    <Link to="/forgot-password" className="underline text-coyoteb">Forgot password?</Link>
                </div>

                <div className="flex flex-col pl-2 mt-4 space-y-1">
                    {error && 
                        <span className="text-red-600">Username and password incorrect</span>
                    }
                </div>
            </form>
        </PublicLayout>
    );
}

export default LogIn;