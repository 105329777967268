import { useContext, useEffect } from "react";
import ClientLayout from "../../components/layouts/ClientLayout";
import { AuthContext } from "../../store/AuthContext";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import WaitingForPayment from "../../components/icons/WaitingForPayment";
import StatusTag from "../../components/tags/StatusTag";
import { ArrowDownOnSquareIcon, CheckCircleIcon, DocumentIcon, DocumentTextIcon, XCircleIcon } from "@heroicons/react/24/outline";
import axiosClient from "../../shared/axiosClient";
import { downloadA, isSuccess } from "../../shared/util";
import colors from "../../shared/colors";
import AppLoader from "../../components/loader/AppLoader";

const ClientBoard = () => {
    const authContext = useContext(AuthContext);

    const inactiveStatuses = ["Waiting for payment", "Waiting for agent assignment"];
    const activeStatuses = ["Changes required", "Compliance completed investigation"];
    const acceptedStatsues = ["Approved"];
    const rejectedStatuses = ["Rejected"];

    if(!authContext.application || !authContext.questionnaire) {
        return <AppLoader />;
    }

    const status = authContext.application.status;

    const downloadContract = async () => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/clients/" + authContext.client.id + "/file");
        if(isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const downloadRejectionLetter = async () => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/applications/" + authContext.application.id + "/rejection/file");
        if(isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const downloadAcceptenceLetter = async () => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/applications/" + authContext.application.id + "/acceptance/file");
        if(isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const showWaitingForPayment = inactiveStatuses.includes(status);
    const showQuestionnaireMessage = activeStatuses.includes(status) && authContext.questionnaire.status === 'Changes required';
    const showQuestionnaireDraftMessage = activeStatuses.includes(status) && authContext.questionnaire.status === 'Draft';
    const showDocumentsMessage = activeStatuses.includes(status) && authContext.questionnaire.status === 'Submitted';
    const showRejectedMessage = rejectedStatuses.includes(status);
    const showApprovedMessage = acceptedStatsues.includes(status);

    return (
        <ClientLayout>
            <div className="flex flex-col items-center space-y-6">

                {showWaitingForPayment &&
                <div className="flex flex-col w-full px-6 py-6 space-y-4 bg-white rounded-md shadow">
                    <div className="flex items-center space-x-8">
                        <WaitingForPayment />
                        <div className="flex flex-col space-y-4">
                            <p className="text-2xl font-semibold">Waiting for payment</p>
                            <p className="text-gray-600">
                                Your contact approved by your agent and we are now waiting for your payment. Once your payment is done, you can send the invoice to your agent.
                            </p>
                        </div>
                    </div>
                </div>}                

                {(showQuestionnaireMessage || showQuestionnaireDraftMessage) && <div className="flex flex-col w-full px-6 py-6 space-y-4 bg-white rounded-md shadow">
                    <p className="text-2xl font-semibold">Complete Questionnaire</p>
                    <p className="text-gray-600">We kindly request your prompt attention to fill out the questionnaire. Once you have completed the questionnaire, please proceed with uploading the necessary documents as instructed. Thank you for your cooperation.</p>
                    <div>
                        <SecondaryButton to="/questionnaire" size="sm" className="max-w-[240px]" text="Answer questions" />
                    </div>
                </div>}

                {/* {showQuestionnaireDraftMessage && <div className="flex flex-col w-full px-6 py-6 space-y-4 bg-white rounded-md shadow">
                    <p className="text-2xl font-semibold">Complete Questionnaire Draft</p>
                    <p className="text-gray-600">To skip to the contract step, complete the questionnaire. Then wait for agent’s approval before signing the contract.</p>
                </div>}     */}

                {/* <div className="flex flex-col w-full px-6 py-6 space-y-6 bg-white rounded-md shadow">
                    <div className="flex flex-col space-y-4">
                        <p className="text-2xl font-semibold">{authContext.questionnaire.name}</p>
                        <p className="text-gray-600">{authContext.questionnaire.description}</p>
                    </div>
                    <div>
                        <SecondaryButton to="/questionnaire" size="sm" className="max-w-[240px]" text="Answer questions" />
                    </div>
                </div> */}

                {showDocumentsMessage &&<div className="flex flex-col w-full px-6 py-6 space-y-6 bg-white rounded-md shadow">
                    <div className="flex flex-col space-y-4">
                        <p className="text-2xl font-semibold">Upload Documents</p>
                        <p className="text-gray-600">We kindly request that you upload the following documents as soon as possible. Please ensure that the documents are in a digital format (PDF, JPEG, or other acceptable formats) and are complete and legible. Uploading these documents promptly will enable us to proceed with your request in a timely manner.</p>
                    </div>
                    <div>
                        <SecondaryButton to="/documents" size="sm" className="max-w-[240px]" text="Upload documents" />
                    </div>
                </div>}

                {showRejectedMessage && <div className="flex flex-col w-full px-6 py-6 space-y-4 bg-white rounded-md shadow">
                    <div className="flex items-center space-x-2">
                        <XCircleIcon width={32} color={colors.red[600]} />
                        <p className="text-2xl font-semibold">Your application has been rejected</p>
                    </div>                    
                    <p className="text-gray-600">{authContext.application.additionalNote}</p>
                    <div>
                        <SecondaryButton onClick={downloadRejectionLetter} lIcon={<ArrowDownOnSquareIcon width={22} />} size="sm" className="max-w-[240px]" text="Rejection letter" />
                    </div>
                </div>}

                {showApprovedMessage && <div className="flex flex-col w-full px-6 py-6 space-y-4 bg-white rounded-md shadow">
                    <div className="flex items-center space-x-2">
                        <CheckCircleIcon width={32} color={colors.green[600]} />
                        <p className="text-2xl font-semibold">Your application has been approved</p>
                    </div>                    
                    <p className="text-gray-600">{authContext.application.additionalNote}</p>
                    <div>
                        <SecondaryButton onClick={downloadAcceptenceLetter} lIcon={<ArrowDownOnSquareIcon width={22} />} size="sm" className="max-w-[240px]" text="Acceptance letter" />
                    </div>
                </div>} 

                {authContext.application.client.contractFileId &&
                <div className="flex flex-col w-full px-6 py-6 space-y-4 bg-white rounded-md shadow">
                    <div className="flex items-center">
                        <div className="flex flex-col space-y-4">
                            <div className="flex w-full">
                                <p className="text-2xl font-semibold">Contract</p>
                                <StatusTag className="flex justify-end grow" status="Approved" />
                            </div>                            
                            <p className="text-gray-600">
                            The contract has received the agent's official approval, signifying that it has met all necessary requirements and is ready for your attention. You may now proceed to access and download the approved contract for your records and further action as needed.
                            </p>
                            <div onClick={downloadContract} className="flex items-center space-x-1 font-medium text-orange-500 cursor-pointer hover:text-orange-700">
                                <DocumentTextIcon width={22} />
                                <span>{authContext.application.client.contractFile ? authContext.application.client.contractFile.name : "Contract"}</span>
                            </div>
                        </div>
                    </div>
                </div>}     
            </div>
        </ClientLayout>
    );
}

export default ClientBoard;