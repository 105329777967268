import { DocumentIcon, XMarkIcon } from "@heroicons/react/24/outline";
import colors from "../../shared/colors";

const DocumentRelatedFileListItem = ({ document, onFileClick, onDeleteClick, canEdit }) => {
    return (
        <div>
            <div className="flex items-end">
                <div className="w-3/5">
                    <div className="flex flex-col space-y-6">
                        {document.file && <div className="flex items-center space-x-2 text-orange-500 cursor-pointer hover:text-orange-700" onClick={() => onFileClick(document)}>
                            <DocumentIcon width={22} />
                            <p className="font-medium text-md">{document.file.name}</p>

                        </div>}



                    </div>
                </div>
                {canEdit && <div className="flex justify-end cursor-pointer">
                    <XMarkIcon color={colors.red[500]} width={22} onClick={() => onDeleteClick(document)} />
                </div>}

            </div>
        </div >
    );
}

export default DocumentRelatedFileListItem;