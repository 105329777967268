import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { ArrowsUpDownIcon, CheckIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import { DataContext } from "../../store/DataContext";

const QuestionItem = ({ question, showAddQuestionForm, onEditClicked, onDeleteClicked, index }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: question.id });
    const dataContext = useContext(DataContext);

    return (
        <div 
            className={`flex w-full min-w-min px-8 py-5 border-b-[1.5px] border-b-slate-200`}
            key={question.id}
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
                transition: transition
            }}>
            <div className="flex space-x-2 md:w-[2%] items-center">
                {!showAddQuestionForm && 
                    <ArrowsUpDownIcon  {...listeners} {...attributes} width={20} className="text-gray-600 cursor-pointer outline-0" />
                }
            </div>
            <div className="flex min-w-[210px] space-x-2 md:w-[28%] items-center">
                <span>{question.question}</span>
            </div>
            <div className="flex min-w-[210px] space-x-2 md:w-[28%] items-center">
                <span>{question.description}</span>
            </div>
            <div className="flex min-w-[210px] space-x-2 md:w-[22%] items-center">
                <span>{dataContext.getFieldType(question.fieldTypeId).name}</span>
            </div>
            <div className="flex min-w-[210px] space-x-2 md:w-[6%] items-center pl-4">
                {question.isRequired && <CheckIcon width={20} />}
            </div>
            <div className="flex min-w-[105px] space-x-4 md:w-[14%] items-center justify-center">
                <PencilIcon onClick={() => onEditClicked(question.id, index)}
                    className='text-gray-900 cursor-pointer hover:text-gray-600' width={22} />
                <TrashIcon className='text-gray-900 cursor-pointer hover:text-gray-600' onClick={onDeleteClicked} width={22} />
            </div>
        </div>
    );
}

export default QuestionItem;