import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { ArrowsRightLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";

const SectionItem = ({ section, selected, onClick, canDelete, onDeleteClicked }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: section.id });

    return (
        <div
            className={`w-[240px] h-full p-2 mb-2 border flex flex-col space-y-4 items-center cursor-pointer group ${selected ? "bg-slate-200" : "bg-white"}`}
            key={section.id}
            ref={setNodeRef}
            onClick={onClick}
            style={{
                transform: CSS.Transform.toString(transform),
                transition: transition
            }}
        >
            <div className="flex w-full">
                <div className="flex w-1/2">
                    <ArrowsRightLeftIcon className="invisible text-gray-800 group-hover:visible section-drag outline-0"
                        width={22} {...listeners} {...attributes}
                    />
                </div>
                <div className="flex justify-end w-1/2">
                    {canDelete &&
                        <XMarkIcon
                            className="invisible text-gray-800 group-hover:visible justify-self-stretch outline-0"
                            width={22}
                            onClick={(e) => {
                                onDeleteClicked();
                                e.stopPropagation();
                            }}
                        />
                    }
                </div>
            </div>
            <span>{section.name}</span>
        </div>
    );
}

export default SectionItem;