import { LockOpenIcon, CursorArrowRippleIcon, MagnifyingGlassIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import TextField from "../../components/forms/TextField";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import colors from "../../shared/colors";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import userApi from "../../services/apis/userApi";
import { getInitials, isSuccess, showSuccess, toFirstUppercase } from "../../shared/util";
import { useContext, useEffect, useState } from "react";
import InviteUserForm from "./forms/InviteUserForm";
import EditUserForm from "./forms/EditUserForm";
import RichTable from "../../components/tables/RichTable";
import BaseModal from "../../components/modals/BaseModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { AuthContext } from "../../store/AuthContext";
import StatusDropdown from "../../components/dropdowns/StatusDropdown";
import resetPasswordEmailApi from "../../services/apis/resetPasswordEmail";
import resendActivationEmail from "../../services/apis/resendActivationEmail";

const Users = () => {
    const [users, setUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [sortByField, setSortByField] = useState('id');
    const [sortBy, setSortBy] = useState('DESC');
    const [searchTerm, setSearchTerm] = useState("");
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [showConfirmResetPasswordModal, setShowConfirmResetPasswordModal] = useState(false);
    const [showConfirmResendActivationModal, setShowConfirmResendActivationModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const authContext = useContext(AuthContext);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const canEdit = authContext.session.role !== 'sales';
    const [showConfirmStatusModal, setShowConfirmStatusModal] = useState(false);

    const userService = userApi();

    const statuses = [
        {
            value: "pending",
            display: "Pending",
        },
        {
            value: "active",
            display: "Active",
        },
    ];


    const getUsers = async () => {
        setIsLoading(true);

        const params = {
            "filter.role": "$in:agent,admin,sales,compliance",
            "limit": 8,
            "page": page,
            "sortBy": sortByField + ":" + sortBy,
        };

        if (searchTerm) {
            params["filter.firstName"] = "$ilike:$or:" + searchTerm;
            params["filter.lastName"] = "$ilike:$or:" + searchTerm;
        }

        const getUsersResponse = await userService.getAll({ params: params });

        if (isSuccess(getUsersResponse.status)) {
            setUsers(getUsersResponse.data.data);
            setTotalPages(getUsersResponse.data.meta.totalPages);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getUsers();
    }, [page, sortBy, sortByField, searchTerm]);

    const inviteSent = () => {
        getUsers();
        setInviteUserModalOpen(false);
        selectedUser ? showSuccess("User has been edited!") : showSuccess("User has been invited!");
    }

    const changeOrder = (field) => {
        if (field !== sortByField) {
            setSortByField(field);
            setSortBy("ASC");
        }

        setSortBy(sortBy === "ASC" ? "DESC" : "ASC");
    }

    const changeActiveStatus = async () => {
        setShowConfirmStatusModal(false);
        const patchActiveResponse = await userService.patch(selectedUser, { status: selectedStatus.value });

        if (isSuccess(patchActiveResponse.status)) {
            getUsers();
            setSelectedStatus(null);
        }
    }


    const userTableColumns = [
        {
            display: 'User',
            sortable: true,
            sortName: 'firstName',
            width: 23,
            column: 'name',
            generate: (item) => {
                return (
                    <div className="flex items-center space-x-3">
                        {item.firstName && item.lastName ?
                            <>
                                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-slate-100">
                                    <span className="text-slate-900">{getInitials(item.firstName + " " + item.lastName)}</span>
                                </div>
                                <span className="text-gray-900">{item.firstName} {item.lastName}</span>
                            </> :
                            <span className="pl-12 text-gray-900">-</span>}
                    </div>
                );
            }
        },
        {
            display: 'Email',
            sortable: true,
            sortName: 'username',
            width: 30,
            column: 'username',
        },
        {
            display: 'Role',
            sortable: true,
            sortName: 'role',
            width: 16,
            column: 'role',
            textTransform: (item) => toFirstUppercase(item.role),
        },
        {
            display: 'Status',
            sortable: true,
            sortName: 'status',
            width: 23,
            column: 'status',
            textTransform: (item) =>
                <StatusDropdown
                    value={item.status}
                    statuses={statuses}
                    onChange={(selectedStatus) => {
                        setSelectedUser(item.id);
                        setSelectedStatus(selectedStatus);
                        setShowConfirmStatusModal(true);
                    }}
                    canEdit={canEdit}
                />


        }
    ];

    const tableActions = [
        {
            title: 'Reset Password',
            icon: <LockOpenIcon width={20} />,
            show: (item) => item.status === 'active',
            onClick: (item) => handleResetPasswordButtonClicked(item),
        },
        {
            title: 'Resend Activation',
            icon: <CursorArrowRippleIcon width={20} />,
            show: (item) => item.status !== 'active',
            onClick: (item) => handleResendActivationButtonClicked(item),
        },
        {
            title: 'Delete',
            icon: <TrashIcon width={20} />,
            onClick: (item) => { handleDeleteUserClicked(item); }
        },
        {
            title: 'Edit',
            icon: <PencilIcon width={20} />,
            confirm: false,
            onClick: (item) => { handleEditUserClicked(item); }
        },
    ];

    const handleDeleteUserClicked = (user) => {
        setSelectedUser(user);
        setShowConfirmDeleteModal(true);
    }

    const handleResetPasswordButtonClicked = async (user) => {
        setSelectedUser(user);
        setShowConfirmResetPasswordModal(true);
    }

    const resetPassword = async () => {
        const reserPasswordEmailService = resetPasswordEmailApi();
        setShowConfirmResetPasswordModal(false);
        const sendResetPasswordResponse = await reserPasswordEmailService.post({ email: selectedUser.username });

        if (isSuccess(sendResetPasswordResponse.status)) {
            showSuccess("The password reset link has been sent!");
        }

        setSelectedUser(null);
    }

    const handleResendActivationButtonClicked = async (user) => {
        setSelectedUser(user);
        setShowConfirmResendActivationModal(true);
    }

    const resendActivation = async () => {
        const resendActivationEmailService = resendActivationEmail();
        setShowConfirmResendActivationModal(false);
        const sendResendActivationResponse = await resendActivationEmailService.post({ email: selectedUser.username });

        if (isSuccess(sendResendActivationResponse.status)) {
            showSuccess("The activation link has been sent!");
        }

        setSelectedUser(null);
    }

    const deleteUser = async () => {
        const deleteUserResponse = await userService.remove(selectedUser.id);
        if (isSuccess(deleteUserResponse.status)) {
            showSuccess("User has been deleted");
            setSelectedUser(null);
            setShowConfirmDeleteModal(false);
            getUsers();
        }
    }

    const getRightSidebarContent = () => {
        if (inviteUserModalOpen) {
            return selectedUser ? <EditUserForm onEditSent={inviteSent} user={selectedUser} /> : <InviteUserForm onInviteSent={inviteSent} user={selectedUser} />;
        } else {
            return null;
        }
    }

    const getRightSidebarTitle = () => {
        return inviteUserModalOpen ? (selectedUser ? 'Edit User' : 'Invite New User') : '';
    }

    const handleEditUserClicked = (item) => {
        if (authContext.session.role !== 'admin') {
            return;
        }

        setSelectedUser(item);
        setInviteUserModalOpen(true);
    };
    return (
        <div>
            <SidebarLayout title="Users"
                showRightSidebar={inviteUserModalOpen}
                rightSidebarTitle={getRightSidebarTitle()}
                rightSidebarContent={getRightSidebarContent()}
                onRightSidebarClose={() => setInviteUserModalOpen(false)}>
                <div className="flex flex-col mb-4 space-y-3 md:space-y-0 md:space-x-4 md:flex-row">
                    <div className="w-full md:w-[380px]">
                        <TextField placeholder="Search" icon={<MagnifyingGlassIcon width={20} color={colors.silver600} />}
                            onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} />
                    </div>
                    <div className="flex space-x-3 grow">
                        {/* <div className="w-1/2">
                            <FilterButton text="Filters" className="md:w-[220px]" filtersCount={2} />
                        </div> */}
                        <div className="flex justify-end w-full">
                            <PrimaryButton className="w-full md:w-[220px]" text="Invite New User" onClick={() => {
                                setSelectedUser(null);
                                setInviteUserModalOpen(true)
                            }} />
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                    </div>
                </div>
                <RichTable
                    columns={userTableColumns}
                    items={users}
                    tableActions={tableActions}
                    loading={isLoading}
                    page={page}
                    setPage={(page) => setPage(page)}
                    totalPages={totalPages}
                    changeOrder={changeOrder}
                    sortByField={sortByField}
                    sortBy={sortBy}
                    actionsStyle="w-[128px]"
                />
                <BaseModal show={showConfirmStatusModal} onClose={() => setShowConfirmStatusModal(false)}>
                    <div className="flex flex-col space-y-12 w-[540px]">
                        <div className="flex flex-col space-y-6">
                            <p className="text-xl font-medium">Confirm status change</p>
                            <div className="flex flex-col space-y-4">
                                <p className="">Are you sure you want to change the status of the user?</p>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <PrimaryButton className="w-1/2" onClick={() => changeActiveStatus()} text="Confirm" />
                            <SecondaryButton className="w-1/2" onClick={() => setShowConfirmStatusModal(false)} text="Cancel" />
                        </div>
                    </div>
                </BaseModal>
                <BaseModal show={showConfirmDeleteModal} onClose={() => setShowConfirmDeleteModal(false)}>
                    <div className="flex flex-col space-y-12 w-[540px]">
                        <div className="flex flex-col space-y-6">
                            <p className="text-xl font-medium">Confirm user deletion</p>
                            <div className="flex flex-col space-y-4">
                                <p className="">Are you sure you want to delete this user?</p>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <PrimaryButton className="w-1/2" onClick={() => deleteUser()} text="Confirm" />
                            <SecondaryButton className="w-1/2" onClick={() => setShowConfirmDeleteModal(false)} text="Cancel" />
                        </div>
                    </div>
                </BaseModal>
                <ConfirmModal
                    show={showConfirmResetPasswordModal}
                    title="Confirm reset password"
                    message={`Are you sure you want to reset password for ${selectedUser?.firstName} ${selectedUser?.lastName}`}
                    onConfirm={resetPassword}
                    onClose={() => {
                        setShowConfirmResetPasswordModal(false);
                        setSelectedUser(null);
                    }}
                />
                <ConfirmModal
                    show={showConfirmResendActivationModal}
                    title="Confirm resend of activation email"
                    message={`Are you sure you want to resend activation email for ${selectedUser?.firstName} ${selectedUser?.lastName}`}
                    onConfirm={resendActivation}
                    onClose={() => {
                        setShowConfirmResendActivationModal(false)
                        setSelectedUser(null);
                    }}
                />
            </SidebarLayout>
        </div>
    );
}

export default Users;