import { EnvelopeIcon } from "@heroicons/react/24/outline";
import InputField from "./InputField";
import colors from "../../shared/colors";

const EmailField = (props) => {
    return <InputField
        type='email'
        icon={<EnvelopeIcon width={20} color={colors.silver400} />}
        {...props}
    />
}

export default EmailField;