import { useEffect, useState } from "react";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import { useParams } from "react-router-dom";
import programApi from "../../services/apis/programApi";
import { isSuccess, showSuccess, sortbyProperty } from "../../shared/util";
import { CircleFlag } from "react-circle-flags";
import documentApi from "../../services/apis/documentApi";
import DocumentsForm from "../../components/documents/DocumentsForm";
import SecondaryButton from "../../components/buttons/SecondaryButton";

const ManageDocuments = () => {
    const [program, setProgram] = useState(null);
    const [documents, setDocuments] = useState(null);
    const { programId } = useParams();
    const programService = programApi();
    const documentService = documentApi(programId, 'programs/');
    const [saveDocumentsInProgress, setSaveDocumentsInProgress] = useState(false);

    useEffect(() => {
        getProgram();
        getDocuments();
    }, [])

    const getProgram = async () => {
        const getProgramResponse = await programService.getSingle(programId);
        if (isSuccess(getProgramResponse.status)) {
            setProgram(getProgramResponse.data);
        }
    }

    const getDocuments = async () => {
        const getDocumentsResponse = await documentService.getAll();
        if (isSuccess(getDocumentsResponse.status)) {
            setDocuments(sortbyProperty(getDocumentsResponse.data.data, 'orderNumber'));
        }
    }

    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter' && isValid) {
    //         addDocument();
    //     }
    // }

    const handelSave = async (docs) => {
        setSaveDocumentsInProgress(true);

        const tmpDocuments = [...docs];
        tmpDocuments.forEach((item, index, itemArray) => {
            itemArray[index].orderNumber = index + 1;
            if(item.id === -1) {
                delete itemArray[index].id;
            }
            if(item.edited) {
                delete itemArray[index].edited;
            }
            if(item.new) {
                delete itemArray[index].new;
            }
        });

        const saveDocumentsResponse = await documentService.patch(null, tmpDocuments);

        if(isSuccess(saveDocumentsResponse.status)) {
            setDocuments(saveDocumentsResponse.data);
            showSuccess("Documents saved successfully");
        }

        setSaveDocumentsInProgress(false);
    }

    const getTitle = () => {
        if(!program) {
            return <span></span>;
        }

        return (
            <div className="flex items-center space-x-2">
                <CircleFlag className="hidden md:block" countryCode={program.country.slug} width={22} />
                <p className="hidden md:block">{program.country.name}</p>
                <span className="hidden text-base font-normal text-gray-600 md:block">/</span>
                <p className="hidden text-base font-normal text-gray-600 md:block">{program.name}</p>
                <span className="hidden text-base font-normal text-gray-600 md:block">/</span>
                <p className="text-base font-normal text-gray-600">Documents</p>
            </div>
        );
    }

    return (
        <SidebarLayout title={getTitle()}>
            <div className="flex flex-col space-y-10">
                <div className="flex flex-col space-y-6">
                    <DocumentsForm 
                        documents={documents}
                        onSetDocuments={setDocuments}
                        singleSave={false}
                        save={handelSave}
                    />
                </div>
                <div className="flex space-x-4">
                    <SecondaryButton text="Cancel" to="/programs" className="md:w-[180px] w-full" />
                    {/* <PrimaryButton spinning={saveDocumentsInProgress} text="Save" onClick={handelSaveButtonClicked} className="md:w-[180px] w-full" /> */}
                </div>
            </div>
        </SidebarLayout>
    );
}

export default ManageDocuments;