import { useContext, useEffect, useState } from "react";
import ClientLayout from "../../components/layouts/ClientLayout";
import DocumentContainer from "../../components/upload/DocumentContainer";
import documentApi from "../../services/apis/documentApi";
import { AuthContext } from "../../store/AuthContext";
import { downloadA, isSuccess, showSuccess } from "../../shared/util";
import axiosClient from "../../shared/axiosClient";
import BaseModal from "../../components/modals/BaseModal";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import questionnaireApi from "../../services/apis/questionnaireApi";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import { isValidQItem } from "../../shared/validateQuestionnaireField";
import { DataContext } from "../../store/DataContext";
import FieldSwitch from "../../components/forms/FieldSwitch";

const Documents = () => {
    const [documents, setDocuments] = useState(null);
    const authContext = useContext(AuthContext);
    const documentService = documentApi(authContext.application.programId, 'programs/');
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [showReadyToStartModal, setShowReadyToStartModal] = useState(false);
    const [activeTab, setActiveTab] = useState('documents');
    const [section, setSection] = useState(0);
    const [questionnaire, setQuestionnaire] = useState(authContext.questionnaire);
    const questionnaireService = questionnaireApi(authContext.application.programId, 'programs/');
    const [savingInProgress, setSavinginProgress] = useState(false);
    const [savingAsDraftInProgress, setSavingAsDraftinProgress] = useState(false);
    const dataContext = useContext(DataContext);

    const [memberDocuments, setMemberDocuments] = useState([]);
    const [allMembers, setAllMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const fetchDocuments = async () => {
        setLoadingDocuments(true);
        const getDocumentsResponse = await documentService.getAll();
        if (isSuccess(getDocumentsResponse.status)) {
            setDocuments(getDocumentsResponse.data.data);

            if (getDocumentsResponse.data.data) {
                const groupedByMember = getDocumentsResponse.data.data.reduce((acc, obj) => {
                    const memberId = obj.applicationMember.id;
                    if (!acc[memberId]) {
                        acc[memberId] = {
                            member: obj.applicationMember,
                            data: [],
                        };
                    }
                    acc[memberId].data.push(obj);
                    return acc;
                }, {});

                const allMembersArray = Object.values(groupedByMember).map(item => item.member);
                const applicantIndex = allMembersArray.findIndex(member => member.memberType === 'applicant');

                if (applicantIndex !== -1) {
                    const applicant = allMembersArray.splice(applicantIndex, 1)[0];
                    allMembersArray.unshift(applicant);
                }

                setMemberDocuments(groupedByMember);
                setAllMembers(allMembersArray);

                if (allMembersArray.length && selectedMember == null) {
                    setSelectedMember(allMembersArray[0].id);
                }
            }
        }

        setLoadingDocuments(false);
    }

    useEffect(() => {
        if (location.state && location.state.showReadyToStartModal) {
            setShowReadyToStartModal(true);
        }

        fetchDocuments();
    }, []);

    const saveQuestionnaire = async (forSave) => {
        for (const sectionItem of forSave.sections) {
            const fileItems = sectionItem.items.filter(x => dataContext.getFieldType(x.fieldTypeId).slug === 'file');
            for (const fileItem of fileItems) {
                if (fileItem.value) {
                    const resp = await uploadFile(fileItem);
                    fileItem.value = null;
                }
            }
        }

        const saveQuestionnaireResponse = await questionnaireService.patch(null, forSave);

        if (isSuccess(saveQuestionnaireResponse.status)) {
            showSuccess("Questionnare has beed saved");
        }
    }

    const uploadFile = async (file, document) => {
        if (file) {
            let formData = new FormData();
            formData.append('file', file);

            const fileUploadResponse = await axiosClient.post("documents/" + document.id + "/file", formData, {
                headers: { "Content-type": "multipart/form-data" }
            });

            if (isSuccess(fileUploadResponse.status)) {
                fetchDocuments();
            }
        }
    }

    const uploadQuestionFile = async (item) => {
        let formData = new FormData();
        formData.append('file', item.value);
        const fileUploadResponse = await axiosClient.post("questionnaires/" + questionnaire.id + "/items/" + item.id + '/file', formData, {
            headers: { "Content-type": "multipart/form-data" }
        });

        return fileUploadResponse;
    }

    const getSection = () => {
        return questionnaire.sections[section];
    }

    const next = () => {
        if (section === questionnaire.sections.length - 1) {
            return;
        }

        setSection(section + 1);
    }

    const previous = () => {
        if (section === 0) {
            return;
        }

        setSection(section - 1);
    }

    const submit = async () => {
        setSavinginProgress(true);

        const tmpQestionnaire = { ...questionnaire };
        tmpQestionnaire.status = "Submitted";

        await saveQuestionnaire(tmpQestionnaire);

        setSavinginProgress(false);
    }

    const saveAsDraft = async () => {
        setSavingAsDraftinProgress(true);

        const tmpQestionnaire = { ...questionnaire };
        tmpQestionnaire.status = "Draft";

        await saveQuestionnaire(tmpQestionnaire);

        setSavingAsDraftinProgress(false);
    }

    const clearSection = () => {
        const tmpQestionnaire = { ...questionnaire };
        tmpQestionnaire.sections[section].items.forEach((item, index, itemArray) => {
            itemArray[index].value = "";
        });

        setQuestionnaire(tmpQestionnaire);
    }

    const downloadFile = async (document) => {
        const downloadResponse = await axiosClient.get(process.env.REACT_APP_API_URL + "/documents/" + document.id + "/file");
        if (isSuccess(downloadResponse.status)) {
            downloadA(downloadResponse.data.url);
        }
    }

    const onNoteClickHandle = (document) => {
        setSelectedDocument(document);
        setShowNoteModal(true);
    }

    let percentage = 0;
    if (documents) {
        const notCompletedStatuses = ['Changes required'];
        const notCompletedCount = documents.filter(x => !notCompletedStatuses.includes(x.status)).length;
        percentage = notCompletedCount === 0 ? 0 : (notCompletedCount / documents.length) * 100;
        percentage = Math.floor(percentage);
    }

    const isValid = () => {
        let valid = true;
        questionnaire.sections.forEach(section => {
            const sectionValid = section.items.some(x => !isValidQItem(x, dataContext.getFieldType(x.fieldTypeId).slug));
            if (sectionValid) {
                valid = false;
            }
        });

        return valid;
    }

    const setValue = (value, index) => {
        const tmpSections = [...questionnaire.sections];
        tmpSections[section].items[index].value = value;

        if (!value && dataContext.getFieldType(tmpSections[section].items[index].fieldTypeId).slug === 'file') {
            tmpSections[section].items[index].fileId = null;
        }

        setQuestionnaire({ ...questionnaire, sections: tmpSections });
    }

    const numberOfEmptyRequiredItems = () => {
        let num = 0;
        questionnaire.sections.forEach((section) => {
            const empty = section.items.filter(x => x.isRequired && !x.value && !x.fileId && dataContext.getFieldType(x.fieldTypeId).slug !== 'toggle');
            num = num + empty.length;
        });

        return num;
    }

    let footer = (
        <div className="fixed bottom-0 left-0 flex flex-col justify-center w-full py-4 space-y-6 bg-white shadow md:py-8 md:space-y-0 md:flex-row">
            {questionnaire.sections.length > 1 &&
                <div className="flex items-center justify-center md:justify-start space-x-4 max-w-[840px] w-full font-medium">
                    <div onClick={previous} className="flex items-center space-x-2 cursor-pointer hover:text-gray-600">
                        <p>Previous</p>
                        <ChevronLeftIcon width={18} />
                    </div>
                    <p>{section + 1} / {questionnaire.sections.length}</p>
                    <div onClick={next} className="flex items-center space-x-2 cursor-pointer hover:text-gray-600">
                        <ChevronRightIcon width={18} />
                        <p>Next</p>
                    </div>
                </div>
            }
            <div className="flex flex-col px-4 space-y-4 md:space-y-0 md:space-x-2 md:flex-row md:px-0">
                <SecondaryButton className="md:w-[180px]" spinning={savingAsDraftInProgress} onClick={saveAsDraft} text="Save as draft" />
                <PrimaryButton disabled={!isValid()} className="md:w-[180px]" spinning={savingInProgress} onClick={submit} text="Submit" />
            </div>
        </div>
    );

    if (questionnaire.status === 'Submitted') {
        footer = null;
    }

    const questionsToAnswer = numberOfEmptyRequiredItems();

    return (
        <ClientLayout footer={activeTab === 'questionnaire' && footer}
            paddingB={activeTab === 'questionnaire' && "pb-[100px]"}>
            <div className="flex flex-col items-center space-y-6">
                <div className="flex flex-col w-full px-6 py-6 space-y-4 bg-white rounded-md shadow">
                    <div>
                        <div className="flex items-center">
                            <div onClick={() => setActiveTab('documents')} className="w-[240px] relative cursor-pointer pb-4">
                                <p className={`text-lg ${activeTab === 'documents' && "font-semibold"} hover:text-gray-700`}>Documents</p>
                                {activeTab === 'documents' && <div className="absolute w-4/5 h-0.5 bg-dirt -bottom-0.5"></div>}
                            </div>
                            <div onClick={() => setActiveTab('questionnaire')} className="w-[240px] relative cursor-pointer pb-4">
                                <p className={`text-lg ${activeTab === 'questionnaire' && "font-semibold"} hover:text-gray-700 flex space-x-2 items-center`}>
                                    <span>Questionnaire</span>
                                    {questionsToAnswer !== 0 && <span className="flex items-center justify-center w-8 h-8 text-sm text-white rounded-full bg-dirt">{questionsToAnswer}</span>}
                                </p>
                                {activeTab === 'questionnaire' && <div className="absolute w-4/5 h-0.5 bg-dirt -bottom-0.5"></div>}
                            </div>
                        </div>
                        <div className="w-full h-0.5 bg-gray-100"></div>
                    </div>
                    {activeTab === 'documents' &&
                        <div className="pt-6">
                            <p className="text-gray-600">Documents are required by the government. Upload a document and your agent will review it.
                                If it's complete and accurate, they'll approve it. Otherwise, they'll reject it with feedback so you can fix it and upload again.</p>
                            <div className="flex flex-col pt-4 pb-8 space-y-2">
                                <span className={`text-2xl font-medium ${percentage === 100 ? "text-green-600" : "text-dirt"}`}>{percentage}%</span>
                                <div className="w-full h-2 bg-gray-100 rounded-full">
                                    <div style={{ 'width': percentage + "%" }} className={`h-2 rounded-full ${percentage === 100 ? "bg-green-600" : "bg-dirt"}`}></div>
                                </div>
                            </div>
                            {allMembers && allMembers.length > 1 &&
                                <div className="flex items-center my-2 pb-4">
                                    {allMembers.map((member, index) => (
                                        <div key={index} className="w-[240px] relative cursor-pointer pb-4" onClick={() => setSelectedMember(member.id)}>
                                            {member.firstName} {member.lastName}
                                            {selectedMember === member.id && <div className="absolute w-4/5 h-0.5 bg-dirt -bottom-0.5"></div>}
                                        </div>
                                    ))}
                                </div>
                            }

                            {memberDocuments[selectedMember] && memberDocuments[selectedMember].data.map((document, index) => (
                                <DocumentContainer
                                    key={document.id}
                                    document={document}
                                    index={index}
                                    onUpload={uploadFile}
                                    onFileClick={() => downloadFile(document)}
                                    onNoteClick={onNoteClickHandle}
                                />
                            ))}
                        </div>}
                    {activeTab === 'questionnaire' &&
                        <div className="flex flex-col px-6 py-6 space-y-12">
                            <div>
                                <p className="text-xl font-medium">SECTION {section + 1} - {getSection().name}</p>
                                <p>{getSection().description}</p>
                            </div>
                            <div className="flex flex-col space-y-5">
                                {getSection().items.map((item, itemIndex) => (
                                    <div className="flex flex-col items-center w-full space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
                                        <div className="flex flex-col justify-center w-full md:w-1/3">
                                            <span className="font-medium text-gray-900">{section + 1}.{itemIndex + 1} {item.question}
                                                {item.isRequired && <span className="ml-1 text-red-500">*</span>}
                                            </span>
                                            <span className="text-sm text-gray-700 ">{item.description}</span>
                                        </div>
                                        <div className="flex justify-start w-full md:w-2/3">
                                            <FieldSwitch
                                                type={dataContext.getFieldType(item.fieldTypeId).slug}
                                                value={item.value}
                                                isRequired={item.isRequired}
                                                onChange={(value) => setValue(value, itemIndex)}
                                                size="sm"
                                                className="w-full"
                                                item={item}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex items-center justify-center font-medium cursor-pointer hover:text-gray-700">
                                <p onClick={clearSection}>Clear section</p>
                            </div>
                        </div>}
                </div>
            </div>
            <BaseModal show={showNoteModal} onClose={() => setShowNoteModal(false)}>
                <div className="flex flex-col space-y-12 w-screen md:w-[440px]">
                    <div className="flex flex-col space-y-6">
                        <div className="flex flex-col items-center space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
                            <p className="text-2xl font-semibold">Note</p>
                            {selectedDocument && <span className="px-4 py-2 text-center bg-gray-100 rounded-full">{selectedDocument.name}</span>}
                        </div>
                        <div className="px-4">
                            {selectedDocument && <span>{selectedDocument.note}</span>}
                        </div>
                    </div>
                    <div className="flex justify-center md:justify-end">
                        <PrimaryButton onClick={() => setShowNoteModal(false)} className="w-[110px]" text="Okay" />
                    </div>
                </div>
            </BaseModal>
            <BaseModal show={showReadyToStartModal} onClose={() => setShowReadyToStartModal(false)}>
                <div className="flex flex-col space-y-12 w-screen md:w-[440px] px-4">
                    <div className="flex flex-col space-y-6">
                        <div className="flex items-center space-x-4">
                            <p className="text-2xl font-semibold">You are ready to start!</p>
                        </div>
                        <div>
                            <p className="font-medium">You have completed your questionnaire. You can now see all the required documents and start uploading them until your agent approves all of them.</p>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <PrimaryButton onClick={() => setShowReadyToStartModal(false)} className="w-full" text="Start" />
                    </div>
                </div>
            </BaseModal>
        </ClientLayout>
    );
}

export default Documents;