import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import colors from "../../shared/colors";
import { useContext, useEffect, useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import UserAccountDropdown from "../dropdowns/UserAccountDropdown";
import { UIContext } from "../../store/UIContext";
import NotificationDropdown from "../dropdowns/NotificationDropdown";

export const SIDEBAR_LAYOUT_DYNAMICLY_GENERATED_CLASSES =
    'w-[520px]'  |
    'w-[680px]' |
    'w-[860px]'
;

const SidebarLayout = ({ children, title, showRightSidebar, rightSidebarTitle, rightSidebarContent, onRightSidebarClose, rightSidebarSize, containerClass }) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [showBackDrop, setShowBackDrop] = useState(false);
    const uiContext = useContext(UIContext);

    useEffect(() => {
        if (showRightSidebar) {
            setShowBackDrop(showRightSidebar);
        } else {
            setTimeout(() => setShowBackDrop(showRightSidebar), 300);
        }
    }, [showRightSidebar]);

    const getRightSidebarWidth = () => {
        switch(rightSidebarSize) {
            case "sm": return 520;
            case "md": return 680;
            case "lg": return 860;
            default: return 520;
        }
    }

    return (
        <div className="relative w-screen h-screen overflow-x-hidden overflow-y-hidden bg-cultured">
            <div className={`absolute top-0 left-0 w-full h-full bg-gray-900 z-[17] transition-opacity ease-in-out backdrop-blur-xl duration-300 opacity-50 ${showRightSidebar ? "opacity-50" : "opacity-0"} ${(showBackDrop || uiContext.showBackdrop) ? "visible" : "invisible"}`}></div>
            <div className={`absolute top-0 left-0 w-full h-full bg-gray-900 z-[17] transition-opacity ease-in-out backdrop-blur-xl duration-300 opacity-25 ${uiContext.showLightBackdrop ? "visible" : "invisible"}`}></div>
            {menuVisible && <div onClick={() => setMenuVisible(false)} className="md:collapse absolute w-full h-full bg-black z-[8] opacity-25 blur-2xl"></div>}
            <Sidebar menuVisible={menuVisible} setMenuVisible={setMenuVisible} />
            <div className={"h-full md:ml-[320px] pb-20"}>
                <div className="relative flex items-center h-20 w-full px-6 space-x-4 bg-white drop-shadow z-[8]">
                    <Bars3Icon onClick={() => setMenuVisible(true)} width={32} height={32} className="cursor-pointer md:hidden" />
                    <h2 className="text-2xl font-semibold min-w-min">{title}</h2>
                    <div className="flex items-center justify-end space-x-8 grow">
                        <NotificationDropdown />
                        <UserAccountDropdown />
                    </div>
                </div>
                <div className="h-full">
                    {uiContext.showAccountDropdown && 
                        <div className="bg-white absolute z-[39] right-1 top-[5.2rem] shadow">
                            {uiContext.accountDropdownContent}
                        </div>
                    }
                    {uiContext.showNotificationDropdown && 
                        <div className="bg-white absolute z-[39] right-1 top-[5.2rem] shadow">
                            {uiContext.notificationDropdownContent}
                        </div>
                    }
                    <div className={`${containerClass ? containerClass : undefined} h-full px-2 py-4 overflow-y-auto md:p-8`}>
                        {children}
                    </div>
                </div>
            </div>
            <div className={`bg-white w-[${getRightSidebarWidth()}px] flex flex-col space-y-4 max-w-full h-full max-h-full absolute top-0 right-0 z-[21] shadow-sm ease-in-out duration-300 ${showRightSidebar ? "translate-x-0" : "translate-x-full"}`}>
                <div className="flex items-center px-6 py-6 space-x-4">
                    <XMarkIcon width={38} color={colors.gray[700]} className="cursor-pointer hover:text-gray-500" onClick={onRightSidebarClose} />
                    <h3 className="text-xl font-semibold text-gray-600">{rightSidebarTitle}</h3>
                </div>
                <div className="px-6 py-4 overflow-y-auto">
                    {rightSidebarContent}
                </div>
            </div>
        </div>
    );
}

export default SidebarLayout;