import { useContext, useEffect } from "react";
import { UIContext } from "../../store/UIContext";
import { useOutsideClick } from "../../hooks/CustomHooks";

const BaseModal = ({ show, onClose, children }) => {
    const uiContext = useContext(UIContext);
    const ref = useOutsideClick(onClose);

    useEffect(() => {
        uiContext.setShowBackdrop(show);
    }, [show]);

    return show ? (
        <div ref={ref} className="rounded absolute p-10 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow top-1/2 left-1/2 z-[36]">
            {children}
        </div>
    ) : null;
}

export default BaseModal;