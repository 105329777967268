import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AppLoader from "../../components/loader/AppLoader";
import questionnaireApi from "../../services/apis/questionnaireApi";
import { isSuccess, showSuccess, sortbyProperty } from "../../shared/util";
import PublicClientLayout from "../../components/layouts/PublicClientLayout";
import { ChevronLeftIcon, ChevronRightIcon, DocumentChartBarIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import FieldSwitch from "../../components/forms/FieldSwitch";
import fieldTypeApi from "../../services/apis/fieldTypeApi";
import { DataContext } from "../../store/DataContext";
import countryApi from "../../services/apis/countryApi";
import { isValidQItem } from "../../shared/validateQuestionnaireField";
import axiosClient from "../../shared/axiosClient";

const PublicQuestionnaire = () => {
    const dataContext = useContext(DataContext);

    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const [isLoadingQuestionnaire, setIsLoadingQuestionnaire] = useState(true);
    const [questionnaire, setQuestionnaire] = useState(null);
    const [title, setTitle] = useState(null);

    const [section, setSection] = useState(0);
    const [savingInProgress, setSavinginProgress] = useState(false);
    const [savingAsDraftInProgress, setSavingAsDraftinProgress] = useState(false);

    const questionnaireService = questionnaireApi();
    const fieldTypeService = fieldTypeApi();
    const countryService = countryApi();

    const navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        await getQuestionnaire();
        await fetchFieldTypes();
        await fetchCountries();
        setIsLoadingQuestionnaire(false);
    }

    const getQuestionnaire = async () => {
        const token = searchParams.get('access');
        if (!id || !token) {
            navigate('/not-found');
        }

        const getQuestionnaireResponse = await questionnaireService.getSingle(id, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        if (isSuccess(getQuestionnaireResponse.status)) { 
            setQuestionnaire(getQuestionnaireResponse.data);
            setTitle(getQuestionnaireResponse.data.name);
        } else {
            navigate('/not-found');
        }
    }

    const fetchFieldTypes = async () => {
        const token = searchParams.get('access');
        const getFieldTypesResponse = await fieldTypeService.getAll({
            params: {
                "limit": 0,
            },
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        if (isSuccess(getFieldTypesResponse.status)) {
            dataContext.setFieldTypes(sortbyProperty(getFieldTypesResponse.data.data, 'id'));
        } else {
            navigate('/not-found');
        }
    }

    const fetchCountries = async () => {
        const token = searchParams.get('access');
        const getCountriesResponse = await countryService.getAll({
            params: {
                "limit": 0,
            },
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        if (isSuccess(getCountriesResponse.status)) {
            dataContext.setCountries(sortbyProperty(getCountriesResponse.data.data, 'id'));
        } else {
            navigate('/not-found');
        }
    }

    const getSection = () => {
        return questionnaire.sections[section];
    }

    const next = () => {
        if (section === questionnaire.sections.length - 1) {
            return;
        }

        setSection(section + 1);
    }

    const previous = () => {
        if (section === 0) {
            return;
        }

        setSection(section - 1);
    }

    const uploadFile = async (item) => {
        let formData = new FormData();
        formData.append('file', item.value);

        const token = searchParams.get('access');
        const fileUploadResponse = await axiosClient.post("questionnaires/" + questionnaire.id + "/items/" + item.id + '/file', formData, {
            headers: { "Content-type": "multipart/form-data", 'Authorization': 'Bearer ' + token }
        });

        return fileUploadResponse;
    }

    const saveQuestionnaire = async (forSave) => {
        for(const sectionItem of forSave.sections) {
            const fileItems = sectionItem.items.filter(x => dataContext.getFieldType(x.fieldTypeId).slug === 'file');
            for(const fileItem of fileItems) {
                if(fileItem.value) {
                    const resp = await uploadFile(fileItem);
                    fileItem.value = null;
                }
            }
        }

        const token = searchParams.get('access');
        const saveQuestionnaireResponse = await questionnaireService.patch(id, forSave, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        if (isSuccess(saveQuestionnaireResponse.status)) {
            await getQuestionnaire();
            showSuccess("Questionnare has beed saved");
        }
    }

    const submit = async () => {
        setSavinginProgress(true);

        const tmpQestionnaire = { ...questionnaire };
        tmpQestionnaire.status = "Submitted";

        await saveQuestionnaire(tmpQestionnaire);

        setSavinginProgress(false);
    }

    const saveAsDraft = async () => {
        setSavingAsDraftinProgress(true);

        const tmpQestionnaire = { ...questionnaire };
        tmpQestionnaire.status = "Draft";

        await saveQuestionnaire(tmpQestionnaire);

        setSavingAsDraftinProgress(false);
    }

    const clearSection = () => {
        const tmpQestionnaire = { ...questionnaire };
        tmpQestionnaire.sections[section].items.forEach((item, index, itemArray) => {
            itemArray[index].value = "";
        });

        setQuestionnaire(tmpQestionnaire);
    }

    const isValid = () => {
        let valid = true;
        questionnaire.sections.forEach(section => {
            const sectionValid = section.items.some(x => !isValidQItem(x, dataContext.getFieldType(x.fieldTypeId).slug));
            if (sectionValid) {
                valid = false;
            }
        });

        return valid;
    }

    const setValue = (value, index) => {
        const tmpSections = [...questionnaire.sections];
        tmpSections[section].items[index].value = value;

        if(!value && dataContext.getFieldType(tmpSections[section].items[index].fieldTypeId).slug === 'file') {
            tmpSections[section].items[index].fileId = null;
        }

        setQuestionnaire({ ...questionnaire, sections: tmpSections });
    }

    if (isLoadingQuestionnaire) {
        return <AppLoader />
    }

    let footer = (
        <div className="fixed bottom-0 left-0 flex flex-col justify-center w-full py-4 space-y-6 bg-white shadow md:py-8 md:space-y-0 md:flex-row">
            {questionnaire.sections.length > 1 &&
                <div className="flex items-center justify-center md:justify-start space-x-4 max-w-[840px] w-full font-medium">
                    <div onClick={previous} className="flex items-center space-x-2 cursor-pointer hover:text-gray-600">
                        <p>Previous</p>
                        <ChevronLeftIcon width={18} />
                    </div>
                    <p>{section + 1} / {questionnaire.sections.length}</p>
                    <div onClick={next} className="flex items-center space-x-2 cursor-pointer hover:text-gray-600">
                        <ChevronRightIcon width={18} />
                        <p>Next</p>
                    </div>
                </div>
            }
            {questionnaire.status !== 'Submitted' && <div className="flex flex-col px-4 space-y-4 md:space-y-0 md:space-x-2 md:flex-row md:px-0">
                <SecondaryButton className="md:w-[180px]" spinning={savingAsDraftInProgress} onClick={saveAsDraft} text="Save as draft" />
                <PrimaryButton disabled={!isValid()} className="md:w-[180px]" spinning={savingInProgress} onClick={submit} text="Submit" />
            </div>}
        </div>
    );

    if(questionnaire.status === 'Submitted') {
        footer = null;
    }

    return (
        <PublicClientLayout title={title} footer={footer}>
            <div className="flex flex-col space-y-8">
                {questionnaire.status === 'Submitted' &&
                    <div className="flex flex-col w-full px-6 py-6 space-y-4 bg-white rounded-md shadow">
                        <div className="flex items-center space-x-8">
                            <DocumentChartBarIcon className="text-coyoteb" width={96} />
                            <div className="flex flex-col space-y-4">
                                <p className="text-2xl font-semibold">Questionnaire submitted</p>
                                <p className="text-gray-600">
                                    Great news! Your questionnaire has been successfully submitted. Kindly wait a moment, and we'll be in touch with you shortly. Thank you for your participation!
                                </p>
                            </div>
                        </div>
                    </div>
                }
                <div className="flex flex-col px-6 py-6 space-y-12 bg-white rounded">
                    <div>
                        <p className="text-2xl font-medium">SECTION {section + 1} - {getSection().name}</p>
                        <p>{getSection().description}</p>
                    </div>
                    <div className="flex flex-col space-y-5">
                        {getSection().items.map((item, itemIndex) => (
                            <div className="flex flex-col items-center w-full space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
                                <div className="flex flex-col justify-center w-full md:w-1/3">
                                    <span className="font-medium text-gray-900">{section + 1}.{itemIndex + 1} {item.question}
                                        {item.isRequired && <span className="ml-1 text-red-500">*</span>}
                                    </span>
                                    <span className="text-sm text-gray-700 ">{item.description}</span>
                                </div>
                                <div className="flex justify-start w-full md:w-2/3">
                                    <FieldSwitch
                                        type={dataContext.getFieldType(item.fieldTypeId).slug}
                                        value={item.value}
                                        isRequired={item.isRequired}
                                        onChange={(value) => setValue(value, itemIndex)}
                                        size="sm"
                                        valid={isValidQItem(item, dataContext.getFieldType(item.fieldTypeId).slug)}
                                        className="w-full" 
                                        item={item}    
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    {questionnaire.status !== 'Submitted' && <div className="flex items-center justify-center font-medium cursor-pointer hover:text-gray-700">
                        <p onClick={clearSection}>Clear section</p>
                    </div>}
                </div>
            </div>
        </PublicClientLayout>
    );
}

export default PublicQuestionnaire;