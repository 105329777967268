import { useEffect, useState } from "react";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import documentApi from "../../services/apis/documentApi";
import { useParams, useSearchParams } from "react-router-dom";
import { isSuccess, showSuccess } from "../../shared/util";
import DocumentsForm from "../../components/documents/DocumentsForm";
import SecondaryButton from "../../components/buttons/SecondaryButton";

const EditApplicationDocuments = () => {
    const [documents, setDocuments] = useState(null);
    const [saveDocumentsInProgress, setSaveDocumentsInProgress] = useState(false);

    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const memberType = searchParams.get("memberType");
    const memberId = searchParams.get("memberId");
    const documentService = documentApi(id, 'applications/');

    useEffect(() => {
        getDocuments();
    }, []);

    const getDocuments = async () => {
        const getDocumentsResponse = await documentService.getAll();

        if (isSuccess(getDocumentsResponse.status)) {
            setDocuments(getDocumentsResponse.data.data);
        }
    }

    const handelSave = async (docs) => {
        setSaveDocumentsInProgress(true);

        const tmpDocuments = [...docs];
        tmpDocuments.forEach((item, index, itemArray) => {
            if (item.new && memberId) {
                itemArray[index].applicationMemberId = +memberId
            }

            itemArray[index].orderNumber = index + 1;

            if (item.id === -1) {
                delete itemArray[index].id;
            }
            if (item.edited) {
                delete itemArray[index].edited;
            }
            if (item.new) {
                delete itemArray[index].new;
            }
        });

        const saveDocumentsResponse = await documentService.patch(null, tmpDocuments);

        if (isSuccess(saveDocumentsResponse.status)) {
            setDocuments(saveDocumentsResponse.data);
            showSuccess("Documents saved successfully");
        }

        setSaveDocumentsInProgress(false);
    }

    return (
        <SidebarLayout>
            {documents && <div className="flex flex-col space-y-8">
                <DocumentsForm
                    documents={documents}
                    onSetDocuments={setDocuments}
                    singleSave={false}
                    save={handelSave}
                    memberType={memberType}
                    memberId={memberId}
                />
                <div className="flex space-x-4">
                    <SecondaryButton text="Cancel" to={"/applications/" + id} className="md:w-[180px] w-full" />
                </div>
            </div>}
        </SidebarLayout>
    );
}

export default EditApplicationDocuments;