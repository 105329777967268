import { useContext } from "react";
import UserAvatar from "../../components/shared/UserAvatar";
import { AuthContext } from "../../store/AuthContext";
import moment from "moment";
import { getInitials } from "../../shared/util";

const MessageItem = ({ message }) => {
    const authContext = useContext(AuthContext);
    const direction = message.author.id === authContext.session.userId ? 'r' : 'l'; 

    const getAuthName = () => {
        return authContext.session.role === 'client' && authContext.session.clients && authContext.session.clients.length > 0
            ? authContext.session.clients[0].firstName + " " + authContext.session.clients[0].lastName
            : authContext.session.firstName + " " + authContext.session.lastName;
    }

    const getName = () => {
        if(message.author.id === authContext.session.userId) {
            return getAuthName();
        }

        return message.author.firstName + " " + message.author.lastName;
    }

    return (
        <div className='flex items-start space-x-2'>
            <div className='w-[62px]'>
                {direction === 'l' && 
                    <div className="flex items-center justify-center w-10 h-10 bg-indigo-100 rounded-full">
                        <span className="font-medium text-gray-900">{getInitials(getName())}</span>
                    </div>
                }
            </div>
            <div className={`flex flex-col w-full grow ${direction === 'l' ? "items-start" : "items-end"}`}>
                <div className={`p-4 rounded border w-fit ${direction === 'l' ? "bg-indigo-50" : "bg-dirt"}`}>
                    <p className={`${direction === 'l' ? "text-gray-900" : "text-white"}`}>{message.message}</p>
                </div>
                <p className={`text-sm text-gray-700 italic`}>{moment(message.dateCreated).format("DD.MM.YY HH:mm")}</p>
            </div>
            <div className='w-[62px]'>
                {direction === 'r' && 
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-dirt">
                        <span className="font-medium text-white">{getInitials(getName())}</span>
                    </div>
                }
            </div>
        </div>
    );
}

export default MessageItem;