import { ArrowUpOnSquareIcon, DocumentIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "../buttons/SecondaryButton";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import StatusTag from "../tags/StatusTag";
import colors from "../../shared/colors";
import { addZeroIfOneDigit } from "../../shared/util";
import DocumentRelatedFileList from "../documents/DocumentRelatedFileList";

const DocumentContainer = ({ document, index, onUpload, onFileClick, onNoteClick }) => {
    const upload = async (file) => {
        setUploading(true);
        await onUpload(file, document);
        setUploading(false);
    }

    const onDrop = useCallback(acceptedFiles => {
        if(document.status !== "Changes required") {
            return;
        }

        upload(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
    const { onClick, ...rootProps } = getRootProps();
    const [uploading, setUploading] = useState(false);

    return (
        <div {...rootProps}>
            <input {...getInputProps()} />
            {
                <div className={`px-6 py-6 space-y-6 rounded ${isDragActive ? "bg-gray-100 border border-dashed" : "bg-gray-50 border border-gray-100"}`}>
                    <div className="flex flex-col w-full space-y-2 md:items-center md:flex-row md:space-y-0">
                        <p className="font-semibold md:w-3/5 text-md">{document.name}</p>
                        <div className="flex md:justify-end md:w-2/5">
                            <StatusTag status={document.status} />
                        </div>
                    </div>
                    <p className="text-gray-600 whitespace-pre">{document.description}</p>
                    <div className="flex flex-col items-end space-y-4 md:flex-row md:space-y-0">
                        <div className="w-full md:w-3/5">
                            <div className="flex flex-col space-y-6">
                                {document.file && <div className="flex items-center space-x-2 text-orange-500 cursor-pointer hover:text-orange-700" onClick={onFileClick}>
                                    <DocumentIcon width={22} />
                                    <p className="font-medium text-md">{document.file.name}</p>
                                </div>}
                                {document.status === "Changes required" &&
                                <SecondaryButton
                                    lIcon={<ArrowUpOnSquareIcon width={22} />}
                                    text="Upload document"
                                    className="max-w-[280px]"
                                    onClick={onClick}
                                    size="sm"
                                    spinning={uploading}
                                />}
                            </div>
                            
                        </div>
                        <div className="flex md:justify-end md:w-2/5">
                            {document.note && <div onClick={() => onNoteClick(document)} className="flex justify-end space-x-2 cursor-pointer text-dirt hover:text-coyoteb">
                                <ExclamationCircleIcon width={22} />
                                <span>Note</span>
                            </div>}
                        </div>
                    </div>
                    <DocumentRelatedFileList document={document} canEdit={false} />
                </div>
            }
        </div>
    );
}

export default DocumentContainer;