import axios from "axios";
import { toast } from "react-toastify";

export const isSuccess = (code) => code >= 200 && code < 300;

export const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export const getToken = () => {
    const token = localStorage.getItem("sol-token");
    if (!token || token === "undefined")
        return null;

    return token;
}

export const setToken = (token) => {
    removeToken();
    localStorage.setItem("sol-token", token);
}

export const removeToken = () => {
    localStorage.removeItem("sol-token");
}

export const getInitials = (name) => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];
    return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
}


export const toFirstUppercase = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export const getCountryList = async () => {
    const countries = await axios.get("https://restcountries.com/v3.1/all?fields=name,cca2");
    return countries.data.map(x => { return { value: x.cca2, display: x.name.common } });
}

export const showSuccess = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: null,
        theme: "light",
    });
}

export const showError = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: null,
        theme: "light",
    });
}

export const showNotification = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: null,
        type: "info",
        theme: "light",
    });
}

export const generatePaginationArray = (currentPage, numberOfPages) => {
    if (numberOfPages < 2) {
        return [];
    }

    if (numberOfPages <= 7) {
        return Array.from({ length: numberOfPages }, (_, i) => i + 1)
    }

    if ([1, 2].includes(currentPage) || [numberOfPages, numberOfPages - 1].includes(currentPage)) {
        return [1, 2, 3, -1, numberOfPages - 2, numberOfPages - 1, numberOfPages];
    }

    if (3 === currentPage) {
        return [1, 2, 3, 4, -1, numberOfPages - 1, numberOfPages];
    }

    if (4 === currentPage) {
        return [1, 2, 3, 4, 5, -1, numberOfPages];
    }

    if (numberOfPages - 2 === currentPage) {
        return [1, 2, -1, numberOfPages - 3, numberOfPages - 2, numberOfPages - 1, numberOfPages];
    }

    if (numberOfPages - 3 === currentPage) {
        return [1, -1, numberOfPages - 4, numberOfPages - 3, numberOfPages - 2, numberOfPages - 1, numberOfPages];
    }

    return [1, -1, currentPage - 1, currentPage, currentPage + 1, -1, numberOfPages];
}

export const changeDirection = (direction) => {
    return direction === 'down' ? 'up' : 'down';
}

export const isEnd = (direction, numberOfPages, currentPage) => {
    return (direction === 'up' && numberOfPages === currentPage)
        || (direction === 'down' && currentPage === 1);
}

export const downloadA = (url) => {
    const a = document.createElement('a');
    a.style = 'display: none';
    a.href = url;
    a.download = 'file.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

export const addZeroIfOneDigit = (n) => {
    return n > -10 && n < 10 ? "0" + n : n;
}

export const sortbyProperty = (arr, property, direction) => {
    return direction === "DESC" ? arr.sort(function(a, b){return b[property]  - a[property]}) 
        : arr.sort(function(a, b){return a[property]  - b[property]});
}

export const getPath = (url) => {
    var reg = /.+?:\/\/.+?(\/.+?)(?:#|\?|$)/;
    return reg.exec(url)[1];
}