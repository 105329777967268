import axiosClient from '../../shared/axiosClient';
import { handleResponse, handleError } from './response';

const getAll = (resource, model, config) => {
    return axiosClient
        .get(`/${resource}`, model, config)
        .then(handleResponse)
        .catch(handleError);
};

const getSingle = (resource, id, config) => {
    return axiosClient
        .get(`/${resource}/${id}`, config)
        .then(handleResponse)
        .catch(handleError);
};

const post = (resource, model, config, id) => {
    const url = id && id !== 0 ? `/${resource}/${id}` : `/${resource}`;

    return axiosClient
        .post(url, model, config)
        .then(handleResponse)
        .catch(handleError);
};

const put = (resource, id, model, config) => {
    return axiosClient
        .put(`/${resource}/${id}`, model, config)
        .then(handleResponse)
        .catch(handleError);
};

const patch = (resource, id, model, config) => {
    const url = id && id !== 0 ? `/${resource}/${id}` : `/${resource}`;
    return axiosClient
        .patch(url, model, config)
        .then(handleResponse)
        .catch(handleError);
};

const remove = (resource, id) => {
    return axiosClient
        .delete(`/${resource}/${id}`, {})
        .then(handleResponse)
        .catch(handleError);
};

export const apiProvider = {
    getAll,
    getSingle,
    post,
    put,
    patch,
    remove,
};