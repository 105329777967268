import DropdownField from "../../../components/forms/DropdownField";
import { useState } from "react";
import userApi from "../../../services/apis/userApi";
import EmailField from "../../../components/forms/EmailField";
import { changeField, getFirstError, isValid } from "../../../shared/validate";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { isSuccess } from "../../../shared/util";

const InviteUserForm = ({onInviteSent}) => {
    const emailDefault = {
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'Email address is required.',
                isValid: true,
            },
            {
                type: 'email',
                message: 'Please provide a valid email address.',
                isValid: true,
            }
        ],
    };
    const [email, setEmail] = useState(emailDefault);
    const [role, setRole] = useState('agent');

    const [sendInviteInProgress, setSendInviteInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const userService = userApi();

    const resetForm = () => {
        setEmail(emailDefault);
        setRole('agent');
    }

    const handleSendInviteButtonClicked = async () => {
        setSendInviteInProgress(true);

        const createUserResponse = await userService.post({
            username: email.value,
            role: role,
            password: 'NeWZeLAnd^:904'
        });

        if(isSuccess(createUserResponse.status)) {
            onInviteSent();
            setSendInviteInProgress(false);
            resetForm();
        } else {
            setErrorMessages([{ message: 'Something went wrong. Please contact system administrator.' }]);
            setSendInviteInProgress(false);
        }
    }

    const isFormValid = () => {
        return isValid([email]);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <form className="flex flex-col space-y-10" onSubmit={onFormSubmit}>
            <div className="flex flex-col space-y-4">
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">Email</label>
                    <EmailField
                        name='email'
                        placeholder='Email address'
                        value={email.value}
                        isValid={email.isValid || !email.showValidation}
                        onChange={(e) => setEmail(changeField(e, { ...email }))}
                        onBlur={(e) => setEmail({ ...email, showValidation: true })}
                        errorMessage={email.showValidation ? getFirstError(email) : ''}
                    />
                </div>
                <div className="flex flex-col space-y-1">
                    <label className="text-sm text-gray-700">User Type</label>
                    <DropdownField
                        name='user_type'
                        placeholder='User Type'
                        onChange={(option) => setRole(option.value)}
                        value={role}
                        options={[
                            {
                                display: 'Processing Agent',
                                value: 'agent'
                            },
                            {
                                display: 'Sales Agent',
                                value: 'sales'
                            },
                            {
                                display: 'Admin',
                                value: 'admin'
                            },
                            {
                                display: 'Compliance',
                                value: 'compliance'
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="">
                <PrimaryButton
                    text="Send Invite"
                    icon={<PaperAirplaneIcon width={24} />}
                    spinning={sendInviteInProgress}
                    onClick={handleSendInviteButtonClicked}
                    disabled={!isFormValid()}
                    type="submit"
                />
            </div>
            <div className="flex flex-col pl-2 mt-4 space-y-1">
                {errorMessages && errorMessages.map((errorMessage, index) => (
                    <span key={'add-user-err-message-' + index} className="text-red-600">{errorMessage.message}</span>
                ))}
            </div>
        </form>
    );
}

export default InviteUserForm;