import axios from 'axios';
import { getToken } from './util';

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosClient = axios.create(defaultOptions);

axiosClient.interceptors.request.use(
  async (config) => {
    const newConfig = config;
    const token = await getToken();
    if (token) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }

    return newConfig;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  },
);

export default axiosClient;
