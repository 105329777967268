import { useContext, useEffect, useState } from "react";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import { AuthContext } from "../../store/AuthContext";
import { DataContext } from "../../store/DataContext";
import questionnaireApi from "../../services/apis/questionnaireApi";
import { useNavigate, useParams } from "react-router-dom";
import { isSuccess, showSuccess } from "../../shared/util";
import { isValidQItem } from "../../shared/validateQuestionnaireField";
import FieldSwitch from "../../components/forms/FieldSwitch";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import axiosClient from "../../shared/axiosClient";

const FillOutQuestionnaire = () => {
    const { id, applicationId } = useParams();
    const context = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const dataContext = useContext(DataContext);
    const [section, setSection] = useState(0);
    const [savingInProgress, setSavinginProgress] = useState(false);
    const [savingAsDraftInProgress, setSavingAsDraftinProgress] = useState(false);
    const questionnaireService = questionnaireApi();
    const navigate = useNavigate();

    const [questionnaire, setQuestionnaire] = useState(null);

    useEffect(() => {
        getQuestionnaire();
    }, []);

    const getQuestionnaire = async () => {
        const getQuestionnaireResponse = await questionnaireService.getSingle(id);
        if (isSuccess(getQuestionnaireResponse.status)) {
            setQuestionnaire(getQuestionnaireResponse.data);
        }

        setIsLoading(false);
    }

    const getSection = () => {
        return questionnaire.sections[section];
    }

    const next = () => {
        if (section === questionnaire.sections.length - 1) {
            return;
        }

        setSection(section + 1);
    }

    const previous = () => {
        if (section === 0) {
            return;
        }

        setSection(section - 1);
    }

    const uploadFile = async (item) => {
        let formData = new FormData();
        formData.append('file', item.value);
        const fileUploadResponse = await axiosClient.post("questionnaires/" + questionnaire.id + "/items/" + item.id + '/file', formData, {
            headers: { "Content-type": "multipart/form-data" }
        });

        return fileUploadResponse;
    }

    const saveQuestionnaire = async (forSave) => {
        for(const sectionItem of forSave.sections) {
            const fileItems = sectionItem.items.filter(x => dataContext.getFieldType(x.fieldTypeId).slug === 'file');
            for(const fileItem of fileItems) {
                if(fileItem.value) {
                    const resp = await uploadFile(fileItem);
                    fileItem.value = null;
                }
            }
        }
        
        const saveQuestionnaireResponse = await questionnaireService.patch(id, forSave);

        if (isSuccess(saveQuestionnaireResponse.status)) {
            showSuccess("Questionnare has beed saved");
        }
    }

    const submit = async () => {
        setSavinginProgress(true);

        const tmpQestionnaire = { ...questionnaire };
        tmpQestionnaire.status = "Submitted";

        await saveQuestionnaire(tmpQestionnaire);

        setSavinginProgress(false);
    }

    const saveAsDraft = async () => {
        setSavingAsDraftinProgress(true);

        const tmpQestionnaire = { ...questionnaire };
        tmpQestionnaire.status = "Draft";

        await saveQuestionnaire(tmpQestionnaire);

        setSavingAsDraftinProgress(false);
    }

    const clearSection = () => {
        const tmpQestionnaire = { ...questionnaire };
        tmpQestionnaire.sections[section].items.forEach((item, index, itemArray) => {
            itemArray[index].value = "";
        });

        setQuestionnaire(tmpQestionnaire);
    }

    const isValid = () => {
        let valid = true;
        questionnaire.sections.forEach(section => {
            const sectionValid = section.items.some(x => !isValidQItem(x, dataContext.getFieldType(x.fieldTypeId).slug));
            if (sectionValid) {
                valid = false;
            }
        });

        return valid;
    }

    const setValue = (value, index, sectionIndex) => {
        const tmpSections = [...questionnaire.sections];
        tmpSections[sectionIndex].items[index].value = value;

        if(!value && dataContext.getFieldType(tmpSections[sectionIndex].items[index].fieldTypeId).slug === 'file') {
            tmpSections[sectionIndex].items[index].fileId = null;
        }

        setQuestionnaire({ ...questionnaire, sections: tmpSections });
    }

    return (
        <SidebarLayout>
            <div className="pb-16">
                <div className="flex flex-col p-8 mb-8 space-y-12 bg-white rounded">
                    {questionnaire && questionnaire.sections.map(((section, index) => (
                        <div>
                            <div className="mb-8">
                                <p className="text-2xl font-medium">SECTION {index + 1} - {section.name}</p>
                                <p>{section.description}</p>
                            </div>
                            <div className="flex flex-col space-y-6">
                                {section.items.map((item, itemIndex) => (
                                    <div className="flex flex-col items-center w-full space-y-2 md:space-y-0 md:space-x-4 md:flex-row">
                                        <div className="flex flex-col justify-center w-full md:w-1/3">
                                            <span className="font-medium text-gray-900">{index + 1}.{itemIndex + 1} {item.question}
                                                {item.isRequired && <span className="ml-1 text-red-500">*</span>}
                                            </span>
                                            <span className="text-sm text-gray-700 ">{item.description}</span>
                                        </div>
                                        <div className="flex justify-start w-full md:w-2/3">
                                            <FieldSwitch
                                                type={dataContext.getFieldType(item.fieldTypeId).slug}
                                                value={item.value}
                                                isRequired={item.isRequired}
                                                onChange={(value) => setValue(value, itemIndex, index)}
                                                size="sm"
                                                valid={isValidQItem(item, dataContext.getFieldType(item.fieldTypeId).slug)}
                                                className="w-full" 
                                                item={item}    
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )))}
                </div>
                <div className="flex space-x-4">
                    <SecondaryButton text="Cancel" to={"/applications/" + applicationId} className="md:w-[180px] w-full" />
                    <SecondaryButton text="Save as draft" onClick={saveAsDraft} spinning={savingAsDraftInProgress} className="md:w-[180px] w-full" />
                    <PrimaryButton spinning={savingInProgress} text="Save" onClick={submit} className="md:w-[180px] w-full" />
                </div>
            </div>
        </SidebarLayout>
    );
}

export default FillOutQuestionnaire;