import LogoBlack from '../images/logo-black.svg';
import { useContext } from "react";
import { UIContext } from "../../store/UIContext";
import { Link } from 'react-router-dom';

const PublicClientLayout = ({ children, footer, title }) => {    
    const uiContext = useContext(UIContext);

    return (
        <div className="relative w-screen h-screen overflow-x-hidden overflow-y-hidden bg-cultured">
            <div className={`absolute top-0 left-0 w-full h-full bg-gray-900 z-[17] transition-opacity ease-in-out backdrop-blur-xl duration-300 opacity-50 ${uiContext.showBackdrop ? "visible" : "invisible"}`}></div>
            <div className={`absolute top-0 left-0 w-full h-full bg-gray-900 z-[17] transition-opacity ease-in-out backdrop-blur-xl duration-300 opacity-25 ${uiContext.showLightBackdrop ? "visible" : "invisible"}`}></div>
            <div className='flex h-full'>
                <div className="relative h-full pb-72 md:pb-20 grow">
                    <div className="relative flex items-center py-6 w-full px-4 md:px-[136px] space-x-4 bg-white z-[8] drop-shadow">
                        <Link to="/">
                            <img width={92} src={LogoBlack} alt="Soland" />
                        </Link>
                        <div className="flex items-center justify-end space-x-8 grow">
                            
                        </div>
                    </div>
                    <div className="relative flex items-center py-4 w-full px-4 md:px-[136px] space-x-4 bg-white drop-shadow z-[8]">
                        <div className="flex flex-col items-center w-full space-y-4 md:space-y-0 md:flex-row">
                            <div className="flex flex-col w-full md:space-x-6 md:items-center md:flex-row md:divide-x">
                                <div className="flex items-center space-x-4">
                                    {title}
                                </div>
                            </div>
                            <div className="flex flex-col items-start w-full space-y-2 md:items-center md:space-x-6 md:space-y-0 md:justify-end md:flex-row grow">
                                <p className="text-gray-700">
                                    
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`px-4 md:px-[136px] py-4 md:p-8 h-full flex flex-col items-center overflow-y-auto grow`}>
                        <div className={`max-w-[840px] w-full pb-[200px]`}>
                            {children}
                        </div>
                    </div>
                    {footer && footer}
                </div>
            </div>
        </div>
    );
}

export default PublicClientLayout;