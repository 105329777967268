import { useContext, useState } from "react";
import PublicLayout from "../../components/layouts/PublicLayout";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../store/AuthContext";
import userApi from "../../services/apis/userApi";
import { isSuccess } from "../../shared/util";
import { changeField, getFirstError, isValid } from "../../shared/validate";
import TextField from "../../components/forms/TextField";

const AccountSetup = () => {
    const [firstName, setFirstName] = useState({
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'First name is required.',
                isValid: true,
            },
        ]
    });

    const [lastName, setLatName] = useState({
        value: '',
        isValid: false,
        isChanged: false,
        showValidation: false,
        validators: [
            {
                type: 'required',
                message: 'Last name is required.',
                isValid: true,
            }
        ]
    });

    const [saveAccountInProgress, setSaveAccountInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    const userService = userApi();

    const handleSaveButtonClick = async () => {
        setSaveAccountInProgress(true);
        setErrorMessages([]);

        const saveUserResponse = await userService.patch(authContext.session.userId, { firstName: firstName.value, lastName: lastName.value });
        if(isSuccess(saveUserResponse.status)) {
            authContext.setSession({...authContext.session, firstName: firstName.value, lastName: lastName.value});
            navigate('/');
        } else {
            setErrorMessages([{ message: 'Something went wrong. Please contact system administrator.' }]);
            setSaveAccountInProgress(false);
        }
    }

    const onFormSubmit = (e) => {
        e.preventDefault(); 
    }

    const isFormValid = () => {
        return isValid([firstName, lastName]);
    }
    
    return (
        <PublicLayout>
            <form className="text-center md:text-start" onSubmit={onFormSubmit}>
                <div className="flex flex-col mb-8 space-y-3">
                    <h2 className="text-4xl font-medium font-semibold font-primary">Profile Setup</h2>
                    <p className="text-thin font-primary text-silver600">Please enter your first and last name</p>
                </div>

                <div className="flex flex-col mb-6 mb-10 space-y-6">
                    <TextField
                        name='first_name'
                        placeholder='First name'
                        value={firstName.value}
                        isValid={firstName.isValid || !firstName.showValidation}
                        onChange={(e) => setFirstName(changeField(e, { ...firstName }))}
                        onBlur={(e) => setFirstName({ ...firstName, showValidation: true })}
                        errorMessage={firstName.showValidation ? getFirstError(firstName) : ''}
                    />
                    <TextField
                        name='last_name'
                        placeholder='Last name'
                        value={lastName.value}
                        isValid={lastName.isValid || !lastName.showValidation}
                        onChange={(e) => setLatName(changeField(e, { ...lastName }))}
                        onBlur={(e) => setLatName({ ...lastName, showValidation: true })}
                        errorMessage={lastName.showValidation ? getFirstError(lastName) : ''}
                    />
                </div>

                <div className="w-full">
                    <PrimaryButton
                        text="Save"
                        icon={<ArrowRightIcon color="white" width={24} />}
                        spinning={saveAccountInProgress}
                        onClick={handleSaveButtonClick}
                        disabled={!isFormValid()}
                        type="submit"
                    />
                </div>

                <div className="flex flex-col pl-2 mt-4 space-y-1">
                    {errorMessages && errorMessages.map((errorMessage, index) => (
                        <span key={'login-err-message-' + index} className="text-red-600">{errorMessage.message}</span>
                    ))}
                </div>
            </form>
        </PublicLayout>
    );
}

export default AccountSetup;