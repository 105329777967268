import { MagnifyingGlassIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import TextField from "../../components/forms/TextField";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import colors from "../../shared/colors";
import { useEffect, useState } from "react";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import questionnaireApi from "../../services/apis/questionnaireApi";
import { isSuccess, showSuccess } from "../../shared/util";
import RichTable from "../../components/tables/RichTable";
import { useNavigate } from "react-router-dom";
import NewFormForm from "./forms/NewFormForm";
import ConfirmModal from "../../components/modals/ConfirmModal";
import SecondaryButton from "../../components/buttons/SecondaryButton";

const Forms = () => {
    const [questionnaires, setQuestionnaires] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [sortByField, setSortByField] = useState('id');
    const [sortBy, setSortBy] = useState('DESC');
    const [searchTerm, setSearchTerm] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showAddFormForm, setShowAddFormForm] = useState(false);
    const [templates, setTemplates] = useState([]);

    const [confirmDeleteFormModalShow, setConfirmDeleteFormModalShow] = useState(false);
    const [confirmDeleteFormModalData, setConfirmDeleteFormModalData] = useState(null);

    const questionnaireService = questionnaireApi();
    const navigate = useNavigate();

    useEffect(() => {
        getQuestionnaires();
    }, [page, searchTerm, sortBy, sortByField])

    useEffect(() => { 
        getTemplates();
    }, []);

    const getTemplates = async () => {
        const getFormsResponse = await questionnaireService.getAll({ params: { "filter.isTemplate": 1, "limit": 0 }});
        if(isSuccess(getFormsResponse.status)) {

            const tmpTemplateList = getFormsResponse.data.data.map(x => { return { value: x.id, display: x.name } });
            tmpTemplateList.unshift({ value: null, display: "Blank questionnaire" });

            setTemplates(tmpTemplateList);
        }
    }

    const getQuestionnaires = async () => {
        setIsLoading(true);

        const params = {
            "filter.isTemplate": 1,
            "limit": 8,
            "page": page,
            "sortBy": sortByField + ":" + sortBy,
        };

        if (searchTerm) {
            params["filter.name"] = "$ilike:$or:" + searchTerm;
        }

        const getQuestionnairesResponse = await questionnaireService.getAll({ params: params });

        if (isSuccess(getQuestionnairesResponse.status)) {
            setQuestionnaires(getQuestionnairesResponse.data.data);
            setTotalPages(getQuestionnairesResponse.data.meta.totalPages);
        }

        setIsLoading(false);
    }

    const deleteFormClicked = (form) => {
        setConfirmDeleteFormModalData({
            form: form,
        });
        setConfirmDeleteFormModalShow(true);
    }

    const deleteForm = async (data) => {
        const deleteQuestionnaireResponse = await questionnaireService.remove(data.form.id);
        if(isSuccess(deleteQuestionnaireResponse.status)) {
            showSuccess("Form has been deleted");
            getQuestionnaires();
            getTemplates()
        }

        setConfirmDeleteFormModalShow(false);
    }

    const changeOrder = (field) => {
        if (field !== sortByField) {
            setSortByField(field);
            setSortBy("ASC");
        }

        setSortBy(sortBy === "ASC" ? "DESC" : "ASC");
    }

    const onNewFormAdded = (newForm) => {
        navigate('/forms/' + newForm.id + '/edit');
    }

    const questionnaireTableColumns = [
        {
            display: 'Name',
            sortable: true,
            sortName: 'name',
            width: 90,
            column: 'name',
        },
    ];

    const tableActions = [
        {
            title: 'Delete',
            icon: <TrashIcon width={20} />,
            onClick: (item) => deleteFormClicked(item)
        },
        {
            title: 'Edit',
            icon: <PencilIcon width={20} />,
            confirm: false,
            onClick: (item) => {
                navigate('/forms/' + item.id + '/edit')
            }
        },
    ];

    return (
        <SidebarLayout title="Forms"
            showRightSidebar={showAddFormForm} 
            rightSidebarTitle="Add new form"
            rightSidebarContent={<NewFormForm onFormSaved={onNewFormAdded} templates={templates}/>}
            onRightSidebarClose={() => setShowAddFormForm(false)}>
            <div className="flex flex-col mb-4 space-y-3 md:space-y-0 md:space-x-4 md:flex-row">
                <div className="flex flex-col w-full space-y-4 md:flex-row md:space-y-0">
                    <div className="w-full md:w-[380px]">
                        <TextField placeholder="Search" icon={<MagnifyingGlassIcon width={20} color={colors.silver600} />}
                            value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    </div>
                    <div className="flex justify-end w-full space-x-2">
                        <PrimaryButton className="w-full md:w-[220px]" text="Add form" onClick={() => setShowAddFormForm(true)} />
                    </div>
                </div>
            </div>
            <RichTable
                columns={questionnaireTableColumns}
                items={questionnaires}
                tableActions={tableActions}
                loading={isLoading}
                page={page}
                setPage={(page) => setPage(page)}
                totalPages={totalPages}
                changeOrder={changeOrder}
                sortByField={sortByField}
                sortBy={sortBy}
            />
            <ConfirmModal 
                show={confirmDeleteFormModalShow}
                onClose={() => setConfirmDeleteFormModalShow(false)}
                onConfirm={deleteForm}
                data={confirmDeleteFormModalData}
                title="Confirm form delete"
                message="Are you sure you want to delete this form?"
            />
        </SidebarLayout>
    );
}

export default Forms;