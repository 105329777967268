import { BellIcon, DocumentArrowUpIcon, DocumentTextIcon, UserPlusIcon, DocumentCheckIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useRef } from "react";
import { useOutsideClick } from "../../hooks/CustomHooks";
import { UIContext } from "../../store/UIContext";
import colors from "../../shared/colors";
import { AuthContext } from "../../store/AuthContext";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import notificationApi from "../../services/apis/notificationApi";

const NotificationDropdown = () => {
    const authContext = useContext(AuthContext);
    const uiContext = useContext(UIContext);
    const contentRef = useRef();
    const ref = useOutsideClick(() => closeMenu(), contentRef);
    const navigate = useNavigate();
    const notificationService = notificationApi();

    const readNotification = async (notification) => {
        if (notification.link) {
            window.location = notification.link;
        }

        const readNotificationResponse = await notificationService.patch(null, { ids: [notification.id] });
    }

    const getIcon = (notification) => {
        switch (notification.type) {
            case 'application_created': {
                return <UserPlusIcon color={colors.blue[500]} width={22} />;
            }
            case 'compliance_report_file_created': {
                return <DocumentCheckIcon color={colors.green[500]} width={22} />;
            }
            case 'document_uploaded': {
                return <DocumentArrowUpIcon color={colors.green[500]} width={22} />;
            }
            case 'document_changes_required': {
                return <DocumentTextIcon color={colors.orange[500]} width={22} />;
            }
            case 'questionnaire_changes_required': {
                return <DocumentTextIcon color={colors.orange[500]} width={22} />;
            }
            default: {
                return null;
            }
        }
    }

    const getContent = () => {
        return (
            <div ref={contentRef} className="md:w-[330px] md:max-h-[460px] w-screen overflow-y-auto space-y-0.5">
                {authContext.notifications && authContext.notifications.map(x => (
                    <div onClick={() => readNotification(x)} className={`px-4 py-4 space-y-1 cursor-pointer hover:bg-slate-200 ${x.isRead ? "bg-white" : "bg-slate-100"}`}>
                        <div className="flex items-center space-x-2 font-medium text-gray-900">
                            {getIcon(x)}
                            <p className="">{x.title}</p>
                        </div>
                        <p className="text-sm text-gray-600">{x.message}</p>
                        <p className="text-sm text-gray-500 text-end">{moment(x.createdAt).fromNow()}</p>
                    </div>
                ))}
                {authContext.notifications && authContext.notifications.length === 0 &&
                    <div className="px-4 pt-4 pb-4">
                        <p className="italic text-center text-gray-700">(No notifications)</p>
                    </div>
                }
            </div>
        );
    }

    const openMenu = () => {
        uiContext.setNotificationDropdownContent(getContent());
        uiContext.setShowNotificationDropdown(true);
    }

    const closeMenu = () => {
        uiContext.setNotificationDropdownContent(null);
        uiContext.setShowNotificationDropdown(false);
    }

    const onProfileClick = () => {
        if (uiContext.showNotificationDropdown) {
            closeMenu();
        } else {
            openMenu();
        }
    }

    useEffect(() => {
        return (() => {
            closeMenu()
        });
    }, []);

    const unreadNumber = authContext.notifications ? authContext.notifications.filter(x => !x.isRead).length : 0;

    return (
        <div ref={ref}>
            <div className="relative flex items-center space-x-2 cursor-pointer" onClick={onProfileClick}>
                <BellIcon width={24} height={24} />
                {authContext.notifications && unreadNumber !== 0 &&
                    <div className="absolute flex items-center justify-center w-6 h-6 bg-red-500 rounded-full left-1 top-2">
                        <span className="text-xs text-white">{unreadNumber}</span>
                    </div>}
            </div>
        </div>
    );
}

export default NotificationDropdown;