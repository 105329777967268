import { getPath, removeToken, showError } from "../../shared/util";

export function handleResponse(response) {
    return response;
}
    
export function handleError(error) {
    let message = "";

    console.log(error);

    if(error.code === 'ERR_NETWORK') {
        return {
            status: 500,
            message: "Problem with connection",
        }
    }

    message = error.response.data.message;

    if(error.response.status === 401) {
      const path = getPath(error.request.responseURL);
      console.log(path);
      if(!["/sessions", "/reset-password", "/activate"].includes(path)) {
        removeToken();
        window.location.replace('/login');
      }
    } else {
        showError(message);
    }
  
    return error.response;
}