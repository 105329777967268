import Tag from "./Tag"

const UserStatusTag = ({props, status}) => {

    const colors = {
        pending: {
            bgColor: 'orange-100',
            color: 'orange-700',
            display: 'Invite Sent',
        },
        not_invited: {
            bgColor: 'green-100',
            color: 'green-700',
            display: 'Not Invited',
        },
        active: {
            bgColor: 'green-100',
            color: 'green-700',
            display: 'Active',
        },
    };

    const getColor = () => {
        return colors[status].color;
    }

    const getBgColor = () => {
        return colors[status].bgColor;
    }

    const getText = () => {
        return colors[status].display;
    }

    return <Tag 
        {...props}
        color={getColor()}
        bgColor={getBgColor()}
        text={getText()}
    />
}

export default UserStatusTag;