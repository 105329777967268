export const applicationStatuses = [
    {
        value: "Know Your Client",
        display: "Know Your Client",
    },
    {
        value: "Waiting for payment",
        display: "Waiting for payment",
    },
    {
        value: "Waiting for agent assignment",
        display: "Waiting for agent assignment",
    },
    {
        value: "Compliance completed investigation",
        display: "Compliance completed investigation",
    },
    {
        value: "Changes required",
        display: "Changes required",
    },
    {
        value: "Pending agent's approval",
        display: "Pending agent's approval",
    },
    {
        value: "Waiting for physical copy",
        display: "Waiting for physical copy",
    },
    {
        value: "Submitting to authority",
        display: "Submitting to authority",
    },
    {
        value: "Submitted to authority",
        display: "Submitted to authority",
    },
    {
        value: "Approved",
        display: "Approved",
    },
    {
        value: "Rejected",
        display: "Rejected",
    },
];