import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { applicationMemberRelationTypes } from "../../data/applicationMemberRelationType";

const ApplicationRelatedMemberItem = ({ member, selected, onClick, canDelete, onDeleteClicked, canEdit, onEditClicked }) => {
    const { setNodeRef, transform, transition } = useSortable({ id: member.id });

    const memberTypeLabel = (memberType) => {
        if (memberType === 'applicant') {
            return null;
        }

        const relationType = applicationMemberRelationTypes.find(item => item.value === memberType);
        return relationType ? relationType.display : null;
    }

    return (
        <div
            className={`w-[240px] h-[120px] p-2 flex flex-col space-y-4 items-center cursor-pointer shadow-md group ${selected ? "bg-slate-200" : "bg-white"}`}
            key={member.id}
            ref={setNodeRef}
            onClick={onClick}
            style={{
                transform: CSS.Transform.toString(transform),
                transition: transition
            }}
        >
            <div className="flex w-full min-h-[22px]">
                <div className="flex w-1/2">
                    {canEdit &&
                        <PencilIcon className="invisible text-gray-800 group-hover:visible outline-0"
                            width={22} onClick={(e) => {
                                onEditClicked();
                                e.stopPropagation();
                            }}
                        />
                    }
                </div>
                <div className="flex justify-end w-1/2">
                    {canDelete &&
                        <XMarkIcon
                            className="invisible text-gray-800 group-hover:visible justify-self-stretch outline-0"
                            width={22}
                            onClick={(e) => {
                                onDeleteClicked();
                                e.stopPropagation();
                            }}
                        />
                    }
                </div>
            </div>
            <div className="flex flex-col items-center">
                <span className="font-bold">{member.firstName} {member.lastName}</span>
                <div className="text-sm">{memberTypeLabel(member.memberType)}</div>
            </div>
        </div>
    );
}

export default ApplicationRelatedMemberItem;