import { createContext, useState } from "react";

export const SidebarMenuContext = createContext({
    userManagementShowSubItems: false,
});

const SidebarMenuContextProvider = ({ children }) => {
    const [userManagementShowSubItems, setUserManagementShowSubItems] = useState();

    const value = {
        userManagementShowSubItems: userManagementShowSubItems,
        setUserManagementShowSubItems: setUserManagementShowSubItems,
    };

    return (
        <SidebarMenuContext.Provider value={value}>{children}</SidebarMenuContext.Provider>
    );
}

export default SidebarMenuContextProvider;