import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import ProtectedElement from "../../shared/ProtectedElement";

const SidebarItem = ({ title, link, onClick, icon, isActive, subitems, showSubitems, setShowSubitems }) => {
    const getContent = () => {
        const content = <>
            {icon && icon}
            <p>{title}</p>
            {subitems && showSubitems && <ChevronUpIcon width={22} className="cursor-pointer" />}
            {subitems && !showSubitems && <ChevronDownIcon width={22} className="cursor-pointer" />}
        </>;

        let className = "flex items-center px-4 py-4 space-x-4 rounded-md cursor-pointer hover:bg-opacity-[0.1] hover:bg-white hover:text-white";
        className += isActive ? " bg-opacity-[0.2] bg-white text-white" : " text-gray-200";

        return subitems
            ? <div onClick={() => setShowSubitems(!showSubitems)} className={className}>{content}</div>
            : link ? <Link className={className} to={link}>{content}</Link> : <div className={className} onClick={onClick}>{content}</div>
    }

    const getSubItem = (subitem) => {
        return (
            <Link to={subitem.link} className={`flex items-center px-4 py-4 pl-10 rounded-md space-x-4 hover:bg-opacity-[0.1] hover:bg-white ${subitem.isActive ? " bg-opacity-25 bg-white text-white" : " text-gray-200"}`}>
                <p>{subitem.title}</p>
            </Link>
        );
    }

    return (
        <>
            {getContent()}
            {subitems && showSubitems &&
                <div className="flex flex-col space-y-1">
                    {subitems.map((subitem, index) => (
                        subitem.protected && subitem.protected.length > 0
                            ?
                            <ProtectedElement key={"subitem-item-" + index} roles={subitem.protected}>
                                {getSubItem(subitem)}
                            </ProtectedElement>
                            :
                            getSubItem(subitem)
                    ))}
                </div>
            }
        </>
    );
}

export default SidebarItem;