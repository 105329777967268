import { ApiCore } from "../utilities/core";

const resetPasswordEmailApi = () => {
    return new ApiCore({
        getAll: false,
        getSingle: false,
        post: true,
        put: false,
        patch: false,
        remove: false,
        url: 'reset-password-email'
    });
}

export default resetPasswordEmailApi;