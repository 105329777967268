import { useEffect, useState } from "react";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { isSuccess, showSuccess } from "../../shared/util";
import { CircleFlag } from "react-circle-flags";
import DropdownField from "../../components/forms/DropdownField";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import AddProgramForm from "./forms/AddProgramForm";
import programApi from "../../services/apis/programApi";
import BaseModal from "../../components/modals/BaseModal";
import SSelect from "../../components/forms/SSelect";

const Programs = () => {
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(null);
    const [programs, setPrograms] = useState(null);
    const [addProgramModalOpen, setAddProgramModalOpen] = useState(false);
    const programService = programApi();
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [selectedProgram, setSelectedProgram] = useState(null);

    useEffect(() => {
        getPrograms();
    }, []);

    const getProgramsForDisplay = () => {
        let list = [];

        if (!country) {
            list = programs;
        } else {
            list = programs ? programs.filter(x => x.countryId === country) : [];
        }

        return list.sort(function (a, b) { return a.countryId - b.countryId });
    }

    const getPrograms = async (countryForSelect) => {
        const getProgramsResponse = await programService.getAll({ params: { "limit": 0 } });
        if (isSuccess(getProgramsResponse.status)) {
            const distinctCountryIds = [...new Set(getProgramsResponse.data.data.map(x => x.countryId))];
            const countryList = distinctCountryIds.map(x => {
                const obj = getProgramsResponse.data.data.find(y => y.countryId === x);
                return {
                    value: obj.countryId,
                    display: obj.country.name,
                    icon: <CircleFlag countryCode={obj.country.slug} width={22} />
                };
            });

            countryList.unshift({ value: null, display: 'All countries' })

            if (countryList.length > 0) {
                setCountry(countryForSelect ? countryForSelect : countryList[0].value);
            }
            setCountries(countryList);
            setPrograms(getProgramsResponse.data.data);
        }
    }

    const programAdded = (countryForSelect) => {
        getPrograms(countryForSelect);
        setAddProgramModalOpen(false);
        showSuccess("Program has been added!")
    }

    const deleteProgram = async () => {
        const deleteProgramResponse = await programService.remove(selectedProgram.id);
        if(isSuccess(deleteProgramResponse.status)) {
            showSuccess("Program has been deleted");
            setSelectedProgram(null);
            setShowConfirmDeleteModal(false);
            getPrograms();
        }
    }

    const handleDeleteProgramClicked = (program) => {
        setSelectedProgram(program);
        setShowConfirmDeleteModal(true);
    } 

    return (
        <SidebarLayout title="Programs"
            showRightSidebar={addProgramModalOpen}
            rightSidebarTitle="Add program"
            rightSidebarContent={<AddProgramForm onProgramAdded={programAdded} selectedCountry={country} programs={programs} />}
            onRightSidebarClose={() => setAddProgramModalOpen(false)}>
            <div className="flex flex-col space-y-8">
                <div className="flex flex-col space-y-4 md:flex-row md:space-y-0">
                    <div className="flex-col space-y-1 flex md:w-[340px] w-full max-w-full">
                        <label className="text-sm text-gray-700">Country</label>
                        <SSelect
                            name='country'
                            placeholder='Country'
                            onChange={(option) => { setCountry(option.value) }}
                            value={country}
                            options={countries}
                        />
                    </div>
                    <div className="flex justify-end w-full">
                        <PrimaryButton className="w-full md:w-[220px]" text="Add program" onClick={() => setAddProgramModalOpen(true)} />
                    </div>
                </div>
                <div className="bg-white rounded-md shadow-sm divide-y-[1px] divide-gray-200">
                    {programs && getProgramsForDisplay().map(program => (
                        <div key={"program-item-" + program.id} className="flex flex-col items-center px-6 py-4 space-y-4 md:space-y-0 md:flex-row">
                            <div className="flex items-center space-x-2 md:w-1/2">
                                <CircleFlag countryCode={program.country.slug} width={22} />
                                <p className="font-medium text-gray-900">{program.name}</p>
                            </div>
                            <div className="flex w-full space-x-12 md:justify-end md:w-1/2">
                                <div className="flex flex-col w-full space-y-2 md:justify-end md:flex-row md:space-y-0 md:space-x-4">
                                    <SecondaryButton size="sm" text={"Manage documents"} to={`/programs/${program.id}/documents`} />
                                    <SecondaryButton size="sm" text={"Manage questionnaire"} to={`/programs/${program.id}/questionnaire`} />
                                </div>
                                <XMarkIcon onClick={() => handleDeleteProgramClicked(program)} width={22} className="hidden text-gray-700 cursor-pointer md:block hover:text-gray-500" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <BaseModal show={showConfirmDeleteModal} onClose={() => setShowConfirmDeleteModal(false)}>
                <div className="flex flex-col space-y-12 w-[540px]">
                    <div className="flex flex-col space-y-6">
                        <p className="text-xl font-medium">Confirm program deletion</p>
                        <div className="flex flex-col space-y-4">
                            <p className="">Are you sure you want to delete this program?</p>
                        </div>
                    </div>
                    <div className="flex space-x-4">
                        <PrimaryButton className="w-1/2" onClick={() => deleteProgram()} text="Confirm" />
                        <SecondaryButton className="w-1/2" onClick={() => setShowConfirmDeleteModal(false)} text="Cancel" />
                    </div>
                </div>
            </BaseModal>
        </SidebarLayout>
    );
}

export default Programs;