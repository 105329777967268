import { isEmail } from "./validate";

export const isValidQItem = (field, type) => {
    if(type === 'toggle') {
        return true;
    }

    if(type === 'file' && field.fileId) {
        return true;
    }

    if(field.isRequired && !field.value) {
        return false;
    }

    let valid = true;

    if(type === 'text-email') {
        valid = isEmail(field.value);
    }

    return valid;
} 