import LogoBlack from '../images/logo-black.svg';

const AppLoader = () => {
    return (
        <div className="flex flex-col items-center justify-center w-screen h-screen space-y-3 opacity-75">
            <img className="" width={340} src={LogoBlack} alt="Soland" />
            <p className="text-2xl font-medium">YOUR WORLD, YOUR CHOICE</p>
        </div>
    );
}

export default AppLoader;