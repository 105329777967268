import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import TextArea from "../forms/TextArea";
import TextField from "../forms/TextField";

const SectionDetailsForm = ({ name, setName, description, setDescription, onClose, onSave }) => {
    return (
        <div className="flex flex-col p-4 space-y-4">
            <div className="flex flex-col space-y-1">
                <label className="text-sm text-gray-700">Title</label>
                <TextField
                    name='title'
                    placeholder='Title'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="flex flex-col space-y-1">
                <label className="text-sm text-gray-700">Description</label>
                <TextArea
                    rows={4}
                    name='description'
                    placeholder='Description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>
            <div className="flex items-end pt-4 space-x-4">
                <div className="w-full">
                    <SecondaryButton
                        onClick={onClose}
                        text="Cancel"
                    />
                </div>
                <div className="w-full">
                    <PrimaryButton
                        disabled={!name}
                        onClick={onSave}
                        text="Save section"
                    />
                </div>
            </div>
        </div>
    );
}

export default SectionDetailsForm;