import { PlusCircleIcon } from "@heroicons/react/24/outline";
import QuestionListSortable from "./QuestionListSortable";
import QuestionItemForm from "./QuestionItemForm";

const QuestionnaireTable = ({items, showAddQuestionForm, onAddQuestionClick, onAddQuestionClose, onAddQuestion, question, 
    onChangeQuestion, onEditClicked, onEdit, onDeleteClicked, isValid, selectedIndex, onSetItems, sectionDetailsForm,
    loadingFieldValues, fieldValues, addFieldValue}) => {

    return (
        <div className="bg-white rounded-md drop-shadow-sm">
            <div className="mb-4">
                {sectionDetailsForm}
            </div>            
            <div className="flex w-full px-8 py-4 bg-gray-50 rounded-t-md border-b-[1.5px] border-b-slate-200 min-w-min">
                <div className="flex space-x-1 min-w-[2%] md:w-[2%] justify-start items-center">
                </div>
                <div className="flex space-x-1 min-w-[210px] md:w-[28%] justify-start items-center">
                    <span className="text-sm text-gray-500">Question</span>
                </div>
                <div className="flex space-x-1 min-w-[210px] md:w-[28%] justify-start items-center">
                    <span className="text-sm text-gray-500">Note</span>
                </div>
                <div className="flex space-x-1 min-w-[210px] md:w-[22%] justify-start items-center">
                    <span className="text-sm text-gray-500">Field Type</span>
                </div>
                <div className="flex space-x-1 min-w-[210px] md:w-[6%] justify-start items-center">
                    <span className="text-sm text-gray-500">Required</span>
                </div>
                <div className="flex space-x-1 min-w-[105px] md:w-[14%] justify-start items-center">
                </div>
            </div>
            {items && items.length === 0 &&
                <div className="flex justify-center w-full py-8">
                    <span className="w-full text-left text-gray-400 px-14 text-md">There are currently no items in the list.</span>
                </div>
            }
            <div className="overflow-x-auto">
                <QuestionListSortable
                    items={items}
                    onSetItems={onSetItems}
                    useDragHandle
                    question={question}
                    onChangeQuestion={onChangeQuestion}
                    onEdit={onEdit}
                    isValid={isValid}
                    showAddQuestionForm={showAddQuestionForm}
                    onEditClicked={onEditClicked}
                    onDeleteClicked={onDeleteClicked}
                    onAddQuestionClose={onAddQuestionClose}
                    selectedIndex={selectedIndex}
                    loadingFieldValues={loadingFieldValues}
                    fieldValues={fieldValues}
                    addFieldValue={addFieldValue}
                />

                {showAddQuestionForm && question && !question.id
                    ?
                    <QuestionItemForm
                        question={question}
                        onChangeQuestion={onChangeQuestion}
                        onAddQuestion={onAddQuestion}
                        onAddQuestionClose={onAddQuestionClose}
                        loadingFieldValues={loadingFieldValues}
                        fieldValues={fieldValues}
                        addFieldValue={addFieldValue}
                    />
                    :
                    <div onClick={onAddQuestionClick}
                        className="flex w-full min-w-min px-8 py-5 text-gray-900 hover:text-gray-600 border-b-[1.5px] border-b-slate-200 space-x-2 cursor-pointer">
                        <PlusCircleIcon width={22} />
                        <span className='underline'>Add question</span>
                    </div>
                }
            </div>
        </div>
    );
}

export default QuestionnaireTable;