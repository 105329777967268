import TextField from "../forms/TextField";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import TextArea from "../forms/TextArea";
import Toggle from "../forms/Toggle";

const DocumentItemForm = ({ document, onChangeDocument, onAddDocument, onAddDocumentClose, isValid, memberType }) => {
    const getDocumentChange = (name, value) => {
        const tmpDocument = { ...document };
        tmpDocument[name] = value;

        return tmpDocument;
    }

    return (
        <div className="flex w-full flex-col md:flex-row">
            <div className="flex w-full flex-col px-8 py-5 border-b-[1.5px] border-b-slate-200">
                <div className="flex w-full min-w-min ">
                    <div className="flex space-x-1 min-w-[2%] md:w-[2%] justify-start items-center">
                    </div>
                    <div className="flex min-w-[210px] space-x-2 md:w-[30%] items-start">
                        <TextField
                            className="w-full mr-2"
                            value={document.name}
                            onChange={(e) => onChangeDocument(getDocumentChange('name', e.target.value))}
                        />
                    </div>
                    <div className="flex min-w-[210px] space-x-2 md:w-[60%] items-start">
                        <TextArea
                            className="w-full mr-2 ml-8"
                            value={document.description}
                            rows={4}
                            onChange={(e) => onChangeDocument(getDocumentChange('description', e.target.value))}
                        />
                    </div>
                </div>
                {memberType == null &&
                    <div className="flex w-full mt-8 space-x-8 -ml-4 flex-col md:flex-row">
                        <div className="flex space-x-1 min-w-[2%] md:w-[2%] justify-start items-center">
                        </div>
                        <div className="flex items-start space-y-1">
                            <Toggle
                                value={document.applicant}
                                onChange={(e) => onChangeDocument(getDocumentChange('applicant', !document.applicant))}
                            />
                            <span className="ml-2">Principal Applicant</span>
                        </div>
                        <div className="flex items-start space-y-1">
                            <Toggle
                                value={document.spouse}
                                onChange={(e) => onChangeDocument(getDocumentChange('spouse', !document.spouse))}
                            />
                            <span className="ml-2">Spouse</span>
                        </div>
                        <div className="flex items-start space-y-1">
                            <Toggle
                                value={document.dependent16AndOver}
                                onChange={(e) => onChangeDocument(getDocumentChange('dependent16AndOver', !document.dependent16AndOver))}
                            />
                            <span className="ml-2">Adult dependent</span>
                        </div>
                        <div className="flex items-start space-y-1">
                            <Toggle
                                value={document.dependentUnder16}
                                onChange={(e) => onChangeDocument(getDocumentChange('dependentUnder16', !document.dependentUnder16))}
                            />
                            <span className="ml-2">Minor dependent</span>
                        </div>
                    </div>
                }
            </div>
            <div className="flex min-w-[105px] space-x-4 md:w-[8%] items-start justify-center mt-8">
                <CheckIcon className='text-green-600 cursor-pointer hover:text-green-900' onClick={onAddDocument} width={22} />
                <XMarkIcon className='text-gray-900 cursor-pointer hover:text-gray-600' onClick={onAddDocumentClose} width={22} />
            </div>
        </div>
    );
}

export default DocumentItemForm;