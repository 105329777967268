import DocumentListItem from "./DocumentListItem";

const DocumentList = ({documents, onStatusChangeClick, onDocumentDownload, canEdit, getDocuments}) => {
    return (
        <div className="flex flex-col space-y-4">
            {documents.map((document, index) => (
                <DocumentListItem 
                    getDocuments={getDocuments}
                    key={"document-item-" + document.id}
                    document={document} 
                    index={index} 
                    onStatusChangeClick={onStatusChangeClick} 
                    onFileClick={onDocumentDownload}
                    canEdit={canEdit}
                />
            ))}
        </div>
    );
}

export default DocumentList;