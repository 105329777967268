import { PaperAirplaneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import colors from "../../shared/colors";
import { useContext, useEffect, useRef, useState } from "react";
import { UIContext } from "../../store/UIContext";
import TextArea from "../../components/forms/TextArea";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import MessageItem from "./MessageItem";
import { AuthContext } from "../../store/AuthContext";
import conversationApi from "../../services/apis/conversationApi";
import { isSuccess, sortbyProperty } from "../../shared/util";

const ConversationPane = () => {
    const uiContext = useContext(UIContext);
    const authContext = useContext(AuthContext);
    const conversationService = conversationApi();
    const [message, setMessage] = useState("");
    const [sendingInProgress, setSendingInProgress] = useState(false);
    const [pageMessage, setPageMessages] = useState(1);
    const [totalPagesMessages, setTotalPagesMessages] = useState(1);

    useEffect(() => {
        setPageMessages(1);
    }, [authContext.selectedConversation])

    const chatPane = useRef();

    const [messages, setMessages] = useState(null);

    useEffect(() => {
        if (authContext.selectedConversation) {
            fetchMessages();
        }
    }, [authContext.selectedConversation, pageMessage]);


    const fetchMessages = async () => {
        const getMessagesResponse = await conversationService.getSingle(authContext.selectedConversation.id, { params: {
            "sortBy": "createdAt:ASC",
            "limit": 20,
            "page": pageMessage,
        }});
        if(isSuccess(getMessagesResponse.status)) {
            const tmpMessages = getMessagesResponse.data.data;
            const firstUnreadIndex = tmpMessages.findIndex(x => !didUserRead(x));
            tmpMessages[firstUnreadIndex] = {...tmpMessages[firstUnreadIndex], showUnreadMessage: true};
            setTotalPagesMessages(getMessagesResponse.data.meta.totalPages)

            if (pageMessage === 1) {
                setMessages(tmpMessages);
            } else {
                setMessages(prevMessages => [...prevMessages, ...tmpMessages]);
            }
        }
    }

    const didUserRead = (message) => {
        return message.authorId === authContext.session.userId || message.reads.map(x => x.user.id).includes(authContext.session.userId);
    }

    const sendMessage = async () => {
        setSendingInProgress(true);

        const createMessageResponse = await conversationService.post({ message: message }, null, authContext.selectedConversation.id);
        if (isSuccess(createMessageResponse.status)) {
            setPageMessages(1);
            fetchMessages();
            setMessage("");
        }

        setSendingInProgress(false);
    }

    const readMessages = async () => {
        const messageIds = messages.filter(x => !didUserRead(x)).map(x => x.id);
        if(messageIds.length === 0) {
            return;
        }

        const readMessagesResponse = await conversationService.patch(null, { ids: messageIds });

        if(isSuccess(readMessagesResponse.status)) {
            fetchMessages();
        }
    }

    const handleScrollMessages = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (pageMessage > totalPagesMessages || pageMessage === -1) {
            return
        }

        if (scrollTop + clientHeight >= scrollHeight) {
            setPageMessages(pageMessage + 1);
        }
    };

    return (
        <div className="flex flex-col h-full overflow-y-hidden">
            <div className='flex flex-col h-full py-6 border'>
                <div className="flex items-center px-4 space-x-4 py-[12px] border-b">
                    <XMarkIcon width={38} color={colors.gray[700]} className="cursor-pointer hover:text-gray-500" onClick={() => uiContext.setShowSecondPane(false)} />
                    <h3 className="text-xl font-semibold text-gray-600">Conversation</h3>
                    {/* <h3>|</h3>
                    <h3 className="text-xl font-semibold text-gray-600">Documents</h3> */}
                </div>
                <div className='flex flex-col overflow-y-auto grow'>
                    <div onScroll={handleScrollMessages} ref={chatPane} className="h-full overflow-y-auto scroll-smooth">
                        <div className="flex flex-col justify-end px-4 py-2 space-y-8">
                            {messages &&
                            <>
                                {messages.map((message) => (
                                    <div>
                                        {message.showUnreadMessage &&
                                            <div className="flex justify-center mb-2">
                                                <p className="px-4 py-2 font-medium text-center text-gray-900 border-2 rounded-full w-fit">New messages</p>
                                                {/* <div className={`w-full h-1 bg-gray-500`}></div> */}
                                            </div>
                                        }
                                        <div className={`px-6`}>
                                            <MessageItem message={message} />
                                        </div>
                                    </div>
                                ))}
                            </>
                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-col items-end px-8 pt-4 space-y-1'>
                    <TextArea
                        value={message}
                        className="w-full"
                        onChange={(e) => setMessage(e.target.value)}
                        onFocus={readMessages}
                    />
                    <PrimaryButton
                        spinning={sendingInProgress}
                        onClick={sendMessage}
                        disabled={!message}
                        className="w-[194px]"
                        icon={<PaperAirplaneIcon width={22} color={colors.white} />}
                        text="Send"
                        size="sm"
                    />
                </div>
            </div>
        </div>
    );
}

export default ConversationPane;