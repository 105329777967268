import { useEffect, useState } from "react";
import SidebarLayout from "../../components/layouts/SidebarLayout";
import documentApi from "../../services/apis/documentApi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { isSuccess, showSuccess } from "../../shared/util";
import DocumentsForm from "../../components/documents/DocumentsForm";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";

const RequestNewDocuments = () => {
    const [existingDocuments, setExistingDocuments] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [saveDocumentsInProgress, setSaveDocumentsInProgress] = useState(false);

    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const memberType = searchParams.get("memberType");
    const memberId = searchParams.get("memberId");
    const documentService = documentApi(id, 'applications/');

    const navigate = useNavigate();

    useEffect(() => {
        getDocuments();
    }, []);

    const getDocuments = async () => {
        const getDocumentsResponse = await documentService.getAll();

        if (isSuccess(getDocumentsResponse.status)) {
            // setExistingDocuments(getDocumentsResponse.data.data);
            setDocuments(getDocumentsResponse.data.data);
        }
    }

    const handelSave = async (documents) => {
        setSaveDocumentsInProgress(true);

        // const tmpDocuments = existingDocuments.concat(documents);

        const tmpDocuments = [...documents];
        tmpDocuments.forEach((item, index, itemArray) => {
            if (item.new && memberId) {
                itemArray[index].applicationMemberId = +memberId
            }

            itemArray[index].orderNumber = index + 1;

            if (item.id === -1) {
                delete itemArray[index].id;
            }
            if (item.edited) {
                delete itemArray[index].edited;
            }
            if (item.new) {
                delete itemArray[index].new;
            }
        });

        const saveDocumentsResponse = await documentService.patch(null, tmpDocuments);

        if (isSuccess(saveDocumentsResponse.status)) {
            showSuccess("Documents requested successfully");
            getDocuments();
        }

        setSaveDocumentsInProgress(false);
    }

    return (
        <SidebarLayout>
            {documents && <div className="flex flex-col space-y-8">
                <DocumentsForm
                    documents={documents}
                    onSetDocuments={setDocuments}
                    singleSave={true}
                    save={handelSave}
                    memberType={memberType}
                    memberId={memberId}
                />
            </div>}
        </SidebarLayout>
    );
}

export default RequestNewDocuments;